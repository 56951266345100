import { useDispatch, useSelector } from "react-redux";

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios, { post } from "axios";
import { sqlData } from "../components/api";
import { useHistory } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Gzonetech Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const dispatch = useDispatch();
  const blmcLogin = useSelector((state) => state.loginReducer);
  const history = useHistory();
  const [state, setState] = React.useState({
    pass: "",
    uname: "",
  });
  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  async function fetchLoginData() {
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        username: state.uname,
        password: state.pass,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData:
        "cash_register,full_name,user_id,user_type,branch_id,page_dashboard,page_orders,page_store_item,page_customers,page_reports,page_inventory,page_supply_chain,page_transactions,page_cash_remittance,cash_register_id,page_supplier,handle_branches,tin,barcode_id,password",
    };
    await sqlData(data, "", "POST", "users/viewdata")
      .then((result) => {
        if (result.length > 0) {
          if (parseInt(result[0].user_type) == 2) {
            history.push("/");
          } else if (parseInt(result[0].user_type) !== 2) {
            history.push("/");
          }
          let handle_branches = []
          let branch_id = ''
          if (result[0].branch_id != null) {
            branch_id = result[0].branch_id
          }
          if (result[0].handle_branches != null) {
            handle_branches = JSON.parse(result[0].handle_branches)
            branch_id = handle_branches[0].branch_id
          }

          dispatch({
            type: "onChangeLoginReducer",
            data: {
              userLogin: result,
              branch_id: branch_id,
              handleBranches: handle_branches
            },
          });
          dispatch({
            type: "onChangeOrderReducer",
            data: {
              cash_register_id: result[0].cash_register_id,
            },
          });
        }

      })
      .catch((error) => {
        // setOpenLoading(false);
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic d2VzdG1lYWQ6d2VzdG1lYWQyMDIw");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", state.uname);
    urlencoded.append("password", state.pass);
    var requestOptions = {
      method: "POST",
      url: "https://api.store.gzonetechph.com/public/AppLogin/login",
      // url: "http://beta-storefront.api.gzonetechph.com/public/AppLogin/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic ZmllbGRwbHVzdjI6MjAyMGZpZWxkcGx1czI=",
      },
      data: urlencoded,
      redirect: "follow",
    };
    let match = false;
    axios(requestOptions)
      .then((response) => {
        if (JSON.stringify(response).includes("OK")) {
          fetchLoginData();
          match = true;
          setTimeout(() => {
            dispatch({
              type: "loginData",
              data: response.data,
            });
          }, 500);
        }
      })

      .catch((error) => {
        JSON.stringify(error).includes("code 401");
        if (JSON.stringify(error).includes("createError")) {
          alert("Login Failed, Check Username or Password");
        }
      });
  };

  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={onSubmit} className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              onChange={onChange}
              label="Username"
              name="uname"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="pass"
              onChange={onChange}
              label="Password"
              type="password"
              id="password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
