const initialState = {
  loginData: [],
  userLogin: [],
  branch_id:'',
  handleBranches:[]
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "loginData":
      return {
        ...state,
        loginData: action.data,
      };
    case "onChangeLoginReducer":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
