import DateFnsUtils from '@date-io/date-fns';
import Backdrop from "@material-ui/core/Backdrop";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from '@material-ui/core/Collapse';
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from "@material-ui/icons/PostAdd";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import 'date-fns';
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { sqlData } from "../api";
const columns = [
    { id: 'po_id', label: 'Purchase Order Id' },
    { id: 'date_order', label: 'Date Order' },
    { id: 'reference_number', label: 'Reference Number' },
    { id: 'supplier_name', label: 'Supplier' },
    { id: 'po_status', label: 'Status' },
    { id: 'po_total', label: 'Total' },
];
export default function PurchaseOrder() {
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        indexCollapse: '',
        singleSched: [],
        selectedPurchase: []
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [purchaseDialog, setPurchaseDialog] = React.useState(false);
    const [selectedDialog, setselectedDialog] = React.useState(false);
    const [supplierArray, setSupplierArray] = React.useState([]);
    const [supplierItemsArray, setSupplierItemsArray] = React.useState([]);
    const [openLoading, setOpenLoading] = React.useState(false);
    const [selectedDateOrder, setSelectedDateOrder] = React.useState(new Date());
    const [selectSupplier, setSelectSupplier] = React.useState("");
    const [collapse, setCollapse] = React.useState('');
    const [supplyItem, setSupplyItem] = React.useState([]);
    const [poArray, setPOArray] = React.useState([]);
    const [branchArray, setBranchArray] = React.useState([]);
    const [selectedPO, setselectedPO] = React.useState([]);
    const [poItems, setPOItems] = React.useState([]);
    const [selectedBranch, setSelectedBranch] = React.useState('');
    const handleOrderDateChange = (date) => {
        setSelectedDateOrder(date);
    };
    const closePurchaseDialog = () => {
        setPurchaseDialog(false)
    }
    const closeViewPurchaseDialog = () => {
        setOpenLoading(true);
        setselectedDialog(false)
        setOpenLoading(false);
    }
    const openViewPurchaseDialog = (row) => {
        console.log(row)
        setOpenLoading(true);
        setselectedDialog(true)
        setselectedPO(row)
        let data = {
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {
                po_id: row.po_id
            },
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {
                inventory: ["inventory.item_id = po_items.item_id", "left"],
            },
            selectData: "*, inventory.quantity as stock_quantity, po_items.quantity as req_quantity",
        }
        sqlData(data, "", "POST", "poitems/viewdata").then((result) => {
            setPOItems(result)
            setOpenLoading(false);
        }).catch((error) => {
            setOpenLoading(false);
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const onChangeBranch = (event) => {
        setSelectedBranch(event.target.value);
    }
    const onChangeSupplier = (event) => {
        setOpenLoading(true);
        const data = JSON.stringify({
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: { 'supplier_id': event.target.value },
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: ['item_name ASC'],
            join: {
                categories: ["categories.category_id = inventory.category_id", "left"],
            },
            selectData: "*",
        });
        sqlData(data, "", "POST", "inventory/viewdata").then((result) => {
            setSupplierItemsArray(result)
            setState(prev => ({
                ...prev,
                selectedPurchase: []
            }))
            setOpenLoading(false);
        }).catch((error) => {
            setOpenLoading(false);
        })
        setSelectSupplier(event.target.value)
    };
    const fetchPO = () => {
        let data = {
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {},
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {
                suppliers: ["suppliers.supplier_id = purchase_orders.supplier_id", "left"],
            },
            selectData: "*",
        }
        sqlData(data, "", "POST", "purchaseorder/viewdata").then((result) => {
            setPOArray(result)
            setOpenLoading(false);
        }).catch((error) => {
            alert('Something went wrong. Please reload the page.')
            setOpenLoading(false);
        })
    }
    React.useEffect(() => {
        setOpenLoading(true);
        async function setPageTitle() {
            dispatch({
                type: "setPageTitle",
                requestResult: "Supply Chain | Purchase Order",
            });
        }
        async function fetchSuppliers() {
            let data = {
                page: 0,
                customWhere: false,
                count: false,
                customWhereStr: "",
                where: {},
                orWhere: {},
                like: {},
                orLike: {},
                groupBy: [],
                orderBy: [],
                join: {},
                selectData: "*",
            }
            await sqlData(data, "", "POST", "suppliers/viewdata").then((result) => {
                setSupplierArray(result)
                setOpenLoading(false);
            }).catch((error) => {
                alert('Something went wrong. Please reload the page.')
                setOpenLoading(false);
            })
        }
        async function fetchBranches() {
            let data = {
                page: 0,
                customWhere: false,
                count: false,
                customWhereStr: "",
                where: {},
                orWhere: {},
                like: {},
                orLike: {},
                groupBy: [],
                orderBy: [],
                join: {},
                selectData: "*",
            }
            await sqlData(data, "", "POST", "branch/viewdata").then((result) => {
                setBranchArray(result)
                setOpenLoading(false);
            }).catch((error) => {
                alert('Something went wrong. Please reload the page.')
                setOpenLoading(false);
            })
        }

        setTimeout(() => {
            setPageTitle()
            fetchSuppliers();
            fetchPO();
            fetchBranches()
        }, 1000);
    }, []);
    const NewCollapse = () => {
        const col = React.useRef(false);
        if (col.current === false) {
            col.current = true
        } else {
            col.current = false
        }
        return <TableRow style={{ backgroundColor: '#fcf2c7' }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 20, paddingLeft: 20 }} colSpan={6}>
                <Collapse in={col.current} timeout="auto" unmountOnExit>
                    <Typography variant="h6" gutterBottom component="div">
                        Item Details
                    </Typography>
                    <Table size="small" >

                        <TableBody>
                            <TableRow>
                                <TableCell>Description:</TableCell>
                                <TableCell>{supplyItem.item_description}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Category:</TableCell>
                                <TableCell>{supplyItem.category_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Brand:</TableCell>
                                <TableCell>{supplyItem.brand}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Reference:</TableCell>
                                <TableCell>{supplyItem.ref_number}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
    }
    const onClickAddPurchase = (val, index) => {
        val.order_quantity = 0
        state.selectedPurchase.push(val)
        setState(prev => ({
            ...prev
        }));
    }
    const onClickRemoveItemPurchase = (val, index) => {
        state.selectedPurchase.splice(index, 1)
        setState(prev => ({
            ...prev
        }));
    }
    const onChangeItemPurchase = (e, index) => {
        let valu = e.target.value;
        setState((prev) => ({
            ...prev,
            selectedPurchase: state.selectedPurchase.map((val, index2) =>
                index === index2 ? { ...val, [e.target.name]: valu } : val
            ),
        }));
    }
    const onSubmit = () => {
        setOpenLoading(true);

        let data = {
            reference_number: '',
            date_order: moment(selectedDateOrder).format('YYYY-MM-DD'),
            date_received: moment(new Date()).format('YYYY-MM-DD'),
            po_receipt_number: 0,
            po_status: 'Pending',
            po_total: '',
            purchase_branch: selectedBranch,
            supplier_id: selectSupplier
        }

        sqlData(data, "", "POST", "purchaseorder").then((result) => {
            let id = result
            let po = {
                po_id: id,
                reference_number: 'PO-' + result
            }
            sqlData(po, "", "PUT", "purchaseorder/" + id).then(
                (result) => {
                    fetchPO()
                })
            let newArry = []
            state.selectedPurchase.map((val) => {
                let details = {
                    po_id: result,
                    quantity: val.quantity,
                    purchase_price: 0,
                    unit: val.unit,
                    item_id: val.item_id
                }
                newArry.push(details)
            })
            setState(prev => ({
                ...prev
            }));
            sqlData(newArry, "", "POST", "poitems/insertBatchPoItem").then(
                (result) => {
                    setOpenLoading(false);
                }
            ).catch((error) => {
                setOpenLoading(false);
            })

        }).catch((error) => {
            setOpenLoading(false);
        })
    }
    return (
        <Container>
            <Backdrop
                style={{ zIndex: 9999 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={2} >

                <Grid item sm={6}>
                    <Box flexDirection='row' justifyContent='flex-start'>

                    </Box>
                </Grid>
                <Grid item sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<PostAddIcon />}
                        onClick={() => { setPurchaseDialog(true) }}
                    >
                        Add Purchase Order
                    </Button>
                </Grid>
                <Grid item sm={12}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {poArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <Tooltip title="View Entry">
                                                    <IconButton
                                                        onClick={() => openViewPurchaseDialog(row)}
                                                    >
                                                        <VisibilityIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={poArray.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                {selectedDialog === true ?
                    <Grid item md={12}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <Typography variant='h6'>
                                            Purchase Order Details
                                        </Typography>
                                        <ButtonGroup
                                            color="primary"
                                            variant="contained"
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                startIcon={<VisibilityOffIcon />}
                                            // onClick={() => { setPurchaseDialog(true) }}
                                            >
                                                Print
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                startIcon={<VisibilityOffIcon />}
                                                onClick={() => { closeViewPurchaseDialog() }}
                                            >
                                                Hide
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <TableContainer>
                                            <Table stickyHeader aria-label="sticky table" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Item Name
                                                        </TableCell>
                                                        <TableCell>
                                                            Description
                                                        </TableCell>
                                                        <TableCell>
                                                            Request Quantity
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {poItems.map((val) => {
                                                        return <TableRow>
                                                            <TableCell>
                                                                {val.item_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {val.item_description}
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {val.req_quantity}
                                                            </TableCell>
                                                        </TableRow>
                                                    })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item md={6}>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align='left' style={{ borderStyle: 'none' }}>
                                                            Date Added:
                                                        </TableCell>
                                                        <TableCell align='right' style={{ borderStyle: 'none' }}>
                                                            {moment(selectedPO.date_created).format('LL ')}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align='left' style={{ borderStyle: 'none' }}>
                                                            Order Date:
                                                        </TableCell>
                                                        <TableCell align='right' style={{ borderStyle: 'none' }}>
                                                            {moment(selectedPO.date_order).format('LL ')}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align='left' style={{ borderStyle: 'none' }}>
                                                            Date Received:
                                                        </TableCell>
                                                        <TableCell align='right' style={{ borderStyle: 'none' }}>
                                                            {moment(selectedPO.date_received).format('LL ')}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align='left' style={{ borderStyle: 'none' }}>
                                                            Reference No. :
                                                        </TableCell>
                                                        <TableCell align='right' style={{ borderStyle: 'none' }}>
                                                            {selectedPO.reference_number}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align='left' style={{ borderStyle: 'none' }}>
                                                            PO ID :
                                                        </TableCell>
                                                        <TableCell align='right' style={{ borderStyle: 'none' }}>
                                                            {selectedPO.po_id}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align='left' style={{ borderStyle: 'none' }}>
                                                            Status :
                                                        </TableCell>
                                                        <TableCell align='right' style={{ borderStyle: 'none' }}>
                                                            {selectedPO.po_status}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align='left'>
                                                            Reciept Number :
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {selectedPO.po_receipt_number}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow style={{ width: '100%' }}>
                                                        <TableCell align='left' style={{ borderStyle: 'none', width: '70%' }}>
                                                            Total PO :
                                                        </TableCell>
                                                        <TableCell align='right' style={{ borderStyle: 'none', backgroundColor: 'rgb(47, 53, 66)', width: '30%', color: 'white' }}>
                                                            {selectedPO.po_receipt_number}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    :
                    undefined
                }


            </Grid>

            <Dialog onClose={closePurchaseDialog} aria-labelledby="simple-dialog-title" open={purchaseDialog} maxWidth='lg' fullWidth fullScreen>
                <DialogTitle id="simple-dialog-title">
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <IconButton color="primary" size='small' onClick={() => { closePurchaseDialog() }}>
                                <ClearIcon />
                            </IconButton>
                            <Typography variant='h6'>
                                Add New Purchase Order
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <FormControl fullWidth size='small' variant="outlined" style={{ marginTop: 25 }}>
                                <InputLabel id="demo-simple-select-outlined-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectSupplier}
                                    onChange={onChangeSupplier}
                                >
                                    {supplierArray.map((val) => {
                                        return <MenuItem value={val.supplier_id}>
                                            {val.supplier_name}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth size='small' variant="outlined" style={{ marginTop: 25 }}>
                                <InputLabel id="demo-simple-select-outlined-label">Deliver To</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectedBranch}
                                    onChange={onChangeBranch}
                                >
                                    {branchArray.map((val) => {
                                        return <MenuItem value={val.branch_id}>
                                            {val.branch_name}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Date Order"
                                    format="MM/dd/yyyy"
                                    value={selectedDateOrder}
                                    onChange={handleOrderDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6}>
                            <TableContainer style={{ width: '100%', maxHeight: 440 }} variant='outlined'>
                                <Table size='small' stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Item</TableCell>
                                            <TableCell>Remaining Qty</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {supplierItemsArray.map((val, index) => {
                                            let collapseVal = false;
                                            if (collapse === index) {
                                                collapseVal = true
                                            }

                                            return <>
                                                <TableRow>
                                                    <TableCell>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => { collapseVal == true ? setCollapse('') : setCollapse(index); setSupplyItem(val) }}>
                                                            {collapseVal ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {val.item_name.toUpperCase()}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {val.quantity}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Button variant="contained" color="primary" size='small' onClick={() => {
                                                            onClickAddPurchase(val, index)
                                                        }}>
                                                            Add
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                {index === collapse ?
                                                    <NewCollapse />
                                                    : undefined

                                                }

                                            </>
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item md={6}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12}>
                                            <Typography variant="subtitle1">
                                                Order Details:
                                            </Typography>
                                        </Grid>
                                        {state.selectedPurchase.map((val, index) => {
                                            return <>
                                                <Grid item sm={4}>
                                                    <Typography>
                                                        {val.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={7}>
                                                    <TextField id="outlined-basic" label="Qty" type='number' variant="outlined" name='quantity' size='small' fullWidth onChange={(e) => {
                                                        onChangeItemPurchase(e, index);
                                                    }} />
                                                </Grid>
                                                <Grid item sm={1}>
                                                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
                                                        onClickRemoveItemPurchase(val, index)
                                                    }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        })}
                                        <Grid item sm={12}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                startIcon={<PostAddIcon />}
                                                onClick={() => { onSubmit() }}
                                            >
                                                Order
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </Container >

    );
}
