import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CashRemittanceAction from "./indexAction";
import IndexTableRemittance from "./indexTableCashRemittance";
// import ThermalPrinter, { PrinterTypes } from "browser-thermal-printer";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function CashRemittance() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.loginReducer.userLogin);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getRemittanceData(newPage, rowsPerPage, state.search)
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    getRemittanceData(0, event.target.value, state.search)

    setPage(0);
  };
  const [state, setState] = React.useState({
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
    sales_amount: 0,
    amount_remitted: 0,
    total_initial_cash_register:0,
    cashRemittanceList: [],
    listCount: 0,
    search: "",
    modalCashRegister: false,
    selectedList: [],
    amountReceived: "",
    refreshList:false,
    cashRegister:[
      {type:'paper',amount:1000,count:0},
      {type:'paper',amount:500,count:0},
      {type:'paper',amount:200,count:0},
      {type:'paper',amount:100,count:0},
      {type:'paper',amount:50,count:0},
      {type:'paper',amount:20,count:0},
      {type:'coins',amount:0,count:0},
    ]
  });
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  React.useEffect(() => {
    dispatch({
        type: "setPageTitle",
        requestResult: "Cash Remittance",
      });
    getRemittanceData(page, rowsPerPage, state.search);
  }, [state.refreshList]);
  const getRemittanceData = (pageVal, limit, search) => {
    let data = {
      branch_id: userLogin[0].branch_id,
      page: pageVal+1,
      limit: limit,
      from: state.from,
      to: state.to,
      search: search,
    };
    sqlData(data, "", "POST", "cashRemittance/getCashRegisterLogs").then(
      (result) => {
        let sales_amount = 0;
        let amount_remitted = 0;
        let total_initial_cash_register = 0;
        if (result.cashRemitLogsDashBoard.length > 0) {
          if (result.cashRemitLogsDashBoard[0].sales_amount != null) {
            sales_amount = result.cashRemitLogsDashBoard[0].sales_amount;
          }
          if (result.cashRemitLogsDashBoard[0].amount_remitted !== null) {
            amount_remitted = result.cashRemitLogsDashBoard[0].amount_remitted;
          }
          if (result.cashRemitLogsDashBoard[0].total_initial_cash_register !== null) {
            total_initial_cash_register = result.cashRemitLogsDashBoard[0].total_initial_cash_register;
          }
        }
        setState((prev) => ({
          ...prev,
          sales_amount: sales_amount,
          amount_remitted: amount_remitted,
          cashRemittanceList: result.res,
          listCount: result.cashRemitLogsDashBoard[0].total_count,
          total_initial_cash_register:total_initial_cash_register
        }));
      }
    );
  };
  const filterDate = () => {
    setPage(0);
    getRemittanceData(0, rowsPerPage, state.search);
  };
  const onRemoveSearch = () => {
    setPage(0);
    setState((prev) => ({
      ...prev,
      search: "",
    }));
    getRemittanceData(0, rowsPerPage, "");
  };
  const onSubmitCashRemit = (e) => {
    e.preventDefault();
    let amount = state.cashRegister.reduce((count,val)=>{
      count += parseFloat(val.amount)*parseInt(val.count)
      return count
    },0)
    let data = {
      amount_remitted: amount,
      admin_id: userLogin[0].user_id,
      admin_name: userLogin[0].full_name,
      cash_register_log_id: state.selectedList.cash_register_log_id,
      status:'Cash Remitted',
      user_id: state.selectedList.user_id,
      branch_id: state.selectedList.branch_id,
      remitanceSchema:JSON.stringify(state.cashRegister)

    };
    sqlData(data, "", "POST", "cashRemittance/updateCashRemittance").then(
      (result) => {
        if(result.res){
           setState(prev=>({
               ...prev,
               refreshList:!state.refreshList,
               modalCashRegister:false,
               amountReceived:''
           }))
           dispatch({
            type:'onChangeLoginReducer',
            data:{
              userLogin : userLogin.map((val,index)=>(index == 0)?{...val,cash_register:null}:val)
            }
          })
        }
      }
    );
  };
  const onChangeTextRegister=(e,indexVal)=>{
    let value = e.target.value
    let name = e.target.name
    if(value == ''){
      value = 0;
    }
    let amount = parseFloat(name) * parseFloat(value)
    if(name == 'coins'){
      setState(prev=>({
        ...prev,
        cashRegister:state.cashRegister.map((val,index)=>(index == indexVal)?
        {...val,amount:value,count:1}
        :val)
       }))
    }else{
      setState(prev=>({
        ...prev,
        cashRegister:state.cashRegister.map((val,index)=>(index == indexVal)?
        {...val,count:value}
        :val)
       }))
    }
   
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <CashRemittanceAction
            onChangeText={onChangeText}
            from={state.from}
            to={state.to}
            filterDate={filterDate}
            search={state.search}
            onRemoveSearch={onRemoveSearch}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <IndexTableRemittance
            sales_amount={state.sales_amount}
            amount_remitted={state.amount_remitted}
            cashRemittanceList={state.cashRemittanceList}
            total_initial_cash_register={state.total_initial_cash_register}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            listCount={state.listCount}
            modalCashRegister={state.modalCashRegister}
            onCloseOpenModal={(status) => {
              setState((prev) => ({ ...prev, modalCashRegister: status,  cashRegister:[
                {type:'paper',amount:1000,count:0},
                {type:'paper',amount:500,count:0},
                {type:'paper',amount:200,count:0},
                {type:'paper',amount:100,count:0},
                {type:'paper',amount:50,count:0},
                {type:'paper',amount:20,count:0},
                {type:'coins',amount:0,count:0},
              ] }));
            }}
            onChangeText={onChangeText}
            onSelectedList={(row) =>
              setState((prev) => ({ ...prev, selectedList: row }))
            }
            amountReceived={state.amountReceived}
            onSubmitCashRemit={onSubmitCashRemit}
            onChangeTextRegister={onChangeTextRegister}
            cashRegister={state.cashRegister}
          />
        </Grid>
      </Grid>
    </div>
  );
}
