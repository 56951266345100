import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import BarChart from "./Barchart";
import Dropdown from "../../reusableComponents/Dropdown/dropdown";
import { TextField } from "@material-ui/core";
// import Button from "../../reusableComponents/Button/button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import OrderDetails from "../Dashboard/Functions/DashboardLogic";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 320,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const {
    ordersToday,
    monthFormat,
    allSales,
    allProfit,
    dailySales,
    orderReport,
    onChangeText,
    handleClick,
    selectedMonth,
    orderType,
    handleChange,
    totalAmountSupplier
  } = OrderDetails()
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "setPageTitle",
      requestResult: "Dashboard",
    });
    orderReport();
  }, []);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{ fontWeight: "bold", fontSize: 18 }}
            variant="overline"
          >
            Dashboard
          </Typography>
        </Grid>
        <Grid container justify="flex-end" item xs={12} md={6}>
          <TextField
            value={selectedMonth}
            type='month'
            variant='outlined'
            size="small"
            style={{ marginRight: 5 }}
            name='selectedMonth'
            onChange={onChangeText}
          />
          <ButtonGroup
            color="primary"
            variant="contained"
            style={{ marginBottom: 10 }}
          >
            <Button onClick={handleClick}>Filter</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 5 }}>
              <Dropdown
                data={[{ name: 'All', value: 'All' }, { name: 'Retail', value: 'Retail' }, { name: 'Whole Sale', value: 'Whole Sale' }]}
                value={orderType}
                handleChange={handleChange}
                optionValue={'value'}
                optionName={'name'}
                type={true}
              />
            </div>

            <ButtonGroup
              color="primary"
              variant="contained"
              style={{ marginBottom: 10 }}
            >
              <Button onClick={handleClick}>Filter</Button>
            </ButtonGroup>
          </div>

        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={fixedHeightPaper}>
            <BarChart dailySales={dailySales} monthFormat={monthFormat} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Deposits totalAmountSupplier={totalAmountSupplier} allSales={allSales} allProfit={allProfit} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper}>
            <Orders ordersToday={ordersToday} />
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </div>
  );
}
