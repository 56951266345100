import React from "react";
import Barcode from "react-barcode";
import '../../../App.css';
import AnL from "../../../assents/logo/AnL.png";
const ComponentToPrint = React.forwardRef((props, ref) => {
  const { branchDetais, orderToPrint, receiptNo, lastDetails } = props;
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <div ref={ref} className='no-page-break'>
      <div

        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={AnL} style={{ width: 150, height: 150, marginTop: -20 }} />
      </div>
      <div style={{ marginTop: -30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label style={{ fontSize: 12, fontWeight: "bold" }}>
            {branchDetais.address}
          </label>
          <label style={{ fontSize: 14, fontWeight: "bold" }}>
            Tel #: {branchDetais.tel_no}
          </label>
          <label style={{ fontSize: 14, fontWeight: "bold" }}>
            Cel #: {branchDetais.contact_no}
          </label>
        </div>
        <div style={{ marginLeft: 15, marginRight: 15, marginTop: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <label style={{ fontSize: 13, fontWeight: "bold" }}>
              REF #: {receiptNo}
            </label>
            <label style={{ fontSize: 13, fontWeight: "bold" }}>
              {lastDetails.length > 0 && lastDetails[0].date_added}
            </label>
          </div>
          <div style={{ marginTop: -5 }}>
            <label style={{ fontSize: 13, fontWeight: "bold" }}>
              CUSTOMER: {lastDetails.length > 0 && String(lastDetails[0].customer_name).toLocaleUpperCase()}
            </label>
          </div>
          <div
            style={{
              width: "100%",
              borderStyle: "dotted",
              borderWidth: 1,
              borderTopColor: "#0000",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></div>
          <div>
            <label style={{ fontSize: 13, fontWeight: "bold" }}>
              Order Details
            </label>
          </div>
          <div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ width: "35%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>
                  Item Name
                </label>
              </div>
              <div style={{ width: "10%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>Qty</label>
              </div>
              <div style={{ width: "10%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>Unit</label>
              </div>
              <div style={{ width: "22%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>Price</label>
              </div>
              <div style={{ width: "13%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>Total</label>
              </div>
            </div>
            {typeof orderToPrint?.itemList !== 'undefined' && orderToPrint?.itemList.map((val, index) => {
              return <div key={index} style={{ marginTop: 2 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    lineHeight: 0.8,
                  }}
                >
                  <div style={{ width: "2%" }}>
                    <label style={{ fontSize: 13, fontWeight: "bold" }}>* </label>
                  </div>

                  <div style={{ width: "33%" }}>
                    <label style={{ fontSize: 13, fontWeight: "bold" }}>
                      {val.item_name}
                    </label>
                  </div>
                  <div style={{ width: "10%" }}>
                    <label style={{ fontSize: 14, fontWeight: "bold" }}> {val.quantity}</label>
                  </div>
                  <div style={{ width: "10%" }}>
                    <label style={{ fontSize: 14, fontWeight: "bold" }}> {val.unit}</label>
                  </div>
                  <div style={{ width: "22%", textAlign: 'right' }}>
                    <label style={{ fontSize: 14, fontWeight: "bold" }}>
                      {formatNumber(val.unit_price)}
                    </label>
                  </div>
                  <div style={{ width: "23%", textAlign: 'right' }}>
                    <label style={{ fontSize: 14, fontWeight: "bold" }}>
                      {formatNumber(val.total_price)}
                    </label>
                  </div>
                </div>
              </div>
            })

            }
            <div
              style={{
                width: "100%",
                borderStyle: "dotted",
                borderWidth: 1,
                borderTopColor: "#0000",
                marginTop: 5,
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                lineHeight: 0.8,
                marginTop: 5,
              }}
            >
              <div style={{ width: "40%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>
                  Sub Total
                </label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%", textAlign: "right" }}>
                <label style={{ fontSize: 14, fontWeight: "bold" }}>
                  {formatNumber(parseFloat(orderToPrint?.order?.total_amount) + parseFloat(orderToPrint?.order?.discount_amount))}
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                lineHeight: 0.8,
              }}
            >
              <div style={{ width: "40%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>
                  Discount
                </label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%", textAlign: "right" }}>
                <label style={{ fontSize: 14, fontWeight: "bold" }}>
                  {formatNumber(orderToPrint?.order?.discount_amount)}
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                lineHeight: 1,
                marginTop: 5,
              }}
            >
              <div style={{ width: "40%" }}>
                <label style={{ fontSize: 15, fontWeight: "bold" }}>Total</label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 15, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 15, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%", textAlign: "right" }}>
                <label style={{ fontSize: 16, fontWeight: "bold" }}>
                  {formatNumber(orderToPrint?.order?.total_amount)}
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                lineHeight: 1,
              }}
            >
              <div style={{ width: "40%" }}>
                <label style={{ fontSize: 13, fontWeight: "bold" }}>Cash</label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 9.5, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 9.5, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%", textAlign: "right" }}>
                <label style={{ fontSize: 14, fontWeight: "bold" }}>
                  {formatNumber(orderToPrint?.order?.cash)}
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                lineHeight: 1,
              }}
            >
              <div style={{ width: "40%" }}>
                <label style={{ fontSize: 15, fontWeight: "bold" }}>
                  Change
                </label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 9.5, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%" }}>
                <label style={{ fontSize: 9.5, fontWeight: "bold" }}></label>
              </div>
              <div style={{ width: "20%", textAlign: "right" }}>
                <label style={{ fontSize: 16, fontWeight: "bold" }}>
                  {formatNumber(orderToPrint?.order?.change)}
                </label>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                borderStyle: "dotted",
                borderWidth: 1,
                borderTopColor: "#0000",
                marginTop: 5,
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <label style={{ fontSize: 12, fontWeight: "bold" }}>
                **** THIS IS NOT AN OFFICIAL RECEIPT ****
              </label>
              <div style={{ width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
                <Barcode options={{ marginLeft: 10, marginRight: 10 }} height={'50%'} value={receiptNo} />
              </div>
              <label style={{ fontSize: 12, fontWeight: "bold" }}>
                {lastDetails.length > 0 && lastDetails[0].user_id + ' / '}
                {lastDetails.length > 0 && lastDetails[0].full_name + ' / '}
                {lastDetails.length > 0 && lastDetails[0].date_added}
              </label>
              <label style={{ fontSize: 12, fontWeight: "bold" }}>
                Powered by: GZONETECH, Inc.
              </label>
            </div>
            {/* <table style={{width:'100%'}}>
                  <thead>
                      <tr>
                          <td style={{textAlign:'left'}}><label style={{ fontSize: 8, fontWeight: "bold"}}>Item Name</label></td>
                          <td><label style={{ fontSize: 8, fontWeight: "bold" }}>Qty</label></td>
                          <td><label style={{ fontSize: 8, fontWeight: "bold" }}>Price</label></td>
                          <td><label style={{ fontSize: 8, fontWeight: "bold" }}>Total</label></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td style={{width:'40%'}}><label style={{ fontSize: 7}}>22202-KTT-1100 TMX SUPREMO CLUTCH LINNING</label></td>
                          <td style={{width:'20%'}}><label style={{ fontSize: 8, fontWeight: "bold" }}>4</label></td>
                          <td style={{width:'20%'}}><label style={{ fontSize: 8, fontWeight: "bold" }}>1,000.00</label></td>
                          <td style={{width:'20%'}}><label style={{ fontSize: 8, fontWeight: "bold" }}>4,000.00</label></td>
                      </tr>
                  </tbody>
              </table> */}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ComponentToPrint;
