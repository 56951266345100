import React from "react";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import PropTypes from "prop-types"; // ES6

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#fff",
    fontWeight: "bold",
    border: "none",
  },
}));

const Dropdown = (props) => {
  const classes = useStyles();
  const {
    data,
    value,
    handleChange,
    optionValue,
    optionName,
    type = null,
  } = props;
  return (
    <div>
      <Select
        id="outlined-select-currency"
        select
        value={value}
        onChange={handleChange}
        variant={type == null ? undefined : "outlined"}
        size={"small"}
        disableUnderline={type == null ? true : false}
        classes={{
          root: type == null ? classes.input : undefined,
          icon: type == null ? classes.input : undefined,
        }}
        style={{ width: type == null ? undefined : 180, height: 38 }}
      >
        {data.map((option, index) => (
          <MenuItem key={index} value={option[optionValue]}>
            {option[optionName]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
Dropdown.prototype = {
  data: PropTypes.array,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  optionValue: PropTypes.any,
  optionName: PropTypes.any,
};
export default Dropdown;
