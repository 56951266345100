import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import { useHistory } from "react-router-dom";
import { getData } from "../api";
export default function Passowrd(props) {
    const history = useHistory();
    const { ...param } = props
    const loginDetails = param.loginDetails
    const dispatch = useDispatch();
    const [openLoading, setOpenLoading] = React.useState(false);
    const [state, setState] = React.useState({
        old_password: '',
        password: '',
        confirm_password: '',
    })
    const onChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value,
        }));
    }
    const onSubmitUpdatePassword = (e) => {
        e.preventDefault();
        // setOpenLoading(true);
        if (state.password === state.confirm_password) {
            const data = {
                old_password: state.old_password,
                password: state.password,
                confirm_password: state.confirm_password,
                user_id: loginDetails.user_id
            }

            getData("users/changePassword", data).then((result) => {
                setOpenLoading(false);
                console.log(result)
                if (result === 'Success!') {
                    alert('ACCOUNT PASSWORD UPDATED SUCCESSFULLY, PLEASE RELOGIN YOUR ACCOUNT')
                    localStorage.clear();
                    history.push("/");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    alert('Incorrect password')
                }

            }).catch((error) => {
                setOpenLoading(false);
                alert('FAILED TO CHANGE PASSWORD!')
            })
            console.log(data)
        } else {
            alert('Password did not match!')
        }


    }

    return (
        <form onSubmit={onSubmitUpdatePassword}>
            <DialogTitle id="form-dialog-title">Update Password</DialogTitle>
            <DialogContent>
                <Card variant="outlined" style={{ marginBottom: 10 }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <TextField onChange={onChange} name="old_password" label="Current Password" required variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField onChange={onChange} name="password" label="New Password" required variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField onChange={onChange} name="confirm_password" label="Confirm Password" required variant="outlined" fullWidth size="small" />
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    Update
                </Button>
            </DialogActions>
        </form>
    )
}