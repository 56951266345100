import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import ThermalPrinter, { PrinterTypes } from "browser-thermal-printer";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;
const width = window.innerWidth
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function CashRemittanceAction(props) {
  const dispatch = useDispatch();
  const {onChangeText,from,to,filterDate,search,onRemoveSearch} = props
 
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{ fontWeight: "bold", fontSize: 18 }}
            variant="overline"
          >
            Cash Remittance
          </Typography>
        </Grid>
        <Grid container justify="flex-end" item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            style={{
              marginRight: width > 600 ? 5 : undefined,
              marginBottom: width < 600 ? 5 : undefined,
            }}
            value={from}
            onChange={onChangeText}
            name="from"
            size="small"
            type="date"
            variant="outlined"
            label="From"
          />

          <TextField
            InputLabelProps={{ shrink: true }}
            style={{
              marginRight: width > 600 ? 5 : undefined,
              marginBottom: width < 600 ? 5 : undefined,
            }}
            value={to}
            onChange={onChangeText}
            name="to"
            size="small"
            type="date"
            variant="outlined"
            label="To"
          />
          <ButtonGroup
            color="primary"
            variant="contained"
            style={{ marginBottom: 10 }}
          >
            <Button onClick={() => filterDate()}>Filter</Button>
          </ButtonGroup>
        </Grid>
        <Grid container item xs={12} md={12}>
          <TextField
            onChange={onChangeText}
            name="search"
            value={search}
            size="small"
            variant="outlined"
            label="Search Employee"
            style={{ marginRight: 5 }}
          />
          <ButtonGroup
            color="primary"
            variant="contained"
            // style={{ marginBottom: 10 }}
          >
            <Button onClick={()=>onRemoveSearch()}>
              <Icon>close</Icon>
            </Button>
            <Button onClick={()=>filterDate()}>Search</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  );
}
