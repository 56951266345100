import Backdrop from "@material-ui/core/Backdrop";
import Box from '@material-ui/core/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import React, { useState } from "react";
import { sqlData } from "../api";
const columns = [
    { id: 's_item_log_id', label: 'Log ID', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'item_id', label: 'Item ID', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'item_name', label: 'Item Name', format: (value) => String(value).toLocaleUpperCase() },
    { id: 's_movement', label: 'Movement', format: (value) => String(value).toLocaleUpperCase() },
    { id: 's_date_updated', label: 'Date', format: (value) => String(value).toLocaleUpperCase() },
    { id: 's_past_quantity', label: 'Past Quantity', format: (value) => String(value).toLocaleUpperCase() },
    { id: 's_new_quantity', label: 'New Quantity', format: (value) => String(value).toLocaleUpperCase() },
    { id: 's_update_value', label: 'Inserted Value', format: (value) => String(value).toLocaleUpperCase() },
];
const InventoryLogs = () => {
    const [state, setState] = useState({
        logArray: [],
        searchlogArray: [],
        filterlogArray: [],
        momentFilter: [],
        searchfield: ''
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openLoading, setOpenLoading] = React.useState(false);
    const loginBranch = useSelector((state) => state.loginReducer.userLogin[0].branch_id);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [filter, setFilter] = React.useState('');

    const handleChange = (event) => {
        let filter = [];
        if (event.target.value === "ALL") {
            filter = state.logArray;
        } else {
            filter = state.logArray.filter(
                (files) => files.s_movement === event.target.value
            );
        }
        setState((prev) => ({
            ...prev,
            searchfield: '',
            searchlogArray: filter.reverse(),
            filterlogArray: filter.reverse(),
        }));
        setFilter(event.target.value);

    };
    const onChange = event => {
        let inventorySearch = state.filterlogArray.filter((files) => {
            return (
                (files.item_name != null) ?
                    files.item_name.toLowerCase()
                        .indexOf(event.target.value.toLocaleLowerCase()) !== -1
                    : null
            );
        });
        setState((prev) => ({
            ...prev,
            searchlogArray: inventorySearch.reverse(),
            searchfield: event.target.value
        }));
        setPage(0)
    }
    React.useEffect(() => {
        setOpenLoading(true);
        async function fetchInventoryLogs() {
            let data = {
                page: 0,
                customWhere: false,
                count: false,
                customWhereStr: "",
                where: {
                    'store_item_logs.s_branch_id': loginBranch
                },
                orWhere: {},
                like: {},
                orLike: {},
                groupBy: [],
                orderBy: [],
                join: {
                    inventory: ["inventory.item_id = store_item_logs.s_item_id", "left"],
                },
                selectData: "*",
            }
            await sqlData(data, "", "POST", "storeitemlogs/viewdata").then((result) => {
                let movements = []
                let response = result
                response.map((val) => {
                    let match = false;
                    movements.map((val2) => {
                        if (val.s_movement === val2.s_movement) {
                            match = true
                        }
                    })
                    if (!match) {
                        movements.push({ s_movement: val.s_movement })
                        return true
                    }
                })
                setState((prev) => ({
                    ...prev,
                    momentFilter: movements,
                    logArray: result.reverse(),
                    filterlogArray: result.reverse(),
                    searchlogArray: result.reverse(),
                }));
                setPage(0)
                setOpenLoading(false);
            }).catch((error) => {
                setOpenLoading(false);
            })
        }
        setTimeout(() => {
            fetchInventoryLogs();

        }, 1000);
    }, []);
    return (
        <Grid container spacing={2}>
            <Backdrop
                style={{ zIndex: 9999 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item sm={6}>
                <Box display="flex" justifyContent="flex-start">
                    <TextField
                        size="small"
                        id="outlined-helperText"
                        label="Search item"
                        variant="outlined"
                        onChange={e => {
                            onChange(e)
                        }}
                        value={state.searchfield}
                        name='searchfield'
                    />
                </Box>

            </Grid>
            <Grid item sm={6}>

                <FormControl fullWidth size='small' variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Filter by movement</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={filter}
                        onChange={handleChange}
                        label="Filter"
                        name='filter'
                    >

                        <MenuItem value="ALL">
                            <em>ALL</em>
                        </MenuItem>
                        {state.momentFilter.map((val) => {
                            let value = ''
                            if (val.s_movement !== null && val.s_movement !== undefined) {
                                value = val.s_movement.toUpperCase()
                            }
                            return <MenuItem value={val.s_movement}>{value}</MenuItem>
                        })}
                    </Select>
                </FormControl>

            </Grid>
            <Grid item sm={12}>
                <TableContainer style={{ maxHeight: 500 }}>
                    <Table size='small' stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.searchlogArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            let value = row[column.id];
                                            if (column.id === 'date_updated') {
                                                value = moment(row[column.id]).format('lll')
                                            }
                                            if (value === undefined) {
                                                value = 'N/A'
                                            }
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && value != null ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={state.searchlogArray.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    )
}
export default InventoryLogs;
