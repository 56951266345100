import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import moment from 'moment';

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});

function TotalSales({ totalSumSales, totalbyTypeSales, from, to, totalSumProfit }) {
    const classes = useStyles();
    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString;
        } else {
            return 0;
        }
    };
    return (
        <React.Fragment>

            <Title>Break Down Sales</Title>
            {totalbyTypeSales.map((val) => {
                return <>
                    <Typography component="p" variant="h6">
                        {val.total != 'NaN' ? 'Php ' + formatNumber(val.total) : 0}
                    </Typography>
                    <Typography color="textSecondary" className={classes.depositContext}>
                        {val.order_type}
                    </Typography>
                </>
            })}
            <Title>Total Sales</Title>
            <Typography component="p" variant="h6">
                {totalSumSales.length !== 0 ? 'Php ' + formatNumber(totalSumSales?.total) : 0}
            </Typography>
            <Title>Total Profit</Title>
            <Typography component="p" variant="h6">
                {totalSumProfit.length !== 0 ? 'Php ' + formatNumber(totalSumProfit?.total) : 0}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                {'from month of ' + moment(from).format("MMMM, Y") + ' to month of ' + moment(to).format("MMMM, Y")}
            </Typography>
            {/* <Title>Total Profit</Title>
      <Typography component="p" variant="h4">
        {allProfit != 'NaN' ? allProfit : 0}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {'as of ' + moment().format("MMM DD, Y")}
      </Typography> */}
        </React.Fragment>
    );
}
export default React.memo(TotalSales)