import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useState } from "react";
import Dropdown from "../../reusableComponents/Dropdown/dropdown";
import MainListItems from "../listitems";
import NavigationLogic from "./NavigationLogic";
import SystemForm from "../Settings/system";
import Password from "../Settings/password";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Navigation() {
  const theme = useTheme();
  const classes = useStyles();
  const {
    open,
    handleDrawerOpen,
    handleDrawerClose,
    logout,
    userLogin,
    blmcPage,
    handleChange,
    handleBranches,
    branch_id,
    accountDetailsDialog,
    handleAccountDetailsDialog,
    handleAddCustTextChange,
    loginDetails
  } = NavigationLogic();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        variant="outlined"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {blmcPage.pageTitle}
          </Typography>
          <Dropdown
            data={handleBranches}
            value={branch_id}
            handleChange={handleChange}
            optionValue={"branch_id"}
            optionName={"branch_name"}
          />
          <Icon style={{ marginLeft: 10, marginRight: 3, fontSize: 20 }}>person</Icon>
          <Typography variant="subtitle2" color="inherit" noWrap>
            {userLogin[0].full_name}
          </Typography>
          <Tooltip title="Settings">
            <IconButton
              onClick={() => {
                handleAccountDetailsDialog(true);
              }}
            >
              <InfoOutlinedIcon fontSize="small" style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton
              onClick={() => {
                logout();
              }}
            >
              <ExitToAppIcon fontSize="small" style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems userLogin={userLogin} />
        </List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <Dialog
        open={accountDetailsDialog}
        onClose={() => handleAccountDetailsDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        {/* <SystemForm handleAddCustTextChange={handleAddCustTextChange} loginDetails={loginDetails} /> */}
        <Password handleAddCustTextChange={handleAddCustTextChange} loginDetails={loginDetails} />
      </Dialog>
    </div>
  );
}
