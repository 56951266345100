import Backdrop from "@material-ui/core/Backdrop";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";

const columns = [
    { id: 'customer_id', label: 'ID', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'customer_name', label: 'FULL NAME', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'cust_address', label: 'ADDRESS', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'cust_contact', label: 'CONTACT NUMBER', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'cust_ref_number', label: 'REF NUMBER', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'cust_status', label: 'STATUS', format: (value) => String(value).toLocaleUpperCase() },
];
const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Customer() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const loginBranch = useSelector((state) => state.loginReducer.userLogin[0].branch_id);


    const [addCustomerDialog, setAddCustomerDialog] = useState(false);
    const [deleteCustomerDialog, setdeleteCustomerDialog] = useState(false);
    const [custID, setcustID] = useState('');
    const [customers, setCustomers] = useState([])
    const [searchcustomer, setCustomerSearch] = useState([])
    const [openLoading, setOpenLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getCustomerData = () => {
        const data = JSON.stringify({
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {
                cust_delete_id: 0,
                branch_id: loginBranch
            },
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {},
            selectData: "*,CONCAT(cust_lname,', ',cust_fname) as customer_name",
        });
        sqlData(data, "", "POST", "customers/viewdata").then((result) => {
            setCustomers(result.reverse());
            setCustomerSearch(result.reverse())
        });
    }
    useEffect(() => {
        dispatch({
            type: "setPageTitle",
            requestResult: "Customers ",
        });
        getCustomerData();

    }, [loginBranch])

    const [customerDetails, setCustomerDetails] = useState({})

    const handleAddCustomer = (e) => {
        e.preventDefault();
        setOpenLoading(true);
        const data = {
            cust_fname: customerDetails.firstName,
            cust_mname: customerDetails.middleName,
            cust_lname: customerDetails.lastName,
            cust_extname: customerDetails.extName,
            cust_type: customerDetails.custType,
            cust_address: customerDetails.address,
            cust_contact: customerDetails.contactNumber,
            tin_number: customerDetails.tin_number,
            cust_email: customerDetails.email,
            cust_status: 'Active',
            branch_id: loginBranch
        }
        sqlData(data, "", "POST", "customers").then((result) => {
            setOpenLoading(false);
            alert('Customer added successfully!')
            getCustomerData()
        }).catch((error) => {
            setOpenLoading(false);
            alert('Adding customer failed!')
        })

    }

    const handleAddCustTextChange = (e) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.name]: e.target.value
        })
    }
    const handleOpenDeleteDialog = (cust) => {
        setdeleteCustomerDialog(true)
        setcustID(cust.customer_id)
    }
    const handleDeleteCustomer = () => {
        const data = {
            customer_id: custID,
            cust_delete_id: 1
        }
        sqlData(data, "", "PUT", "customers/" + custID).then(res => {
            getCustomerData()
            alert('Customer deleted succesfully')

            setdeleteCustomerDialog(false)
        }


        )

    }

    const [updateData, setUpdateData] = useState({})
    const [updateCustDialog, setUpdateCustDialog] = useState(false)

    const handleUpdateItem = (customer) => {
        setUpdateData(customer)
        setUpdateCustDialog(true)
    }

    const updateTextChange = (e) => {
        setUpdateData(
            {
                ...updateData,
                [e.target.name]: e.target.value
            }
        )
    }
    const updateDataRequest = (e) => {
        e.preventDefault()
        sqlData(updateData, "", "PUT", "customers/" + updateData.customer_id).then((result) => {
            getCustomerData()
            alert('Customer updated succesfully')

            setUpdateCustDialog(false)
        })
    }
    const onChange = event => {
        let inventorySearch = customers.filter((files) => {
            return (
                files.customer_name != null ?
                    files.customer_name.toLowerCase()
                        .indexOf(event.target.value.toLocaleLowerCase()) !== -1
                    : null
            );
        });
        setCustomerSearch(inventorySearch)

        setPage(0)
    }
    return (
        <div >
            <Backdrop
                style={{ zIndex: 9999 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container spacing={3} style={{}}>

                                <Grid item md={12} xs={12}>
                                    <Typography variant="subtitle2">CUSTOMER DASHBOARD</Typography>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography variant="h6">
                                        {customers.length} <Typography variant="overline">Customer(s)</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography variant="h6">
                                        {customers.filter(cust => cust.cust_status === 'Active').length} <Typography variant="overline">Active Customer(s)</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography variant="h6">
                                        {customers.filter(cust => cust.cust_status === 'Inactive').length} <Typography variant="overline">Inactive Customer(s)</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start">
                        <TextField
                            size="small"
                            id="outlined-helperText"
                            label="Search item"
                            variant="outlined"
                            onChange={e => {
                                onChange(e)
                            }}
                            // value={state.searchfield}
                            name='searchfield'
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} >
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained" color="primary"
                            onClick={() => setAddCustomerDialog(true)}
                        >
                            Add Customer
                        </Button>
                    </Box>

                </Grid>
                <Grid item xs={12} md={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 450 }}>
                        <Table
                            className={classes.table}
                            size="small"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, fontWeight: 600 }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchcustomer.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                let value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && value != null ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <Tooltip title="Update Entry">
                                                    <IconButton
                                                        onClick={() => handleUpdateItem(row)}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Remove Entry">
                                                    <IconButton
                                                        onClick={() => handleOpenDeleteDialog(row)}
                                                    >
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={searchcustomer.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={addCustomerDialog}
                onClose={() => setAddCustomerDialog(false)}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <form onSubmit={handleAddCustomer}>
                    <DialogTitle id="form-dialog-title">Add New Customer</DialogTitle>
                    <DialogContent>
                        <Card variant="outlined" style={{ marginBottom: 10 }}>
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Personal Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={handleAddCustTextChange} name="firstName" label="First Name" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField onChange={handleAddCustTextChange} name="middleName" label="Middle Name" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={handleAddCustTextChange} name="lastName" label="Last Name" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <TextField onChange={handleAddCustTextChange} name="extName" label="Ext" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card style={{ marginBottom: 10 }} variant="outlined">
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Contact Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={handleAddCustTextChange} name="contactNumber" label="Contact Number" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={handleAddCustTextChange} name="email" type="email" label="Email Address" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    {/* <Grid item xs={12} md={4}>
                                        <TextField onChange={handleAddCustTextChange} name="custRefNumber" label="Account Number" variant="outlined" fullWidth size="small" />
                                    </Grid> */}
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={handleAddCustTextChange} name="tin_number" label="Tin Number" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Address Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField onChange={handleAddCustTextChange} name="address" label="Full Address" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddCustomerDialog(false)} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={updateCustDialog}
                onClose={() => setUpdateCustDialog(false)}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <form onSubmit={updateDataRequest}>
                    <DialogTitle id="form-dialog-title">Update Customer</DialogTitle>
                    <DialogContent>
                        <Card variant="outlined" style={{ marginBottom: 10 }}>
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Personal Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={updateTextChange} name="cust_fname" value={updateData.cust_fname} label="First Name" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField onChange={updateTextChange} name="cust_mname" value={updateData.cust_mname} label="Middle Name" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={updateTextChange} name="cust_lname" value={updateData.cust_lname} label="Last Name" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <TextField onChange={updateTextChange} name="cust_extname" value={updateData.cust_extname} label="Ext" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card style={{ marginBottom: 10 }} variant="outlined">
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Contact Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={updateTextChange} name="cust_contact" value={updateData.cust_contact} label="Contact Number" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={updateTextChange} disabled name="email" value={updateData.cust_email} type="email" label="Email Address" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField onChange={updateTextChange} name="tin_number" value={updateData.tin_number} label="Tin Number" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Address Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField onChange={updateTextChange} name="cust_address" value={updateData.cust_address} label="Full Address" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setUpdateCustDialog(false)} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={deleteCustomerDialog}
                onClose={() => setdeleteCustomerDialog(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">Delete Customer</DialogTitle>
                <DialogContent>
                    If this was the action you wanted to do, please confirm your choice, or cancel and return to the page
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setdeleteCustomerDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        handleDeleteCustomer()
                    }} type="submit" color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}