import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import OrderTableDetails from "./orderTableDetails";
import { sqlData } from "../../components/api";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./AddOrder/receiptDesign";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const formatNumber = (num) => {
  if (num != "") {
    var amount = parseFloat(num).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  } else {
    return 0;
  }
};
export default function OrderDetails(props) {
  const element = document.getElementById("textField");
  const [state, setState] = React.useState({
    orderItems: [],
    branchDetais: [],
    lastDetails: [],
    orderToPrint: {
      order: [],
      itemList: []
    }
  })
  function zeroPad(num) {
    return num.toString().padStart(13, "0");
  }
  const componentRef = React.useRef();
  const branch_id = useSelector((state) => state.loginReducer.branch_id);

  const { handleCloseOpenOrderDetails, open, selectedOrderDetails } = props;
  React.useEffect(() => {
    getOrderItems()
  }, [selectedOrderDetails])
  const getOrderItems = () => {
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        order_id: selectedOrderDetails.order_id,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {
      },
      selectData: "*",
    };
    let data2 = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: branch_id,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData: "*",
    };
    let order_id = ''
    if (typeof (selectedOrderDetails?.order_id) !== 'undefined') {
      order_id = selectedOrderDetails.order_id
    }
    let data3 = {
      order_id: order_id
    }
    Promise.all([
      sqlData(data, "", "POST", "orderItem/viewdata"),
      sqlData(data2, "", "POST", "branch/viewdata"),
      sqlData(data3, "", "POST", "order/onGetOrderDetails"),
    ]).then((result) => {
      setState(prev => ({
        ...prev,
        orderItems: result[0],
        branchDetais: result[1][0],
        lastDetails: result[2].lastDetails,
        orderToPrint: {
          order: result[2].lastDetails[0],
          itemList: result[0]
        }
      }))
    });
  }
  const onDownload = () => {
    window.open('https://api.store.gzonetechph.com/exportData/deliveryReceipt/' + selectedOrderDetails.order_id)
  }
  const onAfterPrint = () => {
    let data = {
      order_id: selectedOrderDetails.order_id,
    };
    sqlData(data, "", "POST", "order/onAfterPrint").then((result) => {
      // element.focus();
    });
  };
  const onClickReceipt = () => {
    document.getElementById("printReciept").click();
    // element.focus();
  }
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseOpenOrderDetails}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseOpenOrderDetails}
        >
          Order Details
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid xs={12} md={6}>
              <Typography variant="overline"><span style={{ fontWeight: 'bold' }}>Reciept No. : </span>{typeof selectedOrderDetails?.receipt_number != 'undefined' && zeroPad(selectedOrderDetails?.receipt_number)}</Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline"><span style={{ fontWeight: 'bold' }}>Date : </span>{moment(selectedOrderDetails.date_added).format('YYYY-MM-DD')}</Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline"><span style={{ fontWeight: 'bold' }}>Type : </span>{String(selectedOrderDetails?.order_type).toLocaleUpperCase()}</Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline"><span style={{ fontWeight: 'bold' }}>Branch : </span>{String(selectedOrderDetails.branch_name).toLocaleUpperCase()}</Typography>
            </Grid>
            <Grid xs={12} md={12}>
              <Typography variant="overline" style={{ fontWeight: 'bold' }}><span style={{ fontWeight: 'bold' }}>Subtotal : </span>{formatNumber(parseFloat(selectedOrderDetails.total_amount) + parseFloat(selectedOrderDetails.discount_amount))}</Typography>
            </Grid>
            <Grid xs={12} md={12}>
              <Typography variant="overline" style={{ fontWeight: 'bold' }}><span style={{ fontWeight: 'bold' }}>Discount : </span>{formatNumber(selectedOrderDetails.discount_amount)}</Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline" style={{ fontWeight: 'bold', fontSize: 20 }}><span style={{ fontWeight: 'bold' }}>Net Amount : </span>{formatNumber(selectedOrderDetails.total_amount)}</Typography>
            </Grid>
            <Grid xs={12} md={6} >
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button style={{ margin: 5 }} variant="contained" color="primary" onClick={() => { onDownload() }}>
                  Download
                </Button>
                <Button style={{ margin: 5 }} variant="contained" color="primary" onClick={() => { onClickReceipt() }}>
                  Receipt
                </Button>
              </div>
            </Grid>
            <Grid xs={12} md={12}>
              <OrderTableDetails orderItems={state.orderItems} />
            </Grid>

          </Grid>
        </DialogContent>
        <ReactToPrint
          onBeforeGetContent={() => { }}
          onAfterPrint={() => {
            onAfterPrint();
          }}
          on
          trigger={() => (
            <Button
              id="printReciept"
              style={{
                background: "#3498db",
                color: "#fff",
                display: "none",
              }}
            >
              Print Reciept
            </Button>
          )}
          content={() => componentRef.current}
        />
        <div style={{ display: "none" }}>
          <ComponentToPrint
            ref={componentRef}
            branchDetais={state.branchDetais}
            orderToPrint={state.orderToPrint}
            receiptNo={typeof selectedOrderDetails?.receipt_number != 'undefined' && zeroPad(selectedOrderDetails?.receipt_number)}
            lastDetails={state.lastDetails}
          />
        </div>
      </Dialog>

    </div>
  );
}
