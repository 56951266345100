import { TextField } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ViewListIcon from "@material-ui/icons/ViewList";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../../../components/api";
import InventorItems from "./InventoryItems";
import InventoryListItemTable from "./InventoryListTable";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
let height = window.innerHeight;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));
const ScanItem = forwardRef((props, ref) => {
  // export default function ScanItem({onClearDiscount}) {
  const { onClearDiscount } = props
  const dispatch = useDispatch();
  const element = document.getElementById("textField");
  const branch_id = useSelector((state) => state.loginReducer.branch_id);
  const [openInventoryList, setOpenInventoryList] = React.useState(false);
  const handleClickOpen = () => {
    setOpenInventoryList(true);
  };
  const handleClose = () => {
    setOpenInventoryList(false);
    setState(prev => ({
      ...
      prev, searchItemName: ''
    }))
    setTimeout(() => {
      element.focus();
    }, 200);
  };
  const selectedItems = useSelector(
    (state) => state.orderReducer.selectedItems
  );
  const orderType = useSelector((state) => state.orderReducer.orderType);
  const refreshStoreList = useSelector((state) => state.orderReducer.refreshStoreList);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [timer, setTimer] = useState(null);
  const [state, setState] = React.useState({
    itemList: [],
    search: "",
    selectedItem: [],
    quantity: "",
    radioSelected: "BARCODE",
    searchItemName: "",
    terms: []
  });

  React.useEffect(() => {
    getItems();
    getTerms()
  }, [refreshStoreList, branch_id]);

  const getItems = () => {
    dispatch({
      type: 'onChangeOrderReducer',
      data: {
        openLoading: true
      }
    })
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        'store_items.branch_id': branch_id,
        'inventory.delete_id':"0",
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {
        inventory: ["inventory.item_id = store_items.item_id", "left"],
        categories: ["categories.category_id = inventory.category_id", "left"],
      },
      selectData: "*,store_items.branch_id as branch_id",
    };
    sqlData(data, "", "POST", "storeitem/viewdata").then((result) => {
      console.log(result)
      setState((prev) => ({
        ...prev,
        itemList: result,
      }));
      dispatch({
        type: 'onChangeOrderReducer',
        data: {
          openLoading: false
        }
      })
    });
  };
  const getTerms = () => {
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        'branch_id': branch_id,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {

      },
      selectData: "*",
    };
    sqlData(data, "", "POST", "terms/viewdata").then((result) => {
      dispatch({
        type: 'onChangeOrderReducer',
        data: {
          terms: result
        }
      })
    });
  }
  React.useEffect(() => {
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        selectedItems: [],
      },
    });
  }, []);
  React.useEffect(() => {
    let search = state.itemList.filter((files) => {
      return (
        files.barcode !== null &&
        files.barcode !== "" &&
        files.barcode == state.search
        //   ||
        // (files.item_name !== null &&
        //   files.item_name !== "" &&
        //   String(files.item_name).toLocaleLowerCase() ==
        //     String(state.search).toLocaleLowerCase())
      );
    });
    if (search.length > 0) {
      let unit_price = parseFloat(search[0].unit_price);
      let retail_price = parseFloat(search[0].unit_price)
      let discount = 0
      if (orderType == "Whole Sale") {
        let price_wholesale = 0;
        if (search[0].price_wholesale != "") {
          price_wholesale = search[0].price_wholesale;
        }
        unit_price = price_wholesale;
        // discount = retail_price - search[0].price_wholesale
      }

      let newSelectedItem = {
        unit_price: unit_price,
        retail_price: retail_price,
        quantity: 1,
        item_id: search[0].item_id,
        total_price: unit_price,
        branch_id: search[0].branch_id,
        order_id: "",
        brand: search[0].brand,
        item_name: search[0].item_name,
        category_name: search[0].category_name,
        price_wholesale: search[0].price_wholesale,
        supplier_price: search[0].supplier_price,
        barcode: search[0].barcode,
        sku: search[0].sku,
        discount: discount,
        disc_record: '',
        unit: search[0].unit,

      };

      let newSelectedItemArray = JSON.parse(JSON.stringify(selectedItems));
      let match = newSelectedItemArray.findIndex(
        (val) => val.barcode == state.search
      );
      if (match == -1) {
        newSelectedItemArray.push(newSelectedItem);
      } else {
        newSelectedItemArray[match].quantity++;
        newSelectedItemArray[match].discount += discount;
        newSelectedItemArray[match].total_price =
          newSelectedItemArray[match].quantity *
          newSelectedItemArray[match].unit_price;
      }

      dispatch({
        type: "onChangeOrderReducer",
        data: {
          selectedItems: newSelectedItemArray,
        },
      });
      document.getElementById("textField").value = "";

      setState((prev) => ({
        ...prev,
        search: "",
      }));
      onClearDiscount()
    }

  }, [state.search]);
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }, 200)
    );
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  let num = state.selectedItem?.unit_price * state.quantity;
  const updateQuantity = () => {

    dispatch({
      type: "onChangeOrderReducer",
      data: {
        selectedItems: selectedItems.map((val, index) => {
          if (index == selectedItems.length - 1) {
            let price = 0;
            let discount = 0
            if (orderType == "Whole Sale" && val.price_wholesale != "") {
              price = val.price_wholesale;
              // discount = parseFloat(val.retail_price) - parseFloat(val.price_wholesale)
            } else if (orderType == "Retail") {
              price = parseFloat(val.unit_price);
            }
            return {
              ...val,
              quantity: state.search,
              total_price: state.search * price,
              discount: discount * state.search
            };
          } else {
            return val;
          }
        }),
      },
    });
    document.getElementById("textField").value = "";
    setState((prev) => ({
      ...prev,
      search: "",
    }));
    onClearDiscount()
    setOpenInventoryList(false)
  };

  const handleChangeRadioButton = (e) => {
    setState((prev) => ({
      ...prev,
      radioSelected: e.target.value,
    }));
  };

  let searchItemList = state.itemList.filter((files) => {
    return (
      (files.item_name !== null &&
        files.item_name
          .toLowerCase()
          .indexOf(state.searchItemName.toLocaleLowerCase()) !== -1) ||
      (files.brand !== null &&
        files.brand
          .toLowerCase()
          .indexOf(state.searchItemName.toLocaleLowerCase()) !== -1) ||
      (files.category_name !== null &&
        files.category_name
          .toLowerCase()
          .indexOf(state.searchItemName.toLocaleLowerCase()) !== -1) ||
      (files.item_description !== null &&
        files.item_description
          .toLowerCase()
          .indexOf(state.searchItemName.toLocaleLowerCase()) !== -1)
    );
  });

  const onSelectItemList = (details) => {
    if (details.barcode == "" || details.barcode == null) {
      alert("Please add barcode to this item");
    } else {
      if (details.unit_price == "" || details.unit_price == null) {
        alert("Please update the price of this item!");
      }
      else if (details.supplier_price == "" || details.supplier_price == null) {
        alert("Please update the supplier price of this item!");
      } else {
        setState((prev) => ({
          ...prev,
          search: details.barcode,
        }));
      }

    }
  };
  useImperativeHandle(ref, () => ({
    updateStoreListItem() {
      getItems();
    },
  }));

  // const onEditQuantity = (e) => {
  //   e.preventDefault();
  //   updateQuantity(indexEdit);
  // };

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <div style={{ position: "relative" }}>
              <Typography>Please Enter SKU</Typography>
              <TextField
                autoFocus
                onChange={onChangeText}
                name="search"
                id="textField"
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && selectedItems.length > 0) {
                    updateQuantity();
                  }
                }}
              />
              <ViewListIcon
                onClick={handleClickOpen}
                style={{
                  position: "absolute",
                  right: 7,
                  fontSize: 26,
                  top: 30,
                  color: "#757575",
                  cursor: "pointer",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
          </Grid>
        </Grid>
      </CardContent>
      <InventorItems
        open={openInventoryList}
        onChangeText={onChangeText}
        searchItemName={state.searchItemName}

        inventoryListItemTable={
          <InventoryListItemTable
            itemList={searchItemList}
            onSelectItemList={(details) => onSelectItemList(details)}
            onChangeText={onChangeText}
            updateQuantity={updateQuantity}

          />
        }
        handleClose={handleClose}
      />
    </Card>
  );
})
export default ScanItem
