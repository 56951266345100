import { ButtonGroup } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ReorderIcon from "@material-ui/icons/Reorder";
import SyncIcon from '@material-ui/icons/Sync';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import Logs from './Logs';
export default function Dashboard() {
  const dispatch = useDispatch();
  const blmcPage = useSelector((state) => state.pageReducer);
  const userLogin = useSelector((state) => state.loginReducer.userLogin[0]);
  const branch_id = useSelector((state) => state.loginReducer.userLogin[0].branch_id);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [viewLogs, setviewLogs] = useState(false);
  const [filter, setFilter] = React.useState('');
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [open, setOpen] = React.useState(false);
  const [stockDialog, setStockDialog] = React.useState(false);
  const [reducestockDialog, setReduceDialog] = React.useState(false);
  const [totalInvValue, setTotalInvValue] = useState(0)
  const [state, setState] = useState({
    init: 0,
    categoryId: 0,
    categories: [],
    currentItemId: 0,
    currentItem: {},
    nonstoreItems: [],
    selectedNonStoreItem: [],
    storeItems: [],
    storeItemsMain: []
  });
  let inventorySearch = state.storeItems.filter((files) => {
    return (
      (files.item_name !== undefined && files.item_name !== "" && files.item_name !== null) && files.item_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    );
  });
  const searchItem = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };
  const onSyncItem = () => {
    let storeItems = state.storeItems
    let nonStoreItems = []
    state.nonstoreItems.map((val) => {
      let data = {
        item_id: val.item_id,
        branch_id: userLogin.branch_id,
        store_quantity: 0,
      };
      nonStoreItems.push(data)
      storeItems.push(val)
    })
    if (nonStoreItems.length > 0) {
      sqlData(nonStoreItems, "", "POST", "storeitem/batchnonstore").then((result) => {
        setState(prev => ({
          ...prev,
          storeItems: [],
        }));
        getStoreItems()
        alert('Item sync Successfully!')
        setOpen(false)
      })
    } else {
      alert('All store items are already syncronized!')
      setOpen(false)

    }

  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onChangeText = (event) => {
    setState(prev => ({
      ...prev,
      [event.target.name]: event.target.value,

    }));
  };
  const handleOpenAddStock = (item) => {
    setState(prev => ({
      ...prev,
      currentItemId: item.item_id,
      currentItem: item,
    }));
    setStockDialog(true);
  };
  const handleOpenReduceStock = (item) => {
    setState(prev => ({
      ...prev,
      currentItemId: item.item_id,
      currentItem: item,
    }));
    setReduceDialog(true);
  };
  const addStock = (e) => {
    e.preventDefault();
    let data = {
      store_item_id: state.currentItem.store_item_id,
      store_quantity:
        parseFloat(state.updateStock) + parseFloat(state.currentItem.store_quantity),
      branch_id: userLogin.branch_id,
    };
    sqlData(data, "", "PUT", "storeitem/" + state.currentItem.store_item_id).then(
      (result) => {
        alert('Item updated successfully!')
        let log_data = {
          store_item_id: state.currentItem.store_item_id,
          s_item_id: state.currentItem.item_id,
          s_movement: state.s_movement,
          s_past_quantity: state.currentItem.store_quantity,
          s_new_quantity:
            parseFloat(state.updateStock) + parseFloat(state.currentItem.store_quantity),
          s_branch_id: userLogin.branch_id,
          s_update_value: state.updateStock,
        };
        sqlData(log_data, "", "POST", "storeitemlogs").then((result1) => {
          setStockDialog(false)
          getStoreItems();
        });
      }
    );
  };
  const reduceStock = (e) => {
    e.preventDefault();
    let data = {
      store_item_id: state.currentItem.store_item_id,
      store_quantity:
        parseFloat(state.currentItem.store_quantity) - parseFloat(state.updateStock),
      branch_id: userLogin.branch_id,
    };
    sqlData(data, "", "PUT", "storeitem/" + state.currentItem.store_item_id).then(
      (result) => {
        alert('Item updated successfully!')
        let log_data = {
          store_item_id: state.currentItem.store_item_id,
          s_item_id: state.currentItem.item_id,
          s_movement: state.s_movement,
          s_past_quantity: state.currentItem.store_quantity,
          s_new_quantity:
            parseFloat(state.currentItem.store_quantity) - parseFloat(state.updateStock),
          s_branch_id: userLogin.branch_id,
          s_update_value: state.updateStock,

        };
        sqlData(log_data, "", "POST", "storeitemlogs").then((result1) => {
          setReduceDialog(false)
          getStoreItems();
        });
      }
    );
  };
  const handleGetNonStoreItems = () => {
    setOpen(true)
    let data = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: userLogin.branch_id,
        delete_id: 0
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: [],
      selectData: "*",
    });
    let info = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: userLogin.branch_id,
        delete_id: 0
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: [],
      selectData: "*",
    });

    Promise.all([
      sqlData(data, "", "POST", "storeitem/viewdata"),
      sqlData(info, "", "POST", "inventory/viewdata")
    ]).then((result) => {
      let inventoryItems = []
      let nonstoreItems = []
      let storeItems = []
      storeItems = result[0]
      inventoryItems = result[1]
      inventoryItems.map((inv) => {
        let match = storeItems.findIndex((str) => (str.item_id === inv.item_id))
        if (match == -1) {
          nonstoreItems.push(inv)
        }
      })
      setState(prev => ({
        ...prev,
        nonstoreItems: nonstoreItems,
      }))


      let log_data = {
        store_item_id: state.currentItem.store_item_id,
        s_item_id: state.currentItem.item_id,
        s_movement: 'Add',
        s_past_quantity: state.currentItem.store_quantity,
        s_new_quantity:
          parseFloat(state.updateStock) + parseFloat(state.currentItem.store_quantity),
        s_branch_id: userLogin.branch_id
      };
      sqlData(log_data, "", "POST", "storeitemlogs").then((result1) => {
        // setOpenLoading(false);
        getStoreItems();
      });
    })
  };

  // const handleGetItems = () => {
  //   const data = JSON.stringify({
  //     page: 0,
  //     customWhere: false,
  //     count: false,
  //     customWhereStr: "",
  //     where: {},
  //     orWhere: {},
  //     like: {},
  //     orLike: {},
  //     groupBy: [],
  //     orderBy: [],
  //     join: {
  //       inventory: ["inventory.item_id = store_items.item_id", "left"],
  //       categories: ["categories.category_id = inventory.category_id", "left"],
  //     },
  //     selectData: "*",
  //   });

  //   sqlData(data, "", "POST", "storeitem/viewdata").then((result) => {
  //     dispatch({
  //       type: "setInitialData",
  //       requestResult: result,
  //     });
  //     setState(prev => ({
  //       ...prev,
  //       storeItems: result,
  //     }))
  //     const invValues = []

  //     result.map(inv => {
  //       let qtty = inv.store_quantity
  //       if (parseInt(inv.store_quantity) <= 0) {
  //         qtty = 0
  //       }
  //       invValues.push(qtty * inv.unit_price)

  //     })
  //     const totalInvValue = invValues.reduce((a, b) => a + parseFloat(b), 0)

  //     setTotalInvValue(totalInvValue)
  //   });
  // };

  const handleGetCategories = () => {
    const data = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: branch_id
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: [],
      selectData: "*",
    });

    sqlData(data, "", "POST", "category/viewdata").then((result) => {
      setState(prev => ({
        ...prev,
        categories: result,
      }))
    });
  };
  const getStoreItems = () => {
    let data = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        'store_items.branch_id': userLogin.branch_id,
        delete_id: '0',
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {
        inventory: ["inventory.item_id = store_items.item_id", "left"],
        categories: ["inventory.category_id = categories.category_id", "left"],
        suppliers: ["inventory.supplier_id = suppliers.supplier_id", "left"],
      },
      selectData: "*",
    });

    sqlData(data, "", "POST", "storeitem/viewdata").then((result) => {
      let storeitem = result
      storeitem.sort(function (a, b) {
        return a["item_name"].localeCompare(b["item_name"]);
      })
      setState(prev => ({
        ...prev,
        storeItems: storeitem,
        storeItemsMain: storeitem
      }))
      const invValues = []
      storeitem.map(inv => {
        let qtty = inv.store_quantity
        if (parseInt(inv.store_quantity) <= 0) {
          qtty = 0
        }
        invValues.push(qtty * inv.unit_price)
      })
      const totalInvValue = invValues.reduce((a, b) => a + parseFloat(b), 0)
      setTotalInvValue(totalInvValue)
    });
  };
  useEffect(() => {
    dispatch({
      type: "setPageTitle",
      requestResult: "Store Items",
    });
    handleGetCategories();
    getStoreItems()
    // handleGetItems();
  }, []);
  const handleChange = (event) => {
    let value = event.target.value
    let filter = [];

    if (value === "ALL") {
      filter = state.storeItemsMain;
    } else {
      filter = state.storeItemsMain.filter(
        (files) => files.category_name == value
      );
    }
    setFilter(value)
    setState(prev => ({
      ...prev,
      storeItems: filter,
    }));
  };
  return (
    <div>
      <Backdrop
        style={{ zIndex: 9999 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <Typography variant="subtitle2">Total Items</Typography>
                  <Typography variant="h6">
                    {state.storeItems.length} Items
                  </Typography>
                </Grid>
                {/* <Grid item md={4} xs={12}>
                  <Typography variant="subtitle2">Total Store Value</Typography>
                  <Typography variant="h6">
                    {totalInvValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </Typography>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => setviewLogs(true)}
            startIcon={<ReorderIcon />}
            color="primary"
            variant="contained"
            size='small'
          >
            Logs
          </Button>
        </Grid>

        <Grid item md={12} style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Search Item"
            onChange={(e) => {
              searchItem(e);
            }}
            variant="outlined"
          />
          <div>
            <FormControl style={{ width: '300px' }} size='small' variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Filter by Category</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filter}
                onChange={handleChange}
                label="Filter by Category"
              >

                <MenuItem value="ALL">
                  <em>ALL</em>
                </MenuItem>
                {state.categories.map((val) => {
                  return <MenuItem value={val.category_name}>{val.category_name}</MenuItem>
                })}
              </Select>
            </FormControl>


          </div>


        </Grid>
        <Grid item md={12}>
          <TableContainer style={{ maxHeight: 450 }} component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"

            >
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>In Stock</TableCell>
                  <TableCell> Retail Cost</TableCell>
                  <TableCell>Retail Value</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventorySearch
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    let qtty = row.store_quantity
                    if (parseInt(row.store_quantity) <= 0) {
                      qtty = 0
                    }
                    return < TableRow hover key={key} >
                      <TableCell>
                        <b>{row.item_name}</b>
                      </TableCell>
                      <TableCell>{row.category_name}</TableCell>
                      <TableCell>{row.supplier_name}</TableCell>
                      <TableCell>{row.store_quantity}</TableCell>
                      <TableCell>PHP {parseInt(row.unit_price).toLocaleString()}</TableCell>
                      <TableCell>
                        <b>
                          PHP {(qtty * row.unit_price).toLocaleString()}
                        </b>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Add Quantity">
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => { handleOpenAddStock(row) }}
                          >
                            <AddCircleOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Reduce Quantity">
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => { handleOpenReduceStock(row) }}
                          >
                            <RemoveCircleOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                    </TableRow>
                  }


                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 30, 50]}
            component="div"
            count={state.storeItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Syncronize Item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This operation will synchronize store inventory items. All inventory items will be synced into the store table.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpen(false) }} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { onSyncItem() }} color="primary" autoFocus>
            Sync
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={stockDialog}
        onClose={() => setStockDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Add Item Quantity</DialogTitle>
        <form onSubmit={addStock}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Quantity"
                  name="updateStock"
                  onChange={onChangeText}
                  required
                  type='number'
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  fullWidth
                  style={{ marginTop: 5 }}
                  required
                >
                  <InputLabel>Reason</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={onChangeText}
                    name='s_movement'
                  >
                    <MenuItem value='Restocking'>Restocking</MenuItem>
                    <MenuItem value='Retrieve Item'>Retrieve Item</MenuItem>
                    <MenuItem value='Other'>Other</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setStockDialog(false) }} color="primary">
              Cancel
            </Button>
            <Button type='submit' color="primary" autoFocus>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={reducestockDialog}
        onClose={() => setReduceDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Reduce Item Quantity</DialogTitle>
        <form onSubmit={reduceStock}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Quantity"
                  name="updateStock"
                  onChange={onChangeText}
                  required
                  type='number'
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  fullWidth
                  style={{ marginTop: 5 }}
                  required
                >
                  <InputLabel>Reason</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={onChangeText}
                    name='s_movement'
                  >
                    <MenuItem value='Lost Item'>Lost Item</MenuItem>
                    <MenuItem value='Manual Sales'>Manual Sales</MenuItem>
                    <MenuItem value='Other'>Other</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setReduceDialog(false) }} color="primary">
              Cancel
            </Button>
            <Button type='submit' color="primary" autoFocus>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={viewLogs}
        onClose={() => setviewLogs(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Inventory Logs</DialogTitle>
        <DialogContent>
          <Logs />
        </DialogContent>
      </Dialog>
    </div >
  );
}
