import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomizedDialogs from "../../reusableComponents/Dialog/dialog";
import { sqlData } from "../api";
import TransactionTableDetails from "./transactionTableDetails";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../Orders/AddOrder/receiptDesign";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const formatNumber = (num) => {
  if (num != "") {
    var amount = parseFloat(num).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  } else {
    return 0;
  }
};

export default function OrderDetails(props) {
  const dispatch = useDispatch();
  const loginBranch = useSelector((state) => state.loginReducer.userLogin[0].branch_id);
  const componentRef = React.useRef();

  function zeroPad(num) {
    return num.toString().padStart(13, "0");
  }
  const { handleCloseOpenOrderDetails, open, selectedOrderDetails } = props;
  React.useEffect(() => {
    getOrderItems();
    getLogs();
  }, [selectedOrderDetails]);
  const [state, setState] = React.useState({
    orderItems: [],
    payment_date: new Date(),
    payment: '',
    details: selectedOrderDetails,
    logs: [],
    barcode_id: '',
    branchDetais: [],
    lastDetails: []
  });
  const getOrderItems = () => {
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        openLoading: true,
      },
    });
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        order_id: selectedOrderDetails.order_id,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData: "*",
    };
    let data2 = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: loginBranch,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData: "*",
    };
    let order_id = ''
    if (typeof (selectedOrderDetails?.order_id) !== 'undefined') {
      order_id = selectedOrderDetails.order_id
    }
    let data3 = {
      order_id: order_id
    }
    Promise.all([
      sqlData(data, "", "POST", "orderItem/viewdata"),
      sqlData(data2, "", "POST", "branch/viewdata"),
      sqlData(data3, "", "POST", "order/onGetOrderDetails"),
    ]).then((result) => {
      setState(prev => ({
        ...prev,
        orderItems: result[0],
        branchDetais: result[1][0],
        lastDetails: result[2].lastDetails,
        orderToPrint: {
          order: result[2].lastDetails[0],
          itemList: result[0]
        }
      }))
      dispatch({
        type: "onChangeOrderReducer",
        data: {
          openLoading: false,
        },
      });
    });
  };
  const getLogs = () => {
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        'transaction_logs.t_order_id': selectedOrderDetails?.order_id
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData: "*",
    }
    sqlData(data, "", "POST", "transactionlogs/viewdata").then((result) => {
      setState((prev) => ({
        ...prev,
        logs: result,
      }));
    });
  };
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onPayment = () => {
    if (state.payment === 0 || state.payment_date === '') {
      alert('Please complete the details')
    } else {
      let total_blnc = selectedOrderDetails.total_balance
      let change = 0
      let stat = 'Unpaid'
      if (parseFloat(state.payment) >= parseFloat(total_blnc)) {
        total_blnc = 0
        change = parseFloat(state.payment) - parseFloat(selectedOrderDetails.total_balance)
        stat = 'Paid'
      } else {
        total_blnc = parseFloat(selectedOrderDetails.total_balance) - parseFloat(state.payment)
      }
      let data = {
        order_id: selectedOrderDetails.order_id,
        payment_date: moment(state.payment_date).format('YYYY-MM-DD HH:mm'),
        cash: state.payment,
        total_balance: total_blnc,
        change: change,
        order_status: stat,
      }
      sqlData(data, "", "PUT", "order/" + selectedOrderDetails.order_id).then((result) => {
        let info = {
          t_order_id: selectedOrderDetails.order_id,
          t_branch_id: selectedOrderDetails.branch_id,
          user_id: selectedOrderDetails.user_id,
          t_order_status: stat,
          t_cash: state.payment,
          t_change: change,
          t_total_balance: total_blnc,
          t_total_amount: selectedOrderDetails.total_amount,
          t_payment_date: moment(state.payment_date).format('YYYY-MM-DD HH:mm')
        }
        sqlData(info, "", "POST", "transactionlogs").then((res) => {
          alert('Trasaction updated successfully')
          setState((prev) => ({
            ...prev,
            payment: 0,
            payment_date: 0,
          }));
          handleCloseOpenOrderDetails()
        })
      })
    }
  };
  const onDownload = () => {
    window.open('https://api.store.gzonetechph.com/exportData/deliveryReceipt/' + selectedOrderDetails.order_id)
  }
  const onVoid = () => {
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: loginBranch,
        user_type: 3,
        barcode_id: state.barcode_id
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData: "*",
    };
    sqlData(data, "", "POST", "users/viewdata").then((result) => {
      if (result.length !== 0) {
        let info = {
          order_id: selectedOrderDetails.order_id,
          branch_id: selectedOrderDetails.branch_id,
        }
        sqlData(info, "", "POST", "order/voidTransaction").then(
          (result) => {
            handleCloseOpenOrderDetails()
            setState((prev) => ({
              ...prev,
              barcode_id: '',
            }));
            alert('Transaction successfully voided!')
          })
      } else {
        alert('Barcode not available!')
      }
    })
  }
  const onAfterPrint = () => {
    let data = {
      order_id: selectedOrderDetails.order_id,
    };
    sqlData(data, "", "POST", "order/onAfterPrint").then((result) => {
      // element.focus();
    });
  };
  const onClickReceipt = () => {
    document.getElementById("printReciept").click();
    // element.focus();
  }
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseOpenOrderDetails}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseOpenOrderDetails}
        >
          Transaction Details
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'flex-start' }}>
              {selectedOrderDetails.order_status === 'Unpaid' ?
                <CustomizedDialogs
                  dialogTitle={"Payments"}
                  buttonName={"Payment"}
                  size='xs'
                  icon="add"
                  dialogContent={<AddPayment onChangeText={onChangeText} state={state} selectedOrderDetails={selectedOrderDetails} onPayment={onPayment} />}
                />
                :
                undefined
              }
              <div style={{ marginLeft: 10 }}>
                <CustomizedDialogs
                  dialogTitle={"Logs"}
                  buttonName={"Logs"}
                  icon="reorder"
                  size='md'
                  dialogContent={<ViewLogs getLogs={getLogs} onChangeText={onChangeText} state={state} selectedOrderDetails={selectedOrderDetails} onPayment={onPayment} />}
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <Button variant="contained" color="primary" onClick={() => { onDownload() }}>
                  Download
                </Button>
              </div>
              <div style={{ marginLeft: 10 }}>
                <Button variant="contained" color="primary" onClick={() => { onClickReceipt() }}>
                  Reciept
                </Button>
              </div>
              <div style={{ marginLeft: 10 }}>
                <CustomizedDialogs
                  dialogTitle={"Void Transaction"}
                  buttonName={"Void"}
                  icon="cancel"
                  size='xs'
                  dialogContent={<Void onChangeText={onChangeText} state={state} selectedOrderDetails={selectedOrderDetails} onPayment={onPayment} onVoid={onVoid} />}
                />
              </div>

            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline">
                <span style={{ fontWeight: "bold" }}>Reciept No. : </span>
                {typeof selectedOrderDetails?.receipt_number != "undefined" &&
                  zeroPad(selectedOrderDetails?.receipt_number)}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline">
                <span style={{ fontWeight: "bold" }}>Date : </span>
                {moment(selectedOrderDetails.date_added).format("YYYY-MM-DD")}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline">
                <span style={{ fontWeight: "bold" }}>Type : </span>
                {String(selectedOrderDetails?.order_type).toLocaleUpperCase()}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline">
                <span style={{ fontWeight: "bold" }}>Branch : </span>
                {String(selectedOrderDetails.branch_name).toLocaleUpperCase()}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline" style={{ fontWeight: "bold" }}>
                <span style={{ fontWeight: "bold" }}>Subtotal : </span>
                {formatNumber(
                  parseFloat(selectedOrderDetails.total_amount) +
                  parseFloat(selectedOrderDetails.discount_amount)
                )}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline" style={{ fontWeight: "bold" }}>
                <span style={{ fontWeight: "bold" }}>Cash : </span>
                {formatNumber(selectedOrderDetails.cash)}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline" style={{ fontWeight: "bold" }}>
                <span style={{ fontWeight: "bold" }}>Discount : </span>
                {formatNumber(selectedOrderDetails.discount_amount)}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant="overline" style={{ fontWeight: "bold" }}>
                <span style={{ fontWeight: "bold" }}>Change : </span>
                {formatNumber(selectedOrderDetails.change)}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography
                variant="overline"
                style={{ fontWeight: "bold", fontSize: 20 }}
              >
                <span style={{ fontWeight: "bold" }}>Net Amount : </span>
                {formatNumber(selectedOrderDetails.total_amount)}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography
                variant="overline"
                style={{ fontWeight: "bold", fontSize: 20 }}
              >
                <span style={{ fontWeight: "bold" }}>Balance : </span>
                {formatNumber(selectedOrderDetails.total_balance)}
              </Typography>
            </Grid>
            <Grid xs={12} md={12}>
              <TransactionTableDetails orderItems={state.orderItems} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ReactToPrint
        onBeforeGetContent={() => { }}
        onAfterPrint={() => {
          onAfterPrint();
        }}
        on
        trigger={() => (
          <Button
            id="printReciept"
            style={{
              background: "#3498db",
              color: "#fff",
              display: "none",
            }}
          >
            Print Reciept
          </Button>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          branchDetais={state.branchDetais}
          orderToPrint={state.orderToPrint}
          receiptNo={state.receiptNo}
          lastDetails={state.lastDetails}
        />
      </div>
    </div>
  );
}
const AddPayment = (props) => {
  const { onChangeText, state, selectedOrderDetails, onPayment } = props
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.payment_date}
          onChange={onChangeText}
          name="payment_date"
          fullWidth
          size="small"
          type="date"
          variant="outlined"
          label="Payment Date"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          value={selectedOrderDetails.total_balance}
          disabled
          name="balance"
          fullWidth
          size="small"
          variant="outlined"
          label="Balance"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          value={state.payment}
          onChange={onChangeText}
          name="payment"
          fullWidth
          size="small"
          variant="outlined"
          label="Amount to pay"
        />
      </Grid>
      <Grid item sm={12}>
        <Button onClick={() => { onPayment() }}>
          Submit
        </Button>
      </Grid>
    </Grid>

  )
}

const ViewLogs = (props) => {
  const { onChangeText, state, selectedOrderDetails, onPayment, getLogs } = props
  React.useEffect(() => {
    getLogs()
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <TableContainer style={{ maxHeight: 400, maxWidth: '92vw' }} component={Paper}>
          <Table
            stickyHeader
            aria-label='sticky table'
            size='small'
            style={{ whiteSpace: 'nowrap' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Log ID</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Payment</TableCell>
                <TableCell>Change</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Date Payed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.logs.map((val) => {
                return <TableRow hover >
                  <TableCell>{val.t_log_id}</TableCell>
                  <TableCell>{val.t_order_id}</TableCell>
                  <TableCell>{val.t_order_status}</TableCell>
                  <TableCell>{'Php ' + parseFloat(val.t_total_amount).toFixed(2)}</TableCell>
                  <TableCell>{'Php ' + parseFloat(val.t_cash).toFixed(2)}</TableCell>
                  <TableCell>{'Php ' + parseFloat(val.t_change).toFixed(2)}</TableCell>
                  <TableCell>{'Php ' + parseFloat(val.t_total_balance).toFixed(2)}</TableCell>
                  <TableCell>{moment(val.t_payment_date).format("YYYY-MM-DD")}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>

  )
}

const Void = (props) => {
  const { state, onChangeText, selectedOrderDetails, onVoid } = props
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="overline">
          Enter barcode id to void transaction
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.barcode_id}
          onChange={onChangeText}
          name="barcode_id"
          fullWidth
          size="small"
          variant="outlined"
          label="Barcode"
        />
      </Grid>
      <Grid item sm={12}>
        <Button onClick={() => { onVoid() }} variant="contained" color="primary" >
          Void
        </Button>
      </Grid>
    </Grid>

  )
}