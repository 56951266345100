import Backdrop from "@material-ui/core/Backdrop";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import ClearIcon from '@material-ui/icons/Clear';
import { Container } from "@material-ui/core";

const columns = [
    { id: 'supplier_id', label: 'ID', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'supplier_name', label: 'Supplier Name', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'supplier_address', label: 'Supplier Address', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'supplier_description', label: 'Supplier Description', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'supplier_email', label: 'Email Address', format: (value) => String(value).toLocaleUpperCase() },
    { id: 'supplier_contact', label: 'Contact', format: (value) => String(value).toLocaleUpperCase() },
];
const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Customer() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const loginBranch = useSelector((state) => state.loginReducer.userLogin[0].branch_id);

    const [state, setState] = useState({
        supplier_name: '',
        supplier_short: '',
        supplier_address: '',
        supplier_description: '',
        supplier_contact: 0,
        supplier_email: '',
        main_supplier: [],
        search_array_supplier: [],
        search_word: '',
        supplier_id: ''
    });
    const [addCustomerDialog, setAddSupplierDialog] = useState(false);
    const [deleteCustomerDialog, setDeleteSupplierDialog] = useState(false);
    const [custID, setcustID] = useState('');
    const [customers, setCustomers] = useState([])
    const [searchcustomer, setCustomerSearch] = useState([])
    const [openLoading, setOpenLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getSupplierData = () => {
        const data = JSON.stringify({
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {
                branch_id: loginBranch,
                sup_delete_id: '0'
            },
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {},
            selectData: "*",
        });

        sqlData(data, "", "POST", "suppliers/viewdata").then((result) => {
            setState(prev => ({
                ...prev,
                main_supplier: result,
                search_array_supplier: result
            }));
        });
    }
    const addSupplierSubmit = (e) => {
        e.preventDefault();
        setOpenLoading(true);
        let result = state.main_supplier.filter((find) => find.supplier_name.toUpperCase() == state.supplier_name.toUpperCase())
        if (result.length === 0) {
            const data = {
                supplier_name: state.supplier_name,
                supplier_short: state.supplier_short,
                supplier_description: state.supplier_description,
                supplier_address: state.supplier_address,
                supplier_contact: state.supplier_contact,
                supplier_email: state.supplier_email,
                branch_id: loginBranch,
            };
            sqlData(data, "", "POST", "suppliers").then((result) => {
                alert('Supplier added successfully!')
                setOpenLoading(false);
                getSupplierData()
            });
        } else {
            alert('Supplier already exist!')
            setOpenLoading(false);
        }

    };
    useEffect(() => {
        dispatch({
            type: "setPageTitle",
            requestResult: "Suppliers",
        });
        getSupplierData();
    }, [loginBranch])

    const handleAddSupplierTextChange = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }
    const handleOpenDeleteDialog = (data) => {
        setDeleteSupplierDialog(true)
        setState(prev => ({
            ...prev,
            supplier_id: data.supplier_id
        }));
    }
    const handleDeleteSupplier = () => {
        const data = {
            supplier_id: state.supplier_id,
            sup_delete_id: 1
        }
        sqlData(data, "", "PUT", "suppliers/" + state.supplier_id).then(res => {
            alert('Supplier deleted succesfully')
            setDeleteSupplierDialog(false)
            getSupplierData();
        }
        )
    }

    const [updateData, setUpdateData] = useState([])
    const [updateSupplierDialog, setupdateSupplierDialog] = useState(false)

    const handleUpdateItem = (data) => {
        setUpdateData(data)
        setupdateSupplierDialog(true)
        setState(prev => ({
            ...prev,
            supplier_id: data.supplier_id
        }));
    }
    const searchItem = (e) => {
        setState(prev => ({
            ...prev,
            search_word: e.target.value,
        }));
        setPage(0);
    };
    let SupplierSearch = state.search_array_supplier.filter((files) => {
        return (
            (files.supplier_name !== undefined && files.supplier_name !== "" && files.supplier_name !== null) && files.supplier_name.toLowerCase().indexOf(state.search_word.toLocaleLowerCase()) !== -1
        );
    });
    const onChangeTextUpdate = (event) => {
        setUpdateData({
            ...updateData,
            [event.target.name]: event.target.value,
        });
    };
    const handleUpdateSupplierData = (e) => {
        e.preventDefault();
        setOpenLoading(true);
        let result = state.main_supplier.filter((find) => find.supplier_name.toUpperCase() == updateData.supplier_name.toUpperCase())
        if (result.length === 0) {
            let data = {
                supplier_address: updateData.supplier_address,
                supplier_contact: updateData.supplier_contact,
                supplier_description: updateData.supplier_description,
                supplier_email: updateData.supplier_email,
                supplier_id: updateData.supplier_id,
                supplier_name: updateData.supplier_name,
                supplier_short: updateData.supplier_short,
            }
            sqlData(data, "", "PUT", "suppliers/" + state.supplier_id).then(
                (result) => {
                    getSupplierData()
                    setOpenLoading(false);
                    alert('Supplier updated successfully!')
                })
        } else {
            alert('Supplier name already exist!')
            setOpenLoading(false);
        }


    }
    return (
        <Container maxWidth='xl'>
            <Backdrop
                style={{ zIndex: 9999 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start">
                        <TextField
                            size="small"
                            id="outlined-helperText"
                            label="Search"
                            variant="outlined"
                            onChange={(e) => {
                                searchItem(e);
                            }}
                            value={state.searchfield}
                            name='searchfield'
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} >
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained" color="primary"
                            onClick={() => setAddSupplierDialog(true)}
                        >
                            Add Supplier
                        </Button>
                    </Box>

                </Grid>
                <Grid item xs={12} md={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 450 }}>
                        <Table
                            className={classes.table}
                            size="small"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, fontWeight: 600 }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SupplierSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                let value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && value != null ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <Tooltip title="Update Entry">
                                                    <IconButton
                                                        onClick={() => handleUpdateItem(row)}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Remove Entry">
                                                    <IconButton
                                                        onClick={() => handleOpenDeleteDialog(row)}
                                                    >
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={SupplierSearch.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={addCustomerDialog}
                onClose={() => setAddSupplierDialog(false)}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <IconButton color="primary" size='small' onClick={() => { setAddSupplierDialog(false) }}>
                                <ClearIcon />
                            </IconButton>
                            <Typography variant='h6'>
                                Add New Supplier
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <form onSubmit={addSupplierSubmit}>
                    <DialogContent>
                        <Card variant="outlined" style={{ marginBottom: 10 }}>
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Supplier Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={handleAddSupplierTextChange} name="supplier_name" label="Supplier Name" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={handleAddSupplierTextChange} name="supplier_short" label="Short Name" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField onChange={handleAddSupplierTextChange} name="supplier_description" label="Supplier Description" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField onChange={handleAddSupplierTextChange} name="supplier_address" label="Supplier Address" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={handleAddSupplierTextChange} name="supplier_email" label="Supplier Email" variant="outlined" fullWidth size="small" type='email' />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={handleAddSupplierTextChange} name="supplier_contact" label="Supplier Contact" variant="outlined" fullWidth size="small" type='number' />
                                    </Grid>
                                    <Grid item sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button type="submit" variant='contained' color="primary">
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={updateSupplierDialog}
                onClose={() => setupdateSupplierDialog(false)}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                <form onSubmit={handleUpdateSupplierData}>
                    <DialogTitle id="form-dialog-title">
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <IconButton color="primary" size='small' onClick={() => { setupdateSupplierDialog(false) }}>
                                    <ClearIcon />
                                </IconButton>
                                <Typography variant='h6'>
                                    Update Supplier Details
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Card variant="outlined" style={{ marginBottom: 10 }}>
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ marginBottom: 20 }}
                                >
                                    Supplier Details
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={onChangeTextUpdate} value={updateData.supplier_name} name="supplier_name" label="Supplier Name" required variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={onChangeTextUpdate} value={updateData.supplier_short} name="supplier_short" label="Short Name" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField onChange={onChangeTextUpdate} value={updateData.supplier_description} name="supplier_description" label="Supplier Description" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField onChange={onChangeTextUpdate} value={updateData.supplier_address} name="supplier_address" label="Supplier Address" variant="outlined" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={onChangeTextUpdate} value={updateData.supplier_email} name="supplier_email" label="Supplier Email" variant="outlined" fullWidth size="small" type='email' />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField onChange={onChangeTextUpdate} value={updateData.supplier_contact} name="supplier_contact" label="Supplier Contact" variant="outlined" fullWidth size="small" type='number' />
                                    </Grid>
                                    <Grid item sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button type="submit" variant='contained' color="primary">
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </DialogContent>
                </form>
            </Dialog>
            <Dialog
                open={deleteCustomerDialog}
                onClose={() => setDeleteSupplierDialog(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">Delete Supplier</DialogTitle>
                <DialogContent>
                    If this was the action you wanted to do, please confirm your choice, or cancel and return to the page
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteSupplierDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        handleDeleteSupplier()
                    }} type="submit" color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}