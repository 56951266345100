import axios from "axios";
let serverurl = "https://api.store.gzonetechph.com/";
// let serverurl = "https://beta-storefront.api.gzonetechph.com/";
// let serverurl = "http://192.168.0.10/backend/storefront-beta-api/";


export function getData(method, param) {
    return new Promise((resolve, reject) => {
        axios
            .post(serverurl + method, {
                param
            })
            .then(res => {
                resolve(res.data);
            });
    });
}
export function sqlData(sql, token, method_data, controller) {

    return new Promise((resolve, reject) => {
        var config = {
            method: method_data,
            url: serverurl + controller,
            headers: {
                'Authorization': 'Bearer' + ' ' + token,
                'Content-Type': 'application/json'
            },
            data: sql
        };
        axios(config)
            .then(res => {
                resolve(res.data);
            })
            .catch((error) => {
                let errorRes = JSON.stringify(error).includes('code 401')
                // if(errorRes){
                //     dispatch({
                //         type: 'loginData',
                //         data: []
                //       })
                // }
            })
            ;
    });
}

export function sqlDataDelete(token, method_data, controller) {
    return new Promise((resolve, reject) => {
        var config = {
            method: method_data,
            url: serverurl + controller,
            // url: 'http://192.168.0.9/backend/betaapiv2/'+controller,
            headers: {
                'Authorization': 'Bearer' + ' ' + token,
                'Content-Type': 'application/json'
            }
        };
        axios(config)
            .then(res => {
                resolve(res.data);
            });
    });
}

export function UploadImage(file, controller, method_data, token) {

    return new Promise((resolve, reject) => {
        //   const dispatch = useDispatch()
        var config = {
            method: method_data,
            url: serverurl + controller,
            // url: 'http://192.168.0.9/backend/betaapiv2/'+controller,
            headers: {
                'Authorization': 'Bearer' + ' ' + token,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            data: file
        };
        axios(config)
            .then(res => {
                resolve(res.data);
            })
            .catch((error) => {
                let errorRes = JSON.stringify(error).includes('code 401')
                // if(errorRes){
                //     dispatch({
                //         type: 'loginData',
                //         data: []
                //       })
                // }
            })
            ;
    });
}






