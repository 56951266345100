import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import moment from 'moment';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

function Deposits({ allSales, allProfit, totalAmountSupplier }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* <Title>Total Inventory Cost</Title>
      <Typography component="p" variant="h4">
        {totalAmountSupplier == 'NaN' ? 0 : 'Php ' + totalAmountSupplier.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {'as of ' + moment().format("MMM DD, Y") + ' (Supplier Price)'}
      </Typography> */}
      <Title>Total Sales</Title>
      <Typography component="p" variant="h4">
        {allSales == 'NaN' ? 0 : allSales.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {'as of ' + moment().format("MMM DD, Y")}
      </Typography>
      <Title>Total Profit</Title>
      <Typography component="p" variant="h4">
        {allProfit != 'NaN' ? allProfit : 0}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {'as of ' + moment().format("MMM DD, Y")}
      </Typography>
      <div>
        {/* <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link> */}
      </div>
    </React.Fragment>
  );
}
export default React.memo(Deposits)