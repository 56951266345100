import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Box from '@material-ui/core/Box';

import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import PostAddIcon from "@material-ui/icons/PostAdd";

import 'date-fns';


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sqlData, sqlDataDelete } from "../api";



export default function Suppliers() {
    const dispatch = useDispatch();
    const blmcPage = useSelector((state) => state.pageReducer);
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        dispatch({
            type: "setPageTitle",
            requestResult: "Supply Chain | Suppliers",
        });

        handleGetCategories();
        handleGetItems();
    }, []);

    const [addItemDialog, setItemDialog] = useState(false);
    const [addCategoryDialog, setAddCatDialog] = useState(false);
    const [updateItemDialog, setUpdateItemDialog] = useState(false);
    const [addStockDialog, setAddStockDialog] = useState(false);
    const [releaseStockDialog, setReleaseStockDialog] = useState(false);
    const [openLoading, setOpenLoading] = React.useState(false);

    //Data Table
    const [search, setSearch] = useState("");
    const searchItem = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let supplierSearch = blmcPage.mainData.filter((files) => {
        return (
            (files.supplier_name !== undefined && files.supplier_name !== "" && files.supplier_name !== null) && files.supplier_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
        );
    });

    //!Data Table

    //Notifs
    const [successUpdated, setSuccessUpdated] = useState(false);
    const [successReleased, setSuccessReleased] = useState(false);
    const [successAddStock, setSuccessAddStock] = useState(false);
    //!Notifs

    const [state, setState] = useState({
        init: 0,
        categoryId: 0,
        categoryName: "",
        categories: [],
        currentItemId: 0,
        currentItem: {},
    });

    const [categorySelect, setCategorySelect] = useState("");
    const [branchSelect, setBranchSelect] = useState(1);
    const [unitSelect, setUnitSelect] = useState("");

    const setCategory = (e) => { };
    const setUnit = (e) => { };
    const handleDeleteCategory = (deleteId) => {
        sqlDataDelete("", "DELETE", "category/" + deleteId).then((result) => {
            handleGetCategories();
        });
    };

    const onChangeText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const onChangeTextUpdate = (event) => {
        setUpdateData({
            ...updateData,
            [event.target.name]: event.target.value,
        });

    };

    const onChangeTextRelease = (event) => {
        if (event.target.value >= state.currentItem.quantity) {
            alert("Masyadong Madami");
        }
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const selectCategory = (e) => {
        setCategorySelect(e.target.value);
    };

    const selectBranch = (e) => {
        setBranchSelect(e.target.value);
    };

    const addItemSubmit = (e) => {
        e.preventDefault();
        setOpenLoading(true);
        const data = {
            supplier_name: state.supplierName,
            supplier_address: state.address,
            supplier_contact: state.contactNumber,
            supplier_email: state.emailAddress,
        };
        sqlData(data, "", "POST", "suppliers").then((result) => {
            setOpenLoading(false);
            setItemDialog(false);
            handleGetItems();
        });
    };

    const [updateData, setUpdateData] = useState({});
    const [updateDataRetain, setUpdateDataRetain] = useState({});

    const handleUpdateItem = (item) => {
        setUpdateData(item);
        setUpdateDataRetain(item);
        setState({
            ...state,
            currentItemId: item.item_id,
            currentItem: item,
        });
        setUpdateItemDialog(true);
    };

    const handleUpdateItemData = (e) => {
        e.preventDefault();
        setOpenLoading(true);
        sqlData(updateData, "", "PUT", "inventory/" + state.currentItemId).then(
            (result) => {
                const log_data = {
                    item_id: state.currentItemId,
                    movement: "update",
                    past_price: updateDataRetain.unit_price,
                    new_price: updateData.unit_price,
                    past_supp_price: updateDataRetain.supplier_price,
                    new_supp_price: updateData.supplier_price,
                };

                sqlData(log_data, "", "POST", "inventorylogs").then((result1) => {
                    setOpenLoading(false);
                    setUpdateItemDialog(false);
                    handleGetItems();
                    setSuccessUpdated(true);
                });
            }
        );
    };

    const handleGetItems = () => {
        const data = JSON.stringify({
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {},
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {},
            selectData: "*",
        });

        sqlData(data, "", "POST", "suppliers/viewdata").then((result) => {
            dispatch({
                type: "setInitialData",
                requestResult: result,
            });
        });
    };

    const handleOpenAddStock = (item) => {
        setState({
            ...state,
            currentItemId: item.item_id,
            currentItem: item,
        });

        setAddStockDialog(true);
    };

    const addStock = (e) => {
        e.preventDefault();
        setOpenLoading(true);
        const data = {
            item_id: state.currentItemId,
            quantity:
                parseFloat(state.updateStock) + parseFloat(state.currentItem.quantity),
        };

        sqlData(data, "", "PUT", "inventory/" + state.currentItemId).then(
            (result) => {
                const log_data = {
                    item_id: state.currentItemId,
                    movement: "Add/Reduce",
                    past_quantity: parseFloat(state.currentItem.quantity),
                    new_quantity:
                        parseFloat(state.updateStock) +
                        parseFloat(state.currentItem.quantity),
                    update_value: parseInt(state.updateStock),
                };

                sqlData(log_data, "", "POST", "inventorylogs").then((result1) => {
                    setOpenLoading(false);
                    setAddStockDialog(false);
                    handleGetItems();
                    setSuccessAddStock(true);
                });
            }
        );
    };

    const handleOpenReleaseStock = (item) => {
        setState({
            ...state,
            currentItemId: item.item_id,
            currentItem: item,
        });

        setReleaseStockDialog(true);
    };

    const releaseStock = (e) => {
        e.preventDefault();
        setOpenLoading(true);
        const data = {
            item_id: state.currentItemId,
            quantity:
                parseFloat(state.currentItem.quantity) - parseFloat(state.updateStock),
        };
        sqlData(data, "", "PUT", "inventory/" + state.currentItemId).then(
            (result) => {
                const transfer_data = {
                    item_id: state.currentItemId,
                    branch_id: branchSelect,
                    transfer_quantity: state.updateStock,
                    transfer_status: "Pending",
                };
                sqlData(transfer_data, "", "POST", "transfers").then((res) => {
                    const log_data = {
                        item_id: state.currentItemId,
                        movement: "Transfer",
                        past_quantity: parseFloat(state.currentItem.quantity),
                        new_quantity:
                            parseFloat(state.currentItem.quantity) -
                            parseFloat(state.updateStock),
                        update_value: parseInt(state.updateStock),
                        brach_id: branchSelect,
                    };

                    sqlData(log_data, "", "POST", "inventorylogs").then((result1) => {
                        setOpenLoading(false);
                        setReleaseStockDialog(false);
                        handleGetItems();
                        setSuccessReleased(true);
                    });
                });
            }
        );
    };

    const addCategorySubmit = (e) => {
        e.preventDefault();
        const data = {
            category_name: state.categoryName,
        };
        setOpenLoading(true);
        sqlData(data, "", "POST", "category").then((result) => {
            setOpenLoading(false);
            handleGetCategories();
            setState({
                ...state,
                categoryName: "",
            });
        });
    };

    const selectUpdateCategory = (e) => {
        setUpdateData({
            ...updateData,
            category_id: e.target.value,
        });
    };

    const handleGetCategories = () => {
        const data = JSON.stringify({
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {},
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: [],
            selectData: "*",
        });

        sqlData(data, "", "POST", "category/viewdata").then((result) => {
            setState({
                ...state,
                categoryName: "",
                categories: result,
            });
        });
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                open={successUpdated}
                autoHideDuration={5000}
                onClose={() => setSuccessUpdated(false)}
                message="Successfully Updated"
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={setSuccessUpdated}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "left",
                    horizontal: "center",
                }}
                open={successAddStock}
                autoHideDuration={5000}
                onClose={() => setSuccessAddStock(false)}
                message="Stock Added Successfully!"
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSuccessAddStock(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                style={{ color: "#27ae60" }}
                open={successReleased}
                autoHideDuration={5000}
                onClose={() => setSuccessReleased(false)}
                message="Successfully Released from inventory"
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={setSuccessReleased}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <Backdrop
                style={{ zIndex: 9999 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <Navigation/> */}
            <Container >
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <Box flexDirection='row' justifyContent='flex-start'>

                        </Box>
                    </Grid>
                    <Grid item sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonGroup
                            color="primary"
                            variant="contained"
                            style={{ marginBottom: 10 }}
                        >
                            <Button
                                onClick={() => setItemDialog(true)}
                                startIcon={<PostAddIcon />}
                            >
                                Add Supplier
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item sm={12}>
                        <TableContainer component={Paper}>
                            <Table
                                size="small"
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Supplier Id</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Contact</TableCell>
                                        <TableCell>Email</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {supplierSearch
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, key) => (
                                            <TableRow hover key={key}>
                                                <TableCell>
                                                    <b>{row.supplier_id}</b>
                                                </TableCell>
                                                <TableCell>{row.supplier_name}</TableCell>
                                                <TableCell>{row.supplier_address}</TableCell>
                                                <TableCell>{row.supplier_contact}</TableCell>
                                                <TableCell>{row.supplier_email}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={blmcPage.mainData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />

                    </Grid>
                </Grid>
                <Dialog
                    open={addItemDialog}
                    onClose={() => setItemDialog(false)}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md">
                    <form onSubmit={addItemSubmit}>
                        <DialogTitle id="form-dialog-title">Add New Supplier</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        style={{ marginTop: 19 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Supplier Name"
                                        name="supplierName"
                                        onChange={onChangeText}
                                        value={state.supplierName}
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        style={{ marginTop: 19 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Address"
                                        name="address"
                                        onChange={onChangeText}
                                        value={state.address}
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        style={{ marginTop: 19 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Contact Number"
                                        name="contactNumber"
                                        onChange={onChangeText}
                                        value={state.contactNumber}
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        style={{ marginTop: 19 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Email Address"
                                        name="emailAddress"
                                        onChange={onChangeText}
                                        value={state.emailAddress}
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setItemDialog(false)} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={addCategoryDialog}
                    onClose={() => setAddCatDialog(false)}
                    aria-labelledby="form-dialog-tit                                                                  le"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">Add New Supplier</DialogTitle>
                    <form onSubmit={addCategorySubmit}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Category Name"
                                        name="categoryName"
                                        onChange={onChangeText}
                                        value={state.categoryName}
                                        type="text"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: 20 }}>
                                {state.categories.map((category) => (
                                    <Chip
                                        label={category.category_name}
                                        onDelete={() => handleDeleteCategory(category.category_id)}
                                        style={{
                                            marginRight: 5,
                                            marginBottom: 5,
                                            backgroundColor: "#2980b9",
                                            color: "#fff",
                                        }}
                                    />
                                ))}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddCatDialog(false)} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={updateItemDialog}
                    onClose={() => setUpdateItemDialog(false)}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <form onSubmit={handleUpdateItemData}>
                        <DialogTitle id="form-dialog-title">Update Item</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        onChange={onChangeTextUpdate}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Item Name"
                                        name="item_name"
                                        type="text"
                                        required
                                        value={updateData.item_name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: 5 }}
                                        required
                                    >
                                        <InputLabel>Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={updateData.category_id}
                                            onChange={selectUpdateCategory}
                                        >
                                            <MenuItem value={0}>No Category</MenuItem>
                                            {state.categories.map((row) => (
                                                <MenuItem value={row.category_id}>
                                                    {row.category_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        onChange={onChangeTextUpdate}
                                        margin="dense"
                                        id="name"
                                        label="Quantity"
                                        type="text"
                                        name="quantity"
                                        fullWidth
                                        required
                                        helperText="Initial Quantity"
                                        value={updateData.quantity}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        onChange={onChangeTextUpdate}
                                        margin="dense"
                                        id="name"
                                        name="supplier_price"
                                        label="Supplier Price"
                                        helperText="Price per Unit"
                                        type="text"
                                        required
                                        fullWidth
                                        value={updateData.supplier_price}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        onChange={onChangeTextUpdate}
                                        margin="dense"
                                        id="name"
                                        label="Store Price"
                                        name="unit_price"
                                        helperText="Retail price per Unit"
                                        type="text"
                                        required
                                        fullWidth
                                        value={updateData.unit_price}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setUpdateItemDialog(false)}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={addStockDialog}
                    onClose={() => setAddStockDialog(false)}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title">Add Stock</DialogTitle>
                    <form onSubmit={addStock}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Quantity"
                                        name="updateStock"
                                        onChange={onChangeText}
                                        type="text"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddStockDialog(false)} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={releaseStockDialog}
                    onClose={() => setReleaseStockDialog(false)}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title">Release Stock</DialogTitle>
                    <form onSubmit={releaseStock}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Quantity"
                                        name="updateStock"
                                        onChange={onChangeTextRelease}
                                        type="text"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: 5, marginBottom: 5 }}
                                        required
                                    >
                                        <InputLabel>Branch</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={branchSelect}
                                            onChange={selectBranch}
                                        >
                                            <MenuItem value={1}>Branch 1</MenuItem>
                                            {/* {state.categories.map((row) => (
                        <MenuItem value={row.category_id}>
                          {row.category_name}
                        </MenuItem>
                      ))} */}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setReleaseStockDialog(false)}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Container>
        </div>
    );
}
