import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { HashRouter as Router, Route } from "react-router-dom";

import Login from "../src/components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Inventory from "./components/Inventory/Index";
import Customers from "./components/Customers/Index";
import Orders from "./components/Orders/Index";
import StoreItems from "./components/Store/Items";
import SupplyChain from "./components/SupplyChain/Supplychain";
import Navigation from "./components/templates/Navigation";
import Transactions from "./components/Transactions/Index";
import CashRemittance from "./components/CashRemittance";
import Supplier from './components/Supp/supp'
import Splash from './components/splash'
import Reports from './components/Reports/Report'
import { onMessageListener, getTokenVal } from "./components/firebase";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    padding: 30
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
function App() {
  const classes = useStyles();
  const blmcLogin = useSelector((state) => state.loginReducer);
  const [open] = React.useState(false);
  const [notification, setNotification] = React.useState({
    title: "",
    body: "",
  });
  const [isTokenFound, setTokenFound] = React.useState(false);
  const [tokenValue, setTokenValue] = React.useState("");
  getTokenVal(setTokenFound, setTokenValue);
  onMessageListener()
    .then((payload) => {
      // getRequestCount()
      // setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  if (blmcLogin.loginData.length !== 0) {
    return (
      <Router>
        {blmcLogin.userLogin.map((val, index) => {
          return (
            <div className={classes.root} key={index}>
              <Navigation />
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <Route exact path="/" component={Splash} />
                {val.page_dashboard == 1 && (
                  <Route exact path="/dashboard" component={Dashboard} />
                )}
                {val.page_supplier == 1 && (
                  <Route path="/supplier" component={Supplier} />
                )}
                {val.page_inventory == 1 && (
                  <Route path="/inventory" component={Inventory} />
                )}
                {val.page_orders == 1 && (
                  <Route path="/orders" component={Orders} />
                )}
                {val.page_store_item == 1 && (
                  <Route path="/store/items" component={StoreItems} />
                )}
                {val.page_supply_chain == 1 && (
                  <Route path="/supplychain" component={SupplyChain} />
                )}
                {val.page_customers == 1 && (
                  <Route path="/customers" component={Customers} />
                )}
                {val.page_transactions == 1 && (
                  <Route path="/transactions" component={Transactions} />
                )}
                {val.page_transactions == 1 && (
                  <Route path="/cashRemittance" component={CashRemittance} />
                )}
                {val.page_reports == 1 && (
                  <Route path="/reports" component={Reports} />
                )}

              </main>
            </div>
          );
        })}
      </Router>
    );
  } else {
    return (
      <Router>
        <Route exact path="/" component={Login} />
      </Router>
    );
  }
}

export default App;
