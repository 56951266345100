import { combineReducers } from "redux";
import pageReducer from "./pageReducer";
import loginReducer from "./loginReducer";
import orderReducer from "./orderReducer";

export default combineReducers({
  pageReducer: pageReducer,
  loginReducer: loginReducer,
  orderReducer: orderReducer,
});
