// import React, { useState, useEffect } from 'react';
// import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
// // import Title from './Title';
// import { sqlData } from "../api";
// import moment from 'moment'
// import { ContactlessOutlined } from '@material-ui/icons';
// import { useDispatch, useSelector } from "react-redux";


// // Generate Sales Data
// function createData(time, amount) {
//     return { time, amount };
// }

// const data = [
//     createData('00:00', 0),
//     createData('03:00', 300),
//     createData('06:00', 600),
//     createData('09:00', 800),
//     createData('12:00', 1500),
//     createData('15:00', 2000),
//     createData('18:00', 2400),
//     createData('21:00', 2400),
//     createData('24:00', undefined),
// ];

// export default function Chart({ monthlySales }) {
//     const theme = useTheme();
//     return (
//         <React.Fragment>
//             {/* <Title>Daily Sales</Title> */}
//             <ResponsiveContainer>
//                 <LineChart
//                     data={monthlySales}
//                     margin={{
//                         top: 16,
//                         right: 16,
//                         bottom: 0,
//                         left: 24,
//                     }}
//                 >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="" stroke={theme.palette.text.secondary} />
//                     <YAxis stroke={theme.palette.text.secondary}>
//                         <Label
//                             angle={270}
//                             position="left"
//                             style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
//                         >
//                             Sales (PHP)
//                         </Label>
//                     </YAxis>
//                     <Tooltip />
//                     <Line type="monotone" dataKey="Total Sales" stroke={theme.palette.primary.main} dot={true} />
//                 </LineChart>
//             </ResponsiveContainer>
//         </React.Fragment>
//     );
// }




import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, Label, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Title from './Title';

const data = [
    {
        month: 'Page A',
        wholesale: 4000,
        retail: 2400,
        total: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export default function SaleChart({ monthlySales }) {
    // export default class Example extends PureComponent {
    return (
        <div >
            <Title>Daily Sales</Title>
            <ResponsiveContainer width={'99%'} height={200}>
                <BarChart
                    width={500}
                    height={300}
                    data={monthlySales}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis>
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: 'middle' }}
                        >
                            Monthly Sales (Php)
                        </Label>
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="wholesale" stackId="a" fill="#8884d8" />
                    <Bar dataKey="retail" stackId="a" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </div >

    );
}
