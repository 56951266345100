const initialState = {
  mainData: [],
  selectionData1: [],
  newPageData: [],
  pendingTransferCount: 0,
  pageTitle: "Dashboard"
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setInitialData":
      return {
        ...state,
        mainData: action.requestResult,
      };
    case "setPageTitle":
      return {
        ...state,
        pageTitle: action.requestResult
      }
    case "setPendingtransferCount":
      return {
        ...state,
        pendingTransferCount: action.requestResult
      }
    case "clearData":
      return state;
    default:
      return state;
  }
};

export default dataReducer
