import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useDispatch, useSelector } from "react-redux";
import Icon from '@material-ui/core/Icon';
import TransactionDetails from '../Transactions/transactionDetails'
import Title from './Title';
import moment from 'moment'

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
  createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
  createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const Orders = ({ ordersToday }) => {
  const classes = useStyles();
  const [selectedOrderDetails, setSelectedOrderDetails] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const openLoading = useSelector((state) => state.orderReducer.openLoading);
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  }
  function zeroPad(num) {
    return num.toString().padStart(13, "0");
  }
  const handleClickOpenOrderDetails = (data) => {
    setSelectedOrderDetails(data);
    setOpen(true);
  };
  const handleCloseOpenOrderDetails = () => {
    setOpen(false);

  }
  return (
    <React.Fragment>
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 9999 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Title>Recent Orders</Title>
      <Table>
        <TableHead style={{ background: "#fff" }}>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Reference #</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Date Ordered</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Time Ordered</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Staff</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Customer</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Total Amount</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Profit</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ordersToday.map((order, key) => (
            <TableRow>
              <TableCell>{key + 1}</TableCell>
              <TableCell>{zeroPad(order.receipt_number)}</TableCell>
              <TableCell>{order.order_type}</TableCell>
              <TableCell>
                {moment(order.date_added).format("Y-MM-DD")}
              </TableCell>
              <TableCell>{moment(order.date_added).format("LT")}</TableCell>
              <TableCell>{order.full_name}</TableCell>
              <TableCell>
                {order.cust_lname}, {order.cust_extname} {order.cust_fname}{" "}
                {order.cust_mname}
              </TableCell>
              <TableCell>{formatNumber(order.total_amount)}</TableCell>
              <TableCell>
                {formatNumber(
                  parseFloat(order.total_amount) -
                  parseFloat(order.total_supplier_price)
                )}
              </TableCell>
              <TableCell>{order.order_status}</TableCell>
              <TableCell>
                <Icon
                  onClick={() => handleClickOpenOrderDetails(order)}
                  style={{ cursor: "pointer", color: "#757575" }}
                >
                  launched
                </Icon>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <a color="primary" href="#/transactions" >
          See more orders
        </a>
      </div>
      <TransactionDetails
        open={open}
        selectedOrderDetails={selectedOrderDetails}
        handleCloseOpenOrderDetails={handleCloseOpenOrderDetails}
      />
    </React.Fragment>
  );
}
export default React.memo(Orders)