import { ButtonGroup } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from '@material-ui/core/TableRow';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import TableChartIcon from '@material-ui/icons/TableChart';
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactExport from 'react-data-export';
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import SalesChart from './Sale Chart';
import Title from './Title';
import TotalSales from "./Total Sales";
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);



export default function Dashboard() {
    const dispatch = useDispatch();
    const branch_id = useSelector((state) => state.loginReducer.userLogin[0].branch_id);
    const [openLoading, setOpenLoading] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const [downloadSale, setdownloadSale] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({
        from: "",
        to: "",
        monthlySales: [],
        totalSumSales: [],
        totalbyTypeSales: [],
        allInventory: [],
        restockAllInventory: [],
        store_item_monthly_log: [],
        totalSumProfit: [],
        inventoryTotal: [],
        inventoryTotal2: [],
        SearchallInventory: [],

    });


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [search, setSearch] = useState("");
    const searchItem = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };
    let SearchallInventory = state.SearchallInventory.filter((files) => {
        return (
            (files.item_name !== undefined && files.item_name !== "" && files.item_name !== null) && files.item_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
        );
    });
    const onChangeText = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setState((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        dispatch({
            type: "setPageTitle",
            requestResult: "Reports",
        });
    }, []);
    const fetchReports = (e) => {
        e.preventDefault()
        let data = {
            branch_id: branch_id,
            monthFrom: moment(state.from).startOf('month').format('YYYY-MM-DD'),
            monthTo: moment(state.to).endOf('month').format('YYYY-MM-DD'),
        }
        let stocks = JSON.stringify({
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {
                'store_items.branch_id': branch_id,
                delete_id: '0',
            },
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {
                inventory: ["inventory.item_id = store_items.item_id", "left"],
                categories: ["inventory.category_id = categories.category_id", "left"],
                suppliers: ["inventory.supplier_id = suppliers.supplier_id", "left"],
            },
            selectData: "*, store_items.branch_id as b_id",
        });
        let monthlyInventory = {
            page: 0,
            customWhere: false,
            count: false,
            customWhereStr: "",
            where: {
                'store_item_logs.s_branch_id': branch_id,
                'inventory.delete_id': '0',
                // 'store_item_logs.s_item_id': 12
            },
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {
                inventory: ["inventory.item_id = store_item_logs.s_item_id", "left"],
                store_items: ["store_items.item_id = store_item_logs.s_item_id", "left"],
            },
            selectData: "*,concat(year(store_item_logs.s_date_updated),'-',month(store_item_logs.s_date_updated))as month",
        }
        Promise.all([
            sqlData(data, "", "POST", "order/reportSales"),
            sqlData(stocks, "", "POST", "storeitem/viewdata"),
            sqlData(monthlyInventory, "", "POST", "storeitemlogs/viewdata")
        ]).then((result) => {
            let inventory = result[1]
            inventory.sort(function (a, b) {
                return a["item_name"].localeCompare(b["item_name"]);
            })
            let store_item_logs = result[2].reverse()
            let store_item_log = []
            let mSales = result[0].monthlySales
            let chartdata = []
            let restock = []
            let invRetailValues = []
            let invWholeSaleValues = []

            mSales.map((sales) => {
                let retailtotal = '0'
                let wholesaletotal = '0'
                if (sales.order_type === 'Retail') {
                    retailtotal = sales.total
                } else if (sales.order_type === 'Whole Sale') {
                    wholesaletotal = sales.total
                }
                let match = false
                chartdata.map((cdata) => {
                    if (moment(cdata.month).format('MMMM YYYY') === moment(sales.month).format('MMMM YYYY')) {
                        match = true
                        cdata.retail += parseFloat(retailtotal)
                        cdata.wholesale += parseFloat(wholesaletotal)
                        cdata.profit += parseFloat(sales.profit)
                        cdata.total += parseFloat(sales.total)
                    }
                })
                if (!match) {
                    let chart = {
                        month: moment(sales.month).format('MMMM YYYY'),
                        wholesale: parseFloat(wholesaletotal),
                        retail: parseFloat(retailtotal),
                        total: parseFloat(sales.total),
                        profit: parseFloat(sales.profit),
                    }
                    chartdata.push(chart)
                }
            })
            inventory.map((val) => {
                if (parseInt(val.store_quantity) <= 10) {
                    restock.push(val)
                }
            })
            store_item_logs.map((val) => {
                let match = store_item_log.filter((array) => array.s_item_id === val.s_item_id && moment(array.s_date_updated).format('YYYY-MM') === moment(val.s_date_updated).format('YYYY-MM'))
                if (match.length == 0) {
                    store_item_log.push(val)
                }
            })
            inventory.map(inv => {
                let qtty = inv.store_quantity
                let unit_price = parseInt(inv.unit_price)
                let supplier_price = parseFloat(inv.supplier_price)
                if (parseInt(inv.store_quantity) <= 0) {
                    qtty = 0
                }
                if (parseInt(inv.store_quantity) <= 0) {
                    unit_price = 0
                }
                if (parseInt(inv.supplier_price) <= 0) {
                    supplier_price = 0
                }

                invRetailValues.push(qtty * unit_price)
                invWholeSaleValues.push(qtty * supplier_price)

            })

            let totalAmountRetailInventory = invRetailValues.reduce((a, b) => a + parseFloat(b), 0)
            let totalAmountSupplierInventory = invWholeSaleValues.reduce((a, b) => a + parseFloat(b), 0)
            let inventoryTotal = {
                totalAmountRetail: formatNumber(totalAmountRetailInventory),
                totalAmountSupplier: formatNumber(totalAmountSupplierInventory)
            }
            setState((prev) => ({
                ...prev,
                monthlySales: chartdata,
                totalSumSales: result[0].totalSumSales[0],
                totalSumProfit: result[0].totalSumProfit[0],
                totalbyTypeSales: result[0].totalbyTypeSales,
                allInventory: inventory,
                restockAllInventory: restock,
                store_item_monthly_log: store_item_logs,
                inventoryTotal: [inventoryTotal],
                inventoryTotal2: inventoryTotal,
                SearchallInventory: inventory
            }));
        })

    }
    const handleClick = (event) => {
        setdownloadSale(event.currentTarget);
    };

    const handleClose = () => {
        setdownloadSale(null);
    };
    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString;
        } else {
            return 0;
        }
    };
    return (
        <div>
            <Backdrop
                style={{ zIndex: 9999 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Grid spacing={2} container justify="flex-end">
                        <form onSubmit={fetchReports}>
                            <TextField
                                style={{ marginRight: 5 }}
                                InputLabelProps={{ shrink: true }}
                                value={state.from}
                                onChange={onChangeText}
                                name="from"
                                size="small"
                                type="month"
                                variant="outlined"
                                label="From"
                                max={new Date()}
                                required
                            />

                            <TextField
                                style={{ marginRight: 5 }}
                                InputLabelProps={{ shrink: true }}
                                value={state.to}
                                onChange={onChangeText}
                                name="to"
                                size="small"
                                type="month"
                                variant="outlined"
                                label="To"
                                max={new Date()}
                                required
                            />
                            <ButtonGroup
                                color="primary"
                                variant="contained"
                                style={{ marginBottom: 10 }}
                            >
                                <Button type='submit'>Filter</Button>
                            </ButtonGroup>
                        </form>
                        <Button color="primary"
                            variant="contained"
                            style={{ marginBottom: 10, marginLeft: 5 }} onClick={handleClick}>
                            Download
                        </Button>
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={downloadSale}
                            keepMounted
                            open={Boolean(downloadSale)}
                            onClose={handleClose}
                        >
                            <ExcelFile
                                filename={'Monthly Sales Summary from' + state.from + ' to ' + state.to}
                                element={
                                    <StyledMenuItem>
                                        <ListItemIcon>
                                            <TableChartIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Sales" />
                                    </StyledMenuItem>
                                }
                            >
                                <ExcelSheet
                                    data={state.monthlySales}
                                    name='Sales Monthly Summary'
                                >
                                    <ExcelColumn label='Month' value='month' />
                                    <ExcelColumn label='Retail' value='retail' />
                                    <ExcelColumn label='Wholesale' value='wholesale' />
                                    <ExcelColumn label='Total Sales' value='total' />
                                    <ExcelColumn label='Total Profit' value='profit' />
                                </ExcelSheet>
                            </ExcelFile>
                            <ExcelFile
                                filename={'Inventory Summary'}
                                element={
                                    <StyledMenuItem>
                                        <ListItemIcon>
                                            <TableChartIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Inventory" />
                                    </StyledMenuItem>
                                }
                            >
                                <ExcelSheet
                                    data={state.allInventory}
                                    name='Current Inventory'
                                >
                                    <ExcelColumn label='Item Name' value='item_name' />
                                    <ExcelColumn label='Category' value='category_name' />
                                    <ExcelColumn label='Brand Name' value='brand' />
                                    <ExcelColumn label='Unit' value='unit' />
                                    <ExcelColumn label='Quantity' value='store_quantity' />
                                    <ExcelColumn label='Supplier' value='supplier_name' />
                                    <ExcelColumn label='Supplier Price' value='supplier_price' />
                                    <ExcelColumn label='Retail Price' value='unit_price' />
                                    <ExcelColumn label='Wholesale Price' value='price_wholesale' />
                                </ExcelSheet>
                                <ExcelSheet
                                    data={state.restockAllInventory}
                                    name='Inventory Items less than 10'
                                >
                                    <ExcelColumn label='Item Name' value='item_name' />
                                    <ExcelColumn label='Category' value='category_name' />
                                    <ExcelColumn label='Brand Name' value='brand' />
                                    <ExcelColumn label='Unit' value='unit' />
                                    <ExcelColumn label='Quantity' value='store_quantity' />
                                    <ExcelColumn label='Supplier' value='supplier_name' />
                                    <ExcelColumn label='Supplier Price' value='supplier_price' />
                                    <ExcelColumn label='Retail Price' value='unit_price' />
                                    <ExcelColumn label='Wholesale Price' value='price_wholesale' />
                                </ExcelSheet>
                                <ExcelSheet
                                    data={state.store_item_monthly_log}
                                    name='Inventory Items Last Status Logs'
                                >
                                    <ExcelColumn label='Item Name' value='item_name' />
                                    <ExcelColumn label='Category' value='category_name' />
                                    <ExcelColumn label='Brand Name' value='brand' />
                                    <ExcelColumn label='Unit' value='unit' />
                                    <ExcelColumn label='Past Quantity' value='s_past_quantity' />
                                    <ExcelColumn label='Quantity' value='s_new_quantity' />
                                    <ExcelColumn label='Update Value' value='s_update_value' />
                                    <ExcelColumn label='Supplier' value='supplier_name' />
                                    <ExcelColumn label='Supplier Price' value='supplier_price' />
                                    <ExcelColumn label='Retail Price' value='unit_price' />
                                    <ExcelColumn label='Wholesale Price' value='price_wholesale' />
                                    <ExcelColumn label='Movement' value='s_movement' />
                                    <ExcelColumn label='Log Date' value='s_date_updated' />

                                </ExcelSheet>
                                <ExcelSheet
                                    data={state.inventoryTotal}
                                    name='Inventory Total Cost'
                                >
                                    <ExcelColumn label='Total Amount Inventory Retail Price' value='totalAmountRetail' />
                                    <ExcelColumn label='Total Amount Inventory Supplier Price ' value='totalAmountSupplier' />
                                </ExcelSheet>
                            </ExcelFile>
                        </StyledMenu>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography
                        style={{ fontWeight: "bold", fontSize: 18 }}
                        variant="overline"
                    >
                        Reports
                    </Typography>
                </Grid>

                <Grid item md={12} >
                    <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Paper style={{ minHeight: 250, width: '70%', padding: 10 }}>
                            <SalesChart monthlySales={state.monthlySales} />
                        </Paper>
                        <Paper style={{ minHeight: 250, width: '25%', padding: 10 }}>
                            <TotalSales totalSumSales={state.totalSumSales} totalbyTypeSales={state.totalbyTypeSales} from={state.from} to={state.to} totalSumProfit={state.totalSumProfit} />
                        </Paper>
                    </div>
                </Grid>

                <Grid item md={12} >
                    <TableContainer component={Paper}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 600 }}>Month</TableCell>
                                    <TableCell style={{ fontWeight: 600 }}>Retail</TableCell>
                                    <TableCell style={{ fontWeight: 600 }}>Wholesale</TableCell>
                                    <TableCell style={{ fontWeight: 600 }}>Total Sales</TableCell>
                                    <TableCell style={{ fontWeight: 600 }}>Total Profits</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.monthlySales.map((val) => (
                                    <TableRow>
                                        <TableCell> {moment(val.month).format('MMMM YYYY')} </TableCell>
                                        <TableCell>{'Php ' + formatNumber(val.retail)}</TableCell>
                                        <TableCell>{'Php ' + formatNumber(val.wholesale)}</TableCell>
                                        <TableCell>{'Php ' + formatNumber(val.total)}</TableCell>
                                        <TableCell>{'Php ' + formatNumber(val.profit)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item md={12} >
                    <Grid container spacing={2} >
                        <Grid item md={4} >
                            <Card variant="outlined">
                                <CardContent>
                                    <Title>Total Inventory Retail Price</Title>
                                    <Typography variant="body2" component="p">
                                        {state.inventoryTotal2.length === 0 ? 'Php ' + 0 : 'Php ' + state.inventoryTotal2.totalAmountRetail}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4} >
                            <Card variant="outlined">
                                <CardContent>
                                    <Title>Total Inventory Supplier Price</Title>
                                    <Typography variant="body2" component="p">
                                        {state.inventoryTotal2.length === 0 ? 'Php ' + 0 : 'Php ' + state.inventoryTotal2.totalAmountSupplier}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4} >
                            <Card variant="outlined">
                                <CardContent>
                                    <Title>Total Quantity Merchendise</Title>
                                    <Typography variant="body2" component="p">
                                        {state.allInventory.length === 0 ? + 0 : state.allInventory.length}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        size="small"
                        id="outlined-basic"
                        label="Search Item"
                        onChange={(e) => {
                            searchItem(e);
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={12}>
                    <TableContainer style={{ maxHeight: 400, maxWidth: '95vw' }} component={Paper}>
                        <Table
                            stickyHeader
                            aria-label='sticky table'
                            size='small'
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Brand Name</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell>Supplier Price</TableCell>
                                    <TableCell>Retail Price</TableCell>
                                    <TableCell>Wholesale Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SearchallInventory
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, key) => (
                                        <TableRow hover key={key}>
                                            <TableCell>{row.item_name} </TableCell>
                                            <TableCell>{row.category_name}</TableCell>
                                            <TableCell>{row.brand}</TableCell>
                                            <TableCell>{row.unit}</TableCell>
                                            <TableCell>{row.store_quantity}</TableCell>
                                            <TableCell>{row.supplier_name}</TableCell>
                                            <TableCell>{row.supplier_price}</TableCell>
                                            <TableCell>{row.unit_price}</TableCell>
                                            <TableCell>{row.price_wholesale}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 50]}
                        component="div"
                        count={SearchallInventory.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">Syncronize Item</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This operation will synchronize store inventory items. All inventory items will be synced into the store table.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
