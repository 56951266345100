import {
  Button, ButtonGroup, Card, CardContent, FormControlLabel, Grid, Icon, IconButton,
  MenuItem, Radio, RadioGroup, Typography
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import EmojiSymbolsIcon from "@material-ui/icons/EmojiSymbols";
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from "moment";
import React, { forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { sqlData } from "../../../components/api";
import ComponentToPrint from "./receiptDesign";
let height = window.innerHeight;
const formatNumber = (num) => {
  if (num != "") {
    var amount = parseFloat(num).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  } else {
    return 0;
  }
};
const columns = [
  {
    id: "item_id",
    label: "Item Id",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "item_name",
    label: "Item Name",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "brand",
    label: "Brand",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  { id: "quantity", label: "Quantity" },
  { id: "unit_price", label: "Price", format: (value) => formatNumber(value) },
  { id: "discount", label: "DISC.", format: (value) => formatNumber(value) },
  { id: "disc_record", label: "Add. Disc." },
  { id: "total_price", label: "Total", format: (value) => formatNumber(value) },

  // {
  //   id: "population",
  //   label: "Population",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "size",
  //   label: "Size\u00a0(km\u00b2)",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 360,
  },
  containerAbsolute: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
});
const ItemList = forwardRef((props, ref) => {
  // export default function ItemList() {
  const { onClearDiscount } = props;
  const element = document.getElementById("textField");
  const elementQuantity = document.getElementById("quantity");
  const orderType = useSelector((state) => state.orderReducer.orderType);
  const terms = useSelector((state) => state.orderReducer.terms);
  const refreshStoreList = useSelector(
    (state) => state.orderReducer.refreshStoreList
  );
  const userLogin = useSelector((state) => state.loginReducer.userLogin);
  const branch_id = useSelector((state) => state.loginReducer.branch_id);

  const cash_register_id = useSelector(
    (state) => state.orderReducer.cash_register_id
  );
  const componentRef = React.useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [editModal, setEditModal] = React.useState(false);
  const [discModal, setDiscModal] = React.useState(false);
  const [quantity, setQuantity] = React.useState(false);
  const [itemEdit, setItemEdit] = React.useState("");
  const [itemDiscount, setItemDiscount] = React.useState("");
  const [disc, setDisc] = React.useState(0);
  const [customerIDSelect, setCustomerIDSelect] = React.useState("");
  const [customerNameSelect, setCustomerNameSelect] = React.useState("");
  const [customerAddressSelect, setCustomerAddressSelect] = React.useState("");
  const [state, setState] = React.useState({
    discountModal: false,
    discountValue: "",
    discountType: "Amount",
    discountAmount: 0,
    paymentModal: false,
    payment: "",
    branchDetais: [],
    receiptNo: "",
    orderToPrint: [],
    lastDetails: [],
    customerName: "",
    date: new Date(),
    term: "Cash",
    payment_type: "Cash",
    customers: [],
    payment_due: 0,
    payment_due_date: new Date(),
    delivery_date: new Date()
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const selectedItems = useSelector(
    (state) => state.orderReducer.selectedItems
  );
  const TransType = useSelector(
    (state) => state.orderReducer.orderType
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const onClick = () => {
    element.focus();
  };
  const removeItem = (index) => {
    if (window.confirm("Are you sure you want to remove this Item?")) {
      let newSelectedItems = selectedItems.filter(
        (val, index2) => index2 != index
      );
      dispatch({
        type: "onChangeOrderReducer",
        data: {
          selectedItems: newSelectedItems,
        },
      });
      element.focus();
    }
  };

  const onChangeText = (e, index) => {
    let value = e.target.value;
    setQuantity(value);
  };

  const onChangeTextValue = (e, index) => {
    let value = e.target.value;
    let name = e.target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onEditQuantity = (e) => {
    e.preventDefault();
    updateQuantity(itemEdit);
  };
  const updateQuantity = (itemEdit) => {
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        selectedItems: selectedItems.map((val, index) =>
          itemEdit == ""
            ? index == selectedItems.length - 1
              ? {
                ...val,
                quantity: quantity,
                disc_record: '',
                discount: (val.retail_price * quantity) - (val.unit_price * quantity),
                total_price: quantity * val.unit_price,
              }
              : val
            : val.item_id == itemEdit
              ? {
                ...val,
                quantity: quantity,
                disc_record: '',
                discount: (val.retail_price * quantity) - (val.unit_price * quantity),
                total_price: quantity * val.unit_price,
              }
              : val
        ),
      },
    });

    setEditModal(false);
    setItemEdit("");
    setTimeout(() => {
      element.focus();
    }, 100);
  };

  const onOpenCloseDiscountModal = (status) => {
    setState((prev) => ({
      ...prev,
      discountModal: status,
    }));
  };
  const handleChangeDiscount = (e) => {
    setState((prev) => ({
      ...prev,
      discountType: e.target.value,
    }));
  };
  const addDiscount = (e) => {
    e.preventDefault();
    let discountAmount = 0;
    if (state.discountType == "Amount") {
      discountAmount = parseFloat(state.discountValue).toFixed(2);
    } else {
      let total = selectedItems.reduce((count, val) => {
        return (count += val.total_price);
      }, 0);
      discountAmount = parseFloat((parseFloat(state.discountValue).toFixed(2) / 100) * total).toFixed(
        2
      );
    }
    setState((prev) => ({
      ...prev,
      discountAmount: discountAmount,
    }));
    onOpenCloseDiscountModal(false);
  };
  useImperativeHandle(ref, () => ({
    clearDiscount() {
      setState((prev) => ({
        ...prev,
        discountAmount: 0,
        discountValue: "",
      }));
    },
  }));
  let paymentValue = 0;
  if (state.payment != "") {
    paymentValue = state.payment;
  }
  let totalNet =
    selectedItems.reduce((count, val) => {
      return (count += val.total_price);
    }, 0) - state.discountAmount;
  let change = paymentValue - totalNet;
  function zeroPad(num) {
    return num.toString().padStart(13, "0");
  }
  const onSubmitPayment = (param) => {
    if (state.date == "" && userLogin[0].user_type != 2) {
      alert("Please select a date");
    } else {
      if (totalNet > state.payment && state.payment_type == 'Cash') {
        alert("Invalid Amount");
      } else {
        dispatch({
          type: "onChangeOrderReducer",
          data: {
            openLoading: true,
          },
        });
        let customer_name = state.customerName;
        let customer_id = 0
        let order_status = "Paid"
        let new_disc = 0
        let totalbalance = totalNet
        let stat = ''
        if (state.payment_type != 'Cash') {
          order_status = 'Unpaid'
          change = 0
          totalbalance = totalNet
        }
        if (orderType == "Whole Sale") {
          customer_name = customerNameSelect
          customer_id = customerIDSelect
          new_disc = selectedItems.reduce((count, val) => {
            return (count += val.discount);
          }, 0)
        } else {
          new_disc = state.discountAmount
          if (customer_name == "") {
            customer_name = "Walk In";
          }
        }
        if (moment(state.date).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
          stat = 'Cash Remitted'
        }
        let subtotal = parseFloat(totalNet) + parseFloat(parseFloat(new_disc).toFixed(2))
        let data = {
          order: {
            change: change,
            total_amount: totalNet,
            total_balance: totalbalance,
            discount_amount: parseFloat(new_disc).toFixed(2),
            sub_total: subtotal,
            order_type: orderType,
            order_status: order_status,
            terms: state.payment_type,
            branch_id: branch_id,
            user_id: userLogin[0].user_id,
            cash: state.payment,
            cash_register_id: cash_register_id,
            customer_name: customer_name,
            customer_id: customer_id,
            customer_address: customerAddressSelect,
            delivery_date: moment(state.delivery_date).format('YYYY-MM-DD HH:mm'),
            date_added: moment(state.date).format('YYYY-MM-DD HH:mm'),
            disc_record: selectedItems[0].disc_record,
            payment_due: state.payment_due,
            payment_due_date: (moment(state.date, "YYYY-MM-DD").add(state.payment_due, 'days')).format('YYYY-MM-DD'),
            total_supplier_price: selectedItems.reduce((count, val) => {
              return (count +=
                parseFloat(val.supplier_price) * parseInt(val.quantity));
            }, 0),
            status: stat
          },
          itemList: selectedItems,
        };
        sqlData(data, "", "POST", "order/insertOrderItems").then((result) => {
          if (result.res == "Exist") {
            alert("Your transaction is already exist");
            dispatch({
              type: "onChangeOrderReducer",
              data: {
                openLoading: false,
              },
            });
          } else if (result.res == true) {
            let details = result.lastDetails[0]
            if (orderType == "Whole Sale") {
              window.open('https://api.store.gzonetechph.com/exportData/deliveryReceipt/' + details.order_id)
            }
            setState((prev) => ({
              ...prev,
              paymentModal: false,
              discountAmount: 0,
              payment: "",
              discountValue: "",
              receiptNo: zeroPad(result.receipt_number),
              orderToPrint: data,
              lastDetails: result.lastDetails,
              customerName: "",
              date: new Date(),
              payment_due: "",
              payment_due_date: "",
              delivery_date: new Date,
              cash: '',
            }));
            setCustomerIDSelect('')
            setCustomerNameSelect('')
            setCustomerAddressSelect('')
            dispatch({
              type: "onChangeOrderReducer",
              data: {
                selectedItems: [],
                refreshStoreList: !refreshStoreList,
              },
            });
            dispatch({
              type: "onChangeOrderReducer",
              data: {
                openLoading: false,
              },
            });
            setTimeout(() => {
              if (param == "Print Receipt") {
                document.getElementById("printReciept").click();
              }
              element.focus();
            }, 100);
          } else {
            alert("Something went wrong!");
            dispatch({
              type: "onChangeOrderReducer",
              data: {
                openLoading: false,
              },
            });
          }
        });
      }
    }
  };
  const checkout = () => {
    if (selectedItems.length == 0) {
      alert("No item to checkout!");
    } else {
      dispatch({
        type: "onChangeOrderReducer",
        data: {
          openLoading: true,
        },
      });
      let data = {
        page: 0,
        customWhere: false,
        count: false,
        customWhereStr: "",
        where: {
          branch_id: branch_id,
        },
        orWhere: {},
        like: {},
        orLike: {},
        groupBy: [],
        orderBy: [],
        join: {},
        selectData: "*",
      };
      let data2 = {
        page: 0,
        customWhere: false,
        count: false,
        customWhereStr: "",
        where: {
          branch_id: branch_id,
        },
        orWhere: {},
        like: {},
        orLike: {},
        groupBy: [],
        orderBy: [],
        join: {},
        selectData: "*, CONCAT(customers.cust_lname, ', ',customers.cust_fname) as customer_fullname ",
      };
      Promise.all([
        sqlData(data, "", "POST", "branch/viewdata"),
        sqlData(data2, "", "POST", "customers/viewdata"),
      ]).then((result) => {
        console.log(result[1])
        setState((prev) => ({
          ...prev,
          paymentModal: true,
          branchDetais: result[0][0],
          customers: result[1]
        }));
        dispatch({
          type: "onChangeOrderReducer",
          data: {
            openLoading: false,
          },
        });
      });
    }
  };

  const onAfterPrint = () => {
    let data = {
      order_id: state.lastDetails[0].order_id,
    };
    sqlData(data, "", "POST", "order/onAfterPrint").then((result) => {
      element.focus();
    });
  };
  const printPreviousOrder = () => {
    if (window.confirm("Are you sure you want to print the last record?   ")) {
      dispatch({
        type: "onChangeOrderReducer",
        data: {
          openLoading: true,
        },
      });
      let data = {
        branch_id: branch_id,
        user_id: userLogin[0].user_id,
      };
      let data2 = {
        page: 0,
        customWhere: false,
        count: false,
        customWhereStr: "",
        where: {
          branch_id: branch_id,
        },
        orWhere: {},
        like: {},
        orLike: {},
        groupBy: [],
        orderBy: [],
        join: {},
        selectData: "*",
      };
      sqlData(data2, "", "POST", "branch/viewdata").then((result2) => {
        sqlData(data, "", "POST", "order/getLastOrder").then((result) => {
          if (typeof result.res.order !== "undefined") {
            setState((prev) => ({
              ...prev,
              orderToPrint: result.res,
              lastDetails: [result.res.order],
              receiptNo: zeroPad(result.res.order.receipt_number),
              branchDetais: result2[0],
            }));
            setTimeout(() => {
              document.getElementById("printReciept").click();
              dispatch({
                type: "onChangeOrderReducer",
                data: {
                  openLoading: false,
                },
              });
            }, 100);
          } else {
            dispatch({
              type: "onChangeOrderReducer",
              data: {
                openLoading: false,
              },
            });
            alert("No previous item to print!");
          }
        });
      });
    }
  };
  const onChangeDiscount = (e, index) => {
    let value = e.target.value;
    setDisc(value);
  };
  const onEditDiscount = (e) => {
    e.preventDefault();
    updateDiscount(itemDiscount);
  };
  const updateDiscount = (itemDiscount) => {
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        selectedItems: selectedItems.map((val, index) =>
          itemDiscount == ""
            ? index == selectedItems.length - 1
              ? {
                ...val,
                discount: val.discount + (((disc / 100) * parseFloat(val.price_wholesale)) * parseInt(val.quantity)),
                disc_record: String(val.disc_record) + (String(val.disc_record) == '' ? ' ' : ', ') + String(disc),
                total_price: parseFloat(val.total_price) - (((disc / 100) * parseFloat(val.price_wholesale)) * parseInt(val.quantity))
              }
              : val
            : val.item_id == itemDiscount
              ? {
                ...val,
                discount: val.discount + (((disc / 100) * parseFloat(val.price_wholesale)) * parseInt(val.quantity)),
                disc_record: String(val.disc_record) + (String(val.disc_record) == '' ? ' ' : ', ') + String(disc),
                total_price: parseFloat(val.total_price) - (((disc / 100) * parseFloat(val.price_wholesale)) * parseInt(val.quantity))
              }
              : val
        ),
      },
    });
    setTimeout(() => {
      element.focus();
    }, 100);
    alert('Discount added successfully')
    setDiscModal(false)
  };
  const selectCustomerCheckout = (e, value) => {
    if (value !== null) {
      setCustomerIDSelect(value.customer_id);
      setCustomerNameSelect(value.customer_fullname);
      setCustomerAddressSelect(value.cust_address);
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <Card style={{ height: height * 0.8 }} variant="outlined">
        <CardContent>
          <Typography variant="h5">Item List</Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.root} variant={"outlined"}>
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedItems.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (

                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {column.format && value != null
                                    ? column.format(value)
                                    : value}
                                </TableCell>

                              );
                            })}
                            <TableCell style={{ whiteSpace: "nowrap" }}>
                              <DeleteForeverIcon
                                onClick={() => removeItem(index)}
                                style={{
                                  color: "#757575",
                                  marginRight: 10,
                                  cursor: "pointer",
                                }}
                              />
                              <EditIcon
                                onClick={() => {
                                  setEditModal(true);
                                  setQuantity(row.quantity);
                                  setItemEdit(row.item_id);
                                  setState((prev) => ({
                                    ...prev,
                                    discountAmount: 0,
                                    discountValue: "",
                                  }));
                                }}
                                style={{ color: "#757575", cursor: "pointer" }}
                              />
                              {orderType == "Whole Sale" &&
                                <MoneyOffIcon
                                  onClick={() => {
                                    setDiscModal(true);
                                    setItemDiscount(row.item_id);
                                    setDisc(row.adding_disc);
                                  }}
                                  style={{ color: "#757575", cursor: "pointer" }}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div className={classes.containerAbsolute}>
        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 60,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Grid container spacing={1}>
            <Grid></Grid>
          </Grid>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Subtotal :{" "}
            {orderType == "Whole Sale"
              ? formatNumber(
                selectedItems.reduce((count, val) => {
                  return (count += val.discount);
                }, 0) + selectedItems.reduce((count, val) => {
                  return (count += val.total_price);
                }, 0)
              )
              : formatNumber(selectedItems.reduce((count, val) => {
                return (count += val.total_price)
              }, 0))}
          </Typography>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Discount :{" "}
            {orderType == "Whole Sale"
              ? formatNumber(
                selectedItems.reduce((count, val) => {
                  return (count += val.discount);
                }, 0)
              )
              : formatNumber(state.discountAmount)}
          </Typography>
          <div style={{ background: "#000", paddingLeft: 5, paddingRight: 5 }}>
            <Typography style={{ fontWeight: "bold", color: "#ffff" }}>
              Total Amount : {formatNumber(totalNet)}
            </Typography>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        >
          <ButtonGroup
            variant="contained"
            style={{ marginBottom: 10, marginRight: 10 }}
          >
            <Button
              style={{ background: "#9b59b6", color: "#fff" }}
              onClick={() => {
                printPreviousOrder();
              }}
              startIcon={<Icon>print</Icon>}
            >
              Print Last Record
            </Button>
          </ButtonGroup>
          {orderType === 'Retail' &&
            <ButtonGroup
              variant="contained"
              style={{ marginBottom: 10, marginRight: 10 }}
            >
              <Button
                style={{ background: "#3498db", color: "#fff" }}
                onClick={() => {
                  if (selectedItems.length == 0) {
                    alert("Enter first an item!");
                  } else {
                    onOpenCloseDiscountModal(true);
                  }
                }}
                startIcon={<EmojiSymbolsIcon />}
              >
                Discount
              </Button>
            </ButtonGroup>
          }
          <ButtonGroup
            color="primary"
            variant="contained"
            style={{ marginBottom: 10 }}
          >
            <Button onClick={() => checkout()} startIcon={<ShoppingCartIcon />}>
              Checkout
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Dialog
        open={editModal}
        onClose={() => setEditModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Edit Quantity</DialogTitle>
        <DialogContent>
          <form onSubmit={onEditQuantity}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  id="quantity"
                  autoFocus
                  value={quantity}
                  onChange={onChangeText}
                  name="quantity"
                  type="number"
                  style={{ width: "100%" }}
                  label="Quantity"
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                >
                  <Button type="submit">Press Enter</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={state.discountModal}
        onClose={() => onOpenCloseDiscountModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <div style={{ position: "relative" }}>
          <DialogTitle>Add Discount</DialogTitle>
          <IconButton
            onClick={() => onOpenCloseDiscountModal(false)}
            style={{ position: "absolute", right: 10, top: 5 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form onSubmit={addDiscount}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <RadioGroup
                  row
                  value={state.discountType}
                  onChange={handleChangeDiscount}
                >
                  <FormControlLabel
                    value="Amount"
                    control={<Radio />} cus
                    label="Amount"
                  />
                  <FormControlLabel
                    value="Percent"
                    control={<Radio />}
                    label="Percent"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="quantity"
                  autoFocus
                  value={state.discountValue}
                  onChange={onChangeTextValue}
                  name="discountValue"
                  style={{ width: "100%" }}
                  label={state.discountType}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                >
                  <Button type="submit">Press Enter</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={state.paymentModal}
        onClose={() => setState((prev) => ({ ...prev, paymentModal: false }))}
        fullWidth
        maxWidth="xs"
      >
        <div style={{ position: "relative" }}>
          <DialogTitle>Register Payment</DialogTitle>
          <IconButton
            onClick={() =>
              setState((prev) => ({ ...prev, paymentModal: false }))
            }
            style={{ position: "absolute", right: 10, top: 5 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {userLogin[0].user_type != 2 && (
                <TextField
                  required
                  id="required"
                  autoFocus
                  value={moment(state.date).format('YYYY-MM-DD')}
                  onChange={onChangeTextValue}
                  name="date"
                  type="date"
                  style={{ width: "100%", marginBottom: 5 }}
                  label={" Order Date"}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                select
                id="payment"
                autoFocus
                value={state?.payment_type}
                onChange={onChangeTextValue}
                name="payment_type"
                style={{ width: "100%" }}
                label={"Payment Type"}
                InputLabelProps={{ shrink: true }}
              >
                {terms.map((val, index) => {
                  return (
                    <MenuItem value={val.term_name}>{val.term_name}</MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            {orderType == "Retail" ? (
              <Grid item xs={12} md={12}>
                <TextField
                  id="payment"
                  autoFocus
                  value={state.customerName}
                  onChange={onChangeTextValue}
                  name="customerName"
                  style={{ width: "100%" }}
                  placeholder="ex. Juan Dela Cruz"
                  label={"Customer Name"}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ) :
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="combo-box-demo"
                  options={state.customers}
                  onChange={selectCustomerCheckout}
                  getOptionLabel={(option) => option.customer_fullname ? option.customer_fullname : option.cust_fname}
                  renderInput={(params) => <TextField type="text"
                    required
                    size='small'
                    fullWidth  {...params} label="Customer" />}
                />
              </Grid>

            }


            {state?.payment_type == "Charge Account" ? (
              <Grid item xs={12} md={12}>
                <TextField
                  id="payment_due"
                  autoFocus
                  required
                  value={state.payment_due}
                  onChange={onChangeTextValue}
                  name="payment_due"
                  type="number"
                  style={{ width: "100%" }}
                  label={"Enter Days"}
                />
              </Grid>
            ) : undefined}
            {state?.payment_type == "COD" ? (
              <Grid item xs={12} md={12}>
                <TextField
                  id="required"
                  autoFocus
                  value={state.delivery_date}
                  onChange={onChangeTextValue}
                  name="delivery_date"
                  type="date"
                  style={{ width: "100%", marginBottom: 5 }}
                  label={" Delivery Date"}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ) : undefined}
            {state?.payment_type == "Cash" ? (
              <Grid item xs={12} md={12}>
                <TextField
                  id="payment"
                  autoFocus
                  value={state.payment}
                  onChange={onChangeTextValue}
                  name="payment"
                  type="number"
                  style={{ width: "100%" }}
                  label={"Enter Amount"}
                />
              </Grid>
            ) : undefined}
            <Grid container justify="flex-end" item xs={12} md={12}>
              <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                Cash : {formatNumber(state.payment)}
              </Typography>
            </Grid>
            <Grid container justify="flex-end" item xs={12} md={12}>
              <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                Change : {change >= 0 ? formatNumber(change) : 0}
              </Typography>
            </Grid>
            <Grid container justify="flex-end" item xs={12} md={12}>
              <ButtonGroup
                color="primary"
                variant="contained"
                style={{ marginBottom: 10 }}
              >
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to checkout the items?"
                      )
                    ) {
                      onSubmitPayment("Print Receipt");
                    }
                  }}
                  style={{ background: "#3498db", color: "#fff" }}
                >
                  Print Receipt
                </Button>
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to checkout the items without receipt?"
                      )
                    ) {
                      onSubmitPayment("");
                    }
                  }}
                >
                  Proceed
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={discModal}
        onClose={() => setDiscModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <div style={{ position: "relative" }}>
          <DialogTitle>Manage Discount</DialogTitle>
          <IconButton
            onClick={() =>
              setDiscModal(false)}
            style={{ position: "absolute", right: 10, top: 5 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form onSubmit={onEditDiscount}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  id="quantity"
                  autoFocus
                  value={disc}
                  onChange={onChangeDiscount}
                  type="number"
                  style={{ width: "100%" }}
                  label='Discount Percentage'
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button color="primary"
                  variant="contained" type="submit">Apply</Button>
              </Grid>
            </Grid>
          </form>

        </DialogContent>
      </Dialog>
      <ReactToPrint
        onBeforeGetContent={() => { }}
        onAfterPrint={() => {
          onAfterPrint();
        }}
        on
        trigger={() => (
          <Button
            id="printReciept"
            style={{
              background: "#3498db",
              color: "#fff",
              display: "none",
            }}
          >
            Print Reciept
          </Button>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          branchDetais={state.branchDetais}
          orderToPrint={state.orderToPrint}
          receiptNo={state.receiptNo}
          lastDetails={state.lastDetails}
        />
      </div>
    </div>
  );
});
export default ItemList;
