import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ReorderIcon from "@material-ui/icons/Reorder";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData, sqlDataDelete } from "../api";
import Logs from "./Logs/logs";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const dispatch = useDispatch();
  const blmcPage = useSelector((state) => state.pageReducer);
  const loginBranch = useSelector(
    (state) => state.loginReducer.userLogin[0].branch_id
  );
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    dispatch({
      type: "setPageTitle",
      requestResult: "Inventory",
    });

    handleGetCategories();
    handleGetItems();
    handleGetBrands();
    handleGetUnits();
    fetchSuppliers();
  }, [loginBranch]);

  const [addItemDialog, setItemDialog] = useState(false);
  const [addSupplierDialog, setSupplierDialog] = useState(false);
  const [addCategoryDialog, setAddCatDialog] = useState(false);
  const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [addUnitDialog, setAddUnitDialog] = useState(false);
  const [updateItemDialog, setUpdateItemDialog] = useState(false);
  const [addStockDialog, setAddStockDialog] = useState(false);
  const [releaseStockDialog, setReleaseStockDialog] = useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [viewLogs, setviewLogs] = useState(false);
  const [dialogTransfer, setDialogTransfer] = useState(false);
  const [selectSupplier, setSelectSupplier] = React.useState("");
  const [priceUpdateDialog, setPriceUpdateDialog] = useState(false);
  const [updatePricesDialog, setUpdatePricesDialog] = useState(false);
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [filter, setFilter] = React.useState("");

  //Data Table
  const [search, setSearch] = useState("");
  const searchItem = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //!Data Table

  //Notifs
  const [successUpdated, setSuccessUpdated] = useState(false);
  const [successReleased, setSuccessReleased] = useState(false);
  const [successAddStock, setSuccessAddStock] = useState(false);
  //!Notifs

  const [state, setState] = useState({
    init: 0,
    categoryId: 0,
    categoryName: "",
    categories: [],
    brandName: "",
    brands: [],
    currentItemId: 0,
    currentItem: {},
    categorySelect: "",
    model: "",
    color: "",
    size: "",
    unit: "",
    unit_prefix: "",
    new_supplier_price: "",
    new_unit_price: "",
    new_price_wholesale: "",
    price_effectiveness: moment(new Date()).format("YYYY-MM-DD"),
    units: [],
    inventories: [],
    inventoriesMain: [],
  });
  let inventorySearch = state.inventories.filter((files) => {
    return (
      (files.item_name !== undefined &&
        files.item_name !== "" &&
        files.item_name !== null &&
        files.item_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !==
          -1) ||
      (files.barcode !== undefined &&
        files.barcode !== "" &&
        files.barcode !== null &&
        files.barcode.toLowerCase().indexOf(search.toLocaleLowerCase()) !==
          -1) ||
      (files.category_name !== undefined &&
        files.category_name !== "" &&
        files.category_name !== null &&
        files.category_name
          .toLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1)
    );
  });
  const [categorySelect, setCategorySelect] = useState("");
  const [categoryNameSelect, setCategoryNameSelect] = useState("");
  const [branchSelect, setBranchSelect] = useState(1);
  const [supplierArray, setSupplierArray] = React.useState([]);
  const handleDeleteCategory = (deleteId) => {
    sqlDataDelete("", "DELETE", "category/" + deleteId).then((result) => {
      handleGetCategories();
    });
  };
  const handleDateChange = (e) => {
    setState((prev) => ({
      ...prev,
      price_effectiveness: e.target.value,
    }));
  };
  const onChangeText = (event) => {
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeTextUpdate = (event) => {
    setUpdateData({
      ...updateData,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeTextRelease = (event) => {
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const selectCategory = (e, value) => {
    if (value !== null) {
      setCategorySelect(value.category_id);
      setCategoryNameSelect(value.category_name);
    }
  };

  const selectBranch = (e) => {
    setBranchSelect(e.target.value);
  };

  const addItemSubmit = (e) => {
    e.preventDefault();
    let result = state.inventories.filter(
      (find) => find.item_name.toUpperCase() === state.itemName.toUpperCase()
    );
    if (result.length === 0) {
      setOpenLoading(true);
      const data = {
        item_name: state.itemName,
        item_description: state.itemName,
        category_id: categorySelect,
        category_name: categoryNameSelect,
        brand: state.brand,
        model: state.model,
        color: state.color,
        size: state.size,
        unit: state.unit,
        supplier_id: selectSupplier,
        quantity: state.quantity,
        supplier_price: state.supplierPrice,
        unit_price: state.retailPrice,
        price_wholesale: state.wholesalePrice,
        branch_id: loginBranch,
        new_supplier_price: state.supplierPrice,
        new_unit_price: state.retailPrice,
        new_price_wholesale: state.wholesalePrice,
      };
      sqlData(data, "", "POST", "inventory").then((result) => {
        const bar_data = {
          item_id: result,
          branch_id: loginBranch,
        };
        const log_data = {
          item_id: result,
          movement: "New Item",
          new_quantity: state.quantity,
          update_value: state.quantity,
          branch_id: loginBranch,
        };
        const store_data = {
          item_id: result,
          branch_id: loginBranch,
          store_quantity: state.quantity,
          unit: state.unit,
        };
        Promise.all([
          sqlData(store_data, "", "POST", "storeitem"),
          sqlData(log_data, "", "POST", "inventorylogs"),
          sqlData(bar_data, "", "POST", "inventory/updateBarcode"),
        ]).then((res) => {
          const store_log__data = {
            store_item_id: res[0],
            s_item_id: result,
            s_movement: "Add",
            s_past_quantity: 0,
            s_new_quantity: state.quantity,
            s_branch_id: loginBranch,
          };
          sqlData(store_log__data, "", "POST", "storeitemlogs").then(
            (result1) => {
              setOpenLoading(false);
              setItemDialog(false);
              setSelectSupplier("");
              setCategorySelect("");
              handleGetItems();
              alert("Item added successfully!");
            }
          );
        });
      });
    } else {
      alert("Item name already exist!");
      setOpenLoading(false);
    }
  };
  async function fetchSuppliers() {
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        sup_delete_id: "0",
        branch_id: loginBranch,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData: "*",
    };
    await sqlData(data, "", "POST", "suppliers/viewdata")
      .then((result) => {
        setSupplierArray(result);
        setOpenLoading(false);
      })
      .catch((error) => {
        setOpenLoading(false);
      });
  }
  const addSupplierSubmit = (e) => {
    e.preventDefault();
    let result = supplierArray.filter(
      (find) =>
        find.supplier_name.toUpperCase() == state.supplierName.toUpperCase()
    );
    if (result.length === 0) {
      setOpenLoading(true);
      const data = {
        supplier_name: state.supplierName,
        supplier_address: state.address,
        supplier_contact: state.contactNumber,
        supplier_email: state.emailAddress,
        branch_id: loginBranch,
      };
      sqlData(data, "", "POST", "suppliers").then((result) => {
        fetchSuppliers();
        setOpenLoading(false);
        setSupplierDialog(false);
      });
    } else {
      alert("Brand already exist!");
      setOpenLoading(false);
    }
  };
  const [updateData, setUpdateData] = useState({});
  const [updateDataRetain, setUpdateDataRetain] = useState({});
  const handleUpdateItem = (item) => {
    setUpdateData(item);
    setUpdateDataRetain(item);
    setState((prev) => ({
      ...prev,
      currentItemId: item.item_id,
      currentItem: item,
    }));
    setUpdateItemDialog(true);
  };

  const handleUpdateItemData = (e) => {
    e.preventDefault();
    setOpenLoading(true);
    let data = {
      item_name: updateData.item_name,
      item_description: updateData.item_name,
      category_id: updateData.category_id,
      category_name: updateData.category_name,
      brand_name: updateData.brand_name,
      brand: updateData.brand,
      model: updateData.model,
      color: updateData.color,
      size: updateData.size,
      unit: updateData.unit,
      supplier_id: updateData.supplier_id,
      quantity: updateData.quantity,
      supplier_price: updateData.supplier_price,
      unit_price: updateData.unit_price,
      price_wholesale: updateData.price_wholesale,
      item_id: updateData.item_id,
    };
    sqlData(data, "", "PUT", "inventory/" + updateData.item_id).then(
      (result) => {
        const log_data = {
          item_id: state.currentItemId,
          movement: "update",
          past_price: updateDataRetain.unit_price,
          new_price: updateData.unit_price,
          past_supp_price: updateDataRetain.supplier_price,
          new_supp_price: updateData.supplier_price,
          barcode: updateData.barcode,
          branch_id: loginBranch,
        };
        // let store_update = {
        //   item_id: state.currentItemId,
        //   store_quantity: updateData.quantity,
        //   branch_id: loginBranch,
        // };
        Promise.all([
          // sqlData(store_update, "", "POST", "storeitem/" + state.currentItemId),
          sqlData(log_data, "", "POST", "inventorylogs"),
        ])
          .then((res) => {
            setOpenLoading(false);
            setUpdateItemDialog(false);
            handleGetItems();
            setSuccessUpdated(true);
          })
          .catch((error) => {
            setOpenLoading(false);
            alert("Item update failed!");
          });
      }
    );
  };

  const handleGetItems = () => {
    const data = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        delete_id: "0",
        "inventory.branch_id": loginBranch,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: ["item_name ASC"],
      join: {
        categories: ["categories.category_id = inventory.category_id", "left"],
        suppliers: ["suppliers.supplier_id = inventory.supplier_id", "left"],
        store_items: ["store_items.item_id = inventory.item_id", "left"],
      },
      selectData: "*, brand as brand_name",
    });
    sqlData(data, "", "POST", "inventory/viewdata").then((result) => {
      dispatch({
        type: "setInitialData",
        requestResult: result,
      });
      setState((prev) => ({
        ...prev,
        inventoriesMain: result,
        inventories: result,
      }));
    });
  };

  const handleOpenAddStock = (item) => {
    setState((prev) => ({
      ...prev,
      currentItemId: item.item_id,
      currentItem: item,
    }));
    setAddStockDialog(true);
  };
  const handlePriceUpdateDialog = (item) => {
    setState((prev) => ({
      ...prev,
      currentItemId: item.item_id,
      currentItem: item,
    }));
    setPriceUpdateDialog(true);
  };
  const addStock = (e) => {
    e.preventDefault();
    setOpenLoading(true);
    const data = {
      item_id: state.currentItemId,
      quantity:
        parseFloat(state.updateStock) + parseFloat(state.currentItem.quantity),
    };

    sqlData(data, "", "PUT", "inventory/" + state.currentItemId).then(
      (result) => {
        const log_data = {
          item_id: state.currentItemId,
          movement: "Add/Reduce",
          past_quantity: parseFloat(state.currentItem.quantity),
          new_quantity:
            parseFloat(state.updateStock) +
            parseFloat(state.currentItem.quantity),
          update_value: parseInt(state.updateStock),
        };

        sqlData(log_data, "", "POST", "inventorylogs").then((result1) => {
          setOpenLoading(false);
          setAddStockDialog(false);
          handleGetItems();
          setSuccessAddStock(true);
        });
      }
    );
  };

  const handleOpenReleaseStock = (item) => {
    setState((prev) => ({
      ...prev,
      currentItemId: item.item_id,
      currentItem: item,
    }));
    setReleaseStockDialog(true);
  };
  const handleOpenDeleteItem = (item) => {
    setOpenLoading(true);
    setState((prev) => ({
      ...prev,
      currentItemId: item.item_id,
      currentItem: item,
    }));
    setOpenLoading(false);
    setDeleteItemDialog(true);
  };

  const handleDeleteItem = () => {
    setOpenLoading(true);
    let data = {
      item_id: state.currentItemId,
      delete_id: 1,
    };
    sqlData(data, "", "PUT", "inventory/" + state.currentItemId).then(
      (result) => {
        const log_data = {
          item_id: state.currentItemId,
          movement: "Deleted Item",
          new_quantity: state.currentItem.quantity,
          update_value: state.currentItem.quantity,
          branch_id: loginBranch,
        };
        sqlData(log_data, "", "POST", "inventorylogs").then((result) => {
          setState((prev) => ({
            ...prev,
            currentItemId: 0,
            currentItem: {},
          }));
          handleGetItems();
          setOpenLoading(false);
          setDeleteItemDialog(false);
        });
      }
    );
  };

  const OnSubmitAllTransfer = (e) => {
    e.preventDefault();
    state.inventories.map((val) => {
      let data = {
        item_id: val.item_id,
        quantity: val.quantity,
      };
      sqlData(data, "", "PUT", "inventory/" + val.item_id).then((result) => {
        const transfer_data = {
          item_id: val.item_id,
          branch_id: branchSelect,
          transfer_quantity: val.quantity,
          transfer_status: "Pending",
        };
        sqlData(transfer_data, "", "POST", "transfers").then((res) => {
          const log_data = {
            item_id: val.item_id,
            movement: "Transfer",
            past_quantity: parseFloat(val.quantity),
            new_quantity: 0,
            update_value: parseInt(val.quantity),
            branch_id: branchSelect,
          };
          sqlData(log_data, "", "POST", "inventorylogs").then((result1) => {
            setOpenLoading(false);
            setReleaseStockDialog(false);
            handleGetItems();
            setSuccessReleased(true);
          });
        });
      });
    });
  };
  const releaseStock = (e) => {
    e.preventDefault();
    if (state.currentItem.quantity >= state.updateStock) {
      let data = {
        item_id: state.currentItemId,
        quantity:
          parseFloat(state.currentItem.quantity) -
          parseFloat(state.updateStock),
      };
      setOpenLoading(true);
      sqlData(data, "", "PUT", "inventory/" + state.currentItemId).then(
        (result) => {
          const transfer_data = {
            item_id: state.currentItemId,
            branch_id: branchSelect,
            transfer_quantity: state.updateStock,
            transfer_status: "Pending",
          };
          sqlData(transfer_data, "", "POST", "transfers").then((res) => {
            const log_data = {
              item_id: state.currentItemId,
              movement: "Transfer",
              past_quantity: parseFloat(state.currentItem.quantity),
              new_quantity:
                parseFloat(state.currentItem.quantity) -
                parseFloat(state.updateStock),
              update_value: parseInt(state.updateStock),
              branch_id: branchSelect,
            };

            sqlData(log_data, "", "POST", "inventorylogs").then((result1) => {
              setOpenLoading(false);
              setReleaseStockDialog(false);
              handleGetItems();
              setSuccessReleased(true);
            });
          });
        }
      );
    } else {
      alert("Invalid Quantity");
    }
  };

  const addCategorySubmit = (e) => {
    e.preventDefault();
    let result = state.categories.filter(
      (find) =>
        find.category_name.toUpperCase() == state.categoryName.toUpperCase()
    );
    if (result.length === 0) {
      setOpenLoading(true);
      const data = {
        category_name: state.categoryName,
        branch_id: loginBranch,
      };
      sqlData(data, "", "POST", "category").then((result) => {
        setOpenLoading(false);
        handleGetCategories();
        setState((prev) => ({
          ...prev,
          categoryName: "",
        }));
        setAddCatDialog(false);
        alert("Category added successfully");
      });
    } else {
      alert("Category already exist!");
      setOpenLoading(false);
    }
  };

  const selectUpdateCategory = (e, value) => {
    if (value !== null) {
      setUpdateData({
        ...updateData,
        category_id: value.category_id,
        category_name: value.category_name,
        category_desc: value.category_desc,
        category_color: "",
      });
    }
  };
  const selectUpdateBrand = (e, value) => {
    if (value !== null) {
      setUpdateData({
        ...updateData,
        brand: value.brand_name,
        brand_name: value.brand_name,
      });
    }
  };
  const selectUpdateSupplier = (e, value) => {
    if (value !== null) {
      setUpdateData({
        ...updateData,
        supplier_id: value.supplier_id,
        supplier_name: value.supplier_name,
      });
    }
  };
  const handleGetCategories = () => {
    const data = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: loginBranch,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: ["category_name ASC"],
      join: [],
      selectData: "*",
    });

    sqlData(data, "", "POST", "category/viewdata").then((result) => {
      setState((prev) => ({
        ...prev,
        categoryName: "",
        categories: result,
      }));
    });
  };
  const onChangeSupplier = (event, value) => {
    if (value !== null) {
      setSelectSupplier(value.supplier_id);
    }
  };
  const onChangeBrand = (event, value) => {
    if (value !== null) {
      setState((prev) => ({
        ...prev,
        brand: value.brand_name,
      }));
    }
  };
  const onChangeUnit = (event, value) => {
    if (value !== null) {
      setState((prev) => ({
        ...prev,
        unit: value.unit_prefix,
      }));
    }
  };
  const handleGetBrands = () => {
    const data = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: loginBranch,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: ["brand_name ASC"],
      join: [],
      selectData: "*",
    });

    sqlData(data, "", "POST", "brand/viewdata").then((result) => {
      setState((prev) => ({
        ...prev,
        brandName: "",
        brands: result,
      }));
    });
  };
  const handleGetUnits = () => {
    const data = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        branch_id: loginBranch,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: ["unit_name ASC"],
      join: [],
      selectData: "*",
    });

    sqlData(data, "", "POST", "unit/viewdata").then((result) => {
      setState((prev) => ({
        ...prev,
        unit: "",
        units: result,
      }));
    });
  };
  const addBrandSubmit = (e) => {
    e.preventDefault();
    let result = state.brands.filter(
      (find) => find.brand_name == state.brandName.toUpperCase()
    );
    if (result.length === 0) {
      setOpenLoading(true);
      const data = {
        brand_name: state.brandName,
        branch_id: loginBranch,
      };
      sqlData(data, "", "POST", "brand").then((result) => {
        setOpenLoading(false);
        handleGetBrands();
        alert("Brand added successfully");
        setState((prev) => ({
          ...prev,
          brandName: "",
        }));
        setAddBrandDialog(false);
      });
    } else {
      alert("Brand already exist!");
      setOpenLoading(false);
    }
  };
  const handleDeleteBrand = (deleteId) => {
    sqlDataDelete("", "DELETE", "brand/" + deleteId).then((result) => {
      handleGetBrands();
    });
  };
  const priceUpdateSubmit = (e) => {
    e.preventDefault();
    setOpenLoading(true);
    let data = {
      new_supplier_price: state.new_supplier_price,
      new_unit_price: state.new_unit_price,
      new_price_wholesale: state.new_price_wholesale,
      price_effectiveness: state.price_effectiveness,
      item_id: state.currentItemId,
    };
    sqlData(data, "", "PUT", "inventory/" + state.currentItemId).then(
      (result) => {
        handleGetItems();
        setOpenLoading(false);
        alert("Price pending successfully");
        setPriceUpdateDialog(false);
      }
    );
  };
  const pressUpdatePrice = () => {
    setOpenLoading(true);
    let arr = state.inventories;
    let info = [];
    arr.map((val) => {
      if (
        moment(val.price_effectiveness).format("YYYY-MM-DD") ===
        moment(new Date()).format("YYYY-MM-DD")
      ) {
        let data = {
          supplier_price: val.new_supplier_price,
          unit_price: val.new_unit_price,
          price_wholesale: val.new_price_wholesale,
          item_id: val.item_id,
          last_update: moment(new Date()).format("YYYY-MM-DD"),
        };
        info.push(data);
      }
    });
    sqlData(info, "", "POST", "inventory/updatePrice").then((result) => {
      alert("Please reload other devices to fetch updated data.");
      handleGetItems();
      setOpenLoading(false);
      setUpdatePricesDialog(false);
    });
  };
  const addUnitSubmit = (e) => {
    e.preventDefault();
    let result = state.units.filter(
      (find) => find.unit_name.toUpperCase() == state.unit_name.toUpperCase()
    );
    if (result.length === 0) {
      setOpenLoading(true);
      const data = {
        unit_name: state.unit_name,
        unit_prefix: state.unit_prefix,
        branch_id: loginBranch,
      };
      sqlData(data, "", "POST", "unit").then((result) => {
        setOpenLoading(false);
        handleGetUnits();
        setState((prev) => ({
          ...prev,
          unit_name: "",
          unit_prefix: "",
        }));
        setAddUnitDialog(false);
        alert("Unit added successfully");
      });
    } else {
      alert("Unit already exist!");
      setOpenLoading(false);
    }
  };
  const handleDeleteUnit = (deleteId) => {
    sqlDataDelete("", "DELETE", "unit/" + deleteId).then((result) => {
      handleGetUnits();
    });
  };
  const selectUpdateUnit = (e, value) => {
    if (value !== null) {
      setUpdateData({
        ...updateData,
        unit: value.unit_prefix,
      });
    }
  };
  const handleChange = (event) => {
    let value = event.target.value;
    let filter = [];

    if (value === "ALL") {
      filter = state.inventoriesMain;
    } else {
      filter = state.inventoriesMain.filter(
        (files) => files.category_name == value
      );
    }
    setFilter(value);
    setState((prev) => ({
      ...prev,
      inventories: filter,
    }));
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        open={successUpdated}
        autoHideDuration={5000}
        onClose={() => setSuccessUpdated(false)}
        message="Successfully Updated"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSuccessUpdated(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "left",
          horizontal: "center",
        }}
        open={successAddStock}
        autoHideDuration={5000}
        onClose={() => setSuccessAddStock(false)}
        message="Stock Added Successfully!"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSuccessAddStock(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        style={{ color: "#27ae60" }}
        open={successReleased}
        autoHideDuration={5000}
        onClose={() => setSuccessReleased(false)}
        message="Successfully Released from inventory"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={setSuccessReleased}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 9999 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <Navigation/> */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2">Total Items</Typography>
              <Typography variant="h6">
                {state.inventories.length} Items
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonGroup
            color="primary"
            variant="contained"
            style={{ marginBottom: 10 }}
          >
            <Button
              onClick={() => setItemDialog(true)}
              startIcon={<PostAddIcon />}
            >
              Add Item
            </Button>
            <Button
              onClick={() => setUpdatePricesDialog(true)}
              startIcon={<AttachMoneyIcon />}
            >
              Update Price
            </Button>
            {/* <Button
                onClick={() => {
                  sqlData(blmcPage.mainData, "", "POST", "inventory/updatebarcodeall").then((result) => {
                  })
                }
                }
                startIcon={<PostAddIcon />}
              >
                barcode
              </Button> */}
            <Button
              color="primary"
              onClick={() => setviewLogs(true)}
              startIcon={<ReorderIcon />}
            >
              Logs
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid
          item
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <TextField
            size="small"
            id="outlined-basic"
            label="Search Item"
            onChange={(e) => {
              searchItem(e);
            }}
            variant="outlined"
          />
          <div>
            <FormControl
              style={{ width: "300px" }}
              size="small"
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Filter by Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filter}
                onChange={handleChange}
                label="Filter by Category"
              >
                <MenuItem value="ALL">
                  <em>ALL</em>
                </MenuItem>
                {state.categories.map((val) => {
                  return (
                    <MenuItem value={val.category_name}>
                      {val.category_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item md={12}>
          <TableContainer
            style={{ maxHeight: 400, maxWidth: "92vw" }}
            component={Paper}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              style={{ whiteSpace: "nowrap" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell> Supplier Cost</TableCell>
                  <TableCell> Retail Cost</TableCell>
                  <TableCell>Wholesale Cost</TableCell>
                  {/* <TableCell>Retail Price</TableCell> */}
                  <TableCell>In Stock</TableCell>
                  <TableCell>Inventory Value</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventorySearch
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    return (
                      <TableRow hover key={key}>
                        <TableCell>
                          <b>{row.item_name}</b>
                        </TableCell>
                        <TableCell>{row.category_name}</TableCell>
                        <TableCell>{row.supplier_price}</TableCell>
                        <TableCell>{row.unit_price}</TableCell>
                        <TableCell>{row.price_wholesale}</TableCell>
                        {/* <TableCell>
                          <b>
                            PHP {(row.quantity * row.unit_price).toLocaleString()}
                          </b>
                        </TableCell> */}
                        <TableCell>{row.store_quantity}</TableCell>
                        <TableCell>
                          <b>
                            PHP{" "}
                            {(
                              row.store_quantity * row.supplier_price
                            ).toLocaleString()}
                          </b>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Update Entry">
                            <IconButton onClick={() => handleUpdateItem(row)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Add Stock">
                            <IconButton
                              onClick={() => handleOpenAddStock(row)}
                            >
                              <KeyboardArrowUpIcon fontSize="small" />
                            </IconButton>
                          </Tooltip> */}
                          <Tooltip title="Update Price">
                            <IconButton
                              onClick={() => handlePriceUpdateDialog(row)}
                            >
                              <AttachMoneyIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Item">
                            <IconButton
                              onClick={() => handleOpenDeleteItem(row)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Transfer Item">
                            <IconButton
                              onClick={() => handleOpenReleaseStock(row)}
                            >
                              <InputIcon fontSize="small" />
                            </IconButton>
                          </Tooltip> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 30, 50]}
            component="div"
            count={state.inventories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      <Dialog
        open={addItemDialog}
        onClose={() => setItemDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setItemDialog(false);
                }}
              >
                <ClearIcon />
              </IconButton>
              <Typography variant="h6">Add New Item</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <form onSubmit={addItemSubmit}>
          <DialogContent>
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={4}>
                    <Typography>ITEM DETAILS</Typography>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={8}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    <ButtonGroup color="primary" variant="contained">
                      <Button
                        color="primary"
                        onClick={() => setAddCatDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Category
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setAddBrandDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Brand
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setSupplierDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Supplier
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setAddUnitDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Unit
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      onChange={onChangeText}
                      autoFocus
                      id="name"
                      label="Item Name"
                      name="itemName"
                      type="text"
                      size="small"
                      required
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item sm={12} md={8}>
                      <TextField
                        onChange={onChangeText}
                        autoFocus
                        id="item_description"
                        label="Item Description"
                        multiline
                        name="item_description"
                        size='small'
                        type="text"
                        required
                        fullWidth
                      />

                    </Grid> */}
                  <Grid item sm={12} md={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={state.categories}
                      onChange={selectCategory}
                      getOptionLabel={(option) => option.category_name}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          required
                          size="small"
                          fullWidth
                          {...params}
                          label="Category"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={state.brands}
                      onChange={onChangeBrand}
                      getOptionLabel={(option) => option.brand_name}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          required
                          size="small"
                          fullWidth
                          {...params}
                          label="Brand"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <TextField
                      onChange={onChangeText}
                      autoFocus
                      id="name"
                      label="Model Name"
                      name="model"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <TextField
                      onChange={onChangeText}
                      autoFocus
                      id="color"
                      label="Color"
                      name="color"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <TextField
                      onChange={onChangeText}
                      autoFocus
                      id="size"
                      label="Size"
                      name="size"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={state.units}
                      onChange={onChangeUnit}
                      getOptionLabel={(option) =>
                        option.unit_name ? option.unit_name : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          size="small"
                          fullWidth
                          {...params}
                          label="Unit"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <Typography>RETAIL & WHOLESALE VALUE DETAILS</Typography>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={supplierArray}
                      onChange={onChangeSupplier}
                      getOptionLabel={(option) => option.supplier_name}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          required
                          size="small"
                          fullWidth
                          {...params}
                          label="Supplier"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <TextField
                      onChange={onChangeText}
                      id="quantity"
                      label="Quantity"
                      name="quantity"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <TextField
                      onChange={onChangeText}
                      id="supplierPrice"
                      name="supplierPrice"
                      label="Supplier Net per Unit"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <TextField
                      onChange={onChangeText}
                      id="retailPrice"
                      label="Retail Price per Unit"
                      name="retailPrice"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <TextField
                      onChange={onChangeText}
                      id="wholesalePrice"
                      name="wholesalePrice"
                      label="Wholesale Price per Unit"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
        </form>
      </Dialog>
      <Dialog
        open={addCategoryDialog}
        onClose={() => setAddCatDialog(false)}
        aria-labelledby="form-dialog-tit                                                                  le"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Add New Category</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <form
                onSubmit={addCategorySubmit}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  autoFocus
                  id="name"
                  label="Category Name"
                  name="categoryName"
                  onChange={onChangeText}
                  type="text"
                  required
                  style={{ width: "80%" }}
                  size="small"
                />

                <Button
                  style={{ width: "20%" }}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Add
                </Button>
              </form>
            </Grid>
            <Grid item md={12} xs={12}>
              <TableContainer style={{ maxHeight: 300 }} component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      >
                        Category Name
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.categories
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, key) => (
                        <TableRow hover key={key}>
                          <TableCell scope="row">
                            <b>{row.category_id}</b>
                          </TableCell>
                          <TableCell>
                            <b>{row.category_name}</b>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Delete Category">
                              <IconButton
                                onClick={() =>
                                  handleDeleteCategory(row.category_id)
                                }
                                aria-label="delete"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={state.categories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={addSupplierDialog}
        onClose={() => setSupplierDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setSupplierDialog(false);
                }}
              >
                <ClearIcon />
              </IconButton>
              <Typography variant="h6">Add New Supplier</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={addSupplierSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ marginTop: 19 }}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Supplier Name"
                  name="supplierName"
                  onChange={onChangeText}
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  style={{ marginTop: 19 }}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Contact Number"
                  name="contactNumber"
                  onChange={onChangeText}
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  style={{ marginTop: 19 }}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  name="emailAddress"
                  onChange={onChangeText}
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ marginTop: 19 }}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Address"
                  name="address"
                  onChange={onChangeText}
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button variant="contained" type="submit" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={updateItemDialog}
        onClose={() => setUpdateItemDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setUpdateItemDialog(false);
                }}
              >
                <ClearIcon />
              </IconButton>
              <Typography variant="h6">Update Item</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <form onSubmit={handleUpdateItemData}>
          <DialogContent>
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={4}>
                    <Typography>ITEM DETAILS</Typography>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={8}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    <ButtonGroup color="primary" variant="contained">
                      <Button
                        color="primary"
                        onClick={() => setAddCatDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Category
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setAddBrandDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Brand
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setSupplierDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Supplier
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setAddUnitDialog(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Unit
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      onChange={onChangeTextUpdate}
                      autoFocus
                      id="name"
                      label="Item Name"
                      name="item_name"
                      type="text"
                      required
                      value={updateData.item_name}
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item md={8} xs={12}>
                      <TextField
                        onChange={onChangeTextUpdate}
                        autoFocus
                        id="name"
                        label="Item Description"
                        name="item_description"
                        type="text"
                        required
                        value={updateData.item_description}
                        fullWidth
                      />
                    </Grid> */}
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={state.categories}
                      value={updateData}
                      onChange={selectUpdateCategory}
                      getOptionLabel={(option) => option.category_name}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          required
                          size="small"
                          fullWidth
                          {...params}
                          label="Category"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={state.brands}
                      value={updateData}
                      onChange={selectUpdateBrand}
                      getOptionLabel={(option) => option.brand_name}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          required
                          size="small"
                          fullWidth
                          {...params}
                          label="Brand"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      onChange={onChangeTextUpdate}
                      autoFocus
                      label="Item Model"
                      name="model"
                      type="text"
                      value={updateData.model}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      onChange={onChangeTextUpdate}
                      autoFocus
                      label="Item Color"
                      name="color"
                      type="text"
                      value={updateData.color}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      onChange={onChangeTextUpdate}
                      autoFocus
                      label="Item Size"
                      name="size"
                      type="text"
                      value={updateData.size}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={state.units}
                      value={{ unit_prefix: updateData.unit }}
                      onChange={selectUpdateUnit}
                      getOptionLabel={(option) =>
                        option.unit_prefix ? option.unit_prefix : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          size="small"
                          fullWidth
                          {...params}
                          label="Unit"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      autoFocus
                      disabled
                      label="Item Bardcode"
                      value={updateData.barcode}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <Typography>RETAIL & WHOLESALE VALUE DETAILS</Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={supplierArray}
                      value={updateData}
                      onChange={selectUpdateSupplier}
                      getOptionLabel={(option) => option.supplier_name}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          required
                          size="small"
                          fullWidth
                          {...params}
                          label="Supplier"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      onChange={onChangeTextUpdate}
                      id="name"
                      name="supplier_price"
                      label="Supplier Net per Unit"
                      type="text"
                      disabled
                      fullWidth
                      value={updateData.supplier_price}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      onChange={onChangeTextUpdate}
                      id="name"
                      label="Retail Cost per Unit"
                      name="unit_price"
                      type="text"
                      disabled
                      fullWidth
                      value={updateData.unit_price}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      onChange={onChangeTextUpdate}
                      id="name"
                      label="Wholesale Cost per Unit"
                      name="price_wholesale"
                      type="text"
                      disabled
                      fullWidth
                      value={updateData.price_wholesale}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
        </form>
      </Dialog>
      <Dialog
        open={addStockDialog}
        onClose={() => setAddStockDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Add Stock</DialogTitle>
        <form onSubmit={addStock}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Quantity"
                  name="updateStock"
                  onChange={onChangeText}
                  type="text"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddStockDialog(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={releaseStockDialog}
        onClose={() => setReleaseStockDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Release Stock</DialogTitle>
        <form onSubmit={releaseStock}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Quantity"
                  name="updateStock"
                  onChange={onChangeTextRelease}
                  type="text"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  style={{ marginTop: 5, marginBottom: 5 }}
                  required
                >
                  <InputLabel>Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={branchSelect}
                    onChange={selectBranch}
                  >
                    <MenuItem value={1}>Branch 1</MenuItem>
                    {/* {state.categories.map((row) => (
                        <MenuItem value={row.category_id}>
                          {row.category_name}
                        </MenuItem>
                      ))} */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setReleaseStockDialog(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={viewLogs}
        onClose={() => setviewLogs(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Inventory Logs</DialogTitle>
        <DialogContent>
          <Logs />
        </DialogContent>
      </Dialog>
      <Dialog
        open={addBrandDialog}
        onClose={() => setAddBrandDialog(false)}
        aria-labelledby="form-dialog-tit                                                                  le"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Add New Brand</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <form
                onSubmit={addBrandSubmit}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  autoFocus
                  id="name"
                  label="Brand Name"
                  name="brandName"
                  onChange={onChangeText}
                  type="text"
                  required
                  style={{ width: "80%" }}
                  size="small"
                />

                <Button
                  style={{ width: "20%" }}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Add
                </Button>
              </form>
            </Grid>
            <Grid item md={12} xs={12}>
              <TableContainer style={{ maxHeight: 300 }} component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      >
                        Brand Name
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.brands
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, key) => (
                        <TableRow hover key={key}>
                          <TableCell scope="row">
                            <b>{row.brand_id}</b>
                          </TableCell>
                          <TableCell>
                            <b>{row.brand_name}</b>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Delete Brand">
                              <IconButton
                                onClick={() => handleDeleteBrand(row.brand_id)}
                                aria-label="delete"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={state.brands.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogTransfer}
        onClose={() => setDialogTransfer(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Transfer Inventory</DialogTitle>
        <DialogContent>
          <form onSubmit={OnSubmitAllTransfer}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    style={{ marginTop: 5, marginBottom: 5 }}
                    required
                  >
                    <InputLabel>Branch</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={branchSelect}
                      onChange={selectBranch}
                    >
                      <MenuItem value={1}>Branch 1</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setReleaseStockDialog(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={priceUpdateDialog}
        onClose={() => setPriceUpdateDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Price Update</DialogTitle>
        <form onSubmit={priceUpdateSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  flexWrap: "nowrap",
                }}
              >
                <TextField
                  value={state.currentItem.supplier_price}
                  label="Supplier cost per unit"
                  name="new_supplier_price"
                  fullWidth
                  type="text"
                  disabled
                />
                <ArrowRightAltRoundedIcon size="small" />
                <TextField
                  autoFocus
                  label="Supplier new cost per unit"
                  name="new_supplier_price"
                  onChange={onChangeText}
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  flexWrap: "nowrap",
                }}
              >
                <TextField
                  value={state.currentItem.unit_price}
                  label="Retail cost per unit"
                  fullWidth
                  type="text"
                  disabled
                />
                <ArrowRightAltRoundedIcon size="small" />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Retail new cost per unit"
                  name="new_unit_price"
                  onChange={onChangeText}
                  type="text"
                  required
                  fullWidth
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  flexWrap: "nowrap",
                }}
              >
                <TextField
                  value={state.currentItem.price_wholesale}
                  label="Wholesale cost per unit"
                  fullWidth
                  type="text"
                  disabled
                />
                <ArrowRightAltRoundedIcon size="small" />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Wholesale new cost per unit"
                  name="new_price_wholesale"
                  onChange={onChangeText}
                  type="text"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}></Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  id="date"
                  onChange={handleDateChange}
                  label="Date Effectiveness"
                  type="date"
                  disablePast
                  value={state.price_effectiveness}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={updatePricesDialog}
        onClose={() => setUpdatePricesDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Price Update</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will update inventory item prices or costs by its date
            effectiveness
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              pressUpdatePrice();
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addUnitDialog}
        onClose={() => setAddUnitDialog(false)}
        aria-labelledby="form-dialog-tit                                                                  le"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Add New Unit</DialogTitle>
        <DialogContent>
          <form onSubmit={addUnitSubmit}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} style={{ width: "100%" }}>
                <TextField
                  autoFocus
                  label="Unit Name"
                  name="unit_name"
                  onChange={onChangeText}
                  type="text"
                  required
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  autoFocus
                  label="Unit Prefix"
                  name="unit_prefix"
                  onChange={onChangeText}
                  fullWidth
                  type="text"
                  required
                  size="small"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button type="submit" color="primary" variant="contained">
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>

          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TableContainer style={{ maxHeight: 300 }} component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      >
                        Unit Name
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      >
                        Unit Prefix
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#3f51b5", color: "white" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.units
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, key) => (
                        <TableRow hover key={key}>
                          <TableCell scope="row">
                            <b>{row.unit_id}</b>
                          </TableCell>
                          <TableCell>
                            <b>{row.unit_name}</b>
                          </TableCell>
                          <TableCell>
                            <b>{row.unit_prefix}</b>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Delete Unit">
                              <IconButton
                                onClick={() => handleDeleteUnit(row.unit_id)}
                                aria-label="delete"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={state.categories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteItemDialog}
        onClose={() => setDeleteItemDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Delete Item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will remove the selected inventory item in your store
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              handleDeleteItem();
            }}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
