import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../../../components/api";
import ScanItem from "./scanItem";
import ItemList from "./itemList";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Cashier(props) {
  const { onClickRemit } = props;

  const element = document.getElementById("textField");
  const childRefItemList = React.useRef();
  const childRefScanItem = React.useRef();

  const dispatch = useDispatch();
  const orderType = useSelector((state) => state.orderReducer.orderType);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const onChangeOrderType = (type) => {
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        orderType: type,
        selectedItems: [],
      },
    });
    element.focus();
  };
  const updateStoreItems = () => {
    childRefScanItem.current.updateStoreListItem();
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <ButtonGroup
                color="primary"
                variant="contained"
                style={{ marginBottom: 5 }}
              >
                <Button onClick={() => onChangeOrderType("Retail")}>
                  Retail
                </Button>
                <Button onClick={() => onChangeOrderType("Whole Sale")}>
                  Whole Sale
                </Button>

                {/* <Button onClick={()=>onChangeOrderType('Whole Sale')}>Whole Sale</Button> */}
              </ButtonGroup>
            </Grid>
            <Grid xs={12} md={12}>
              <ScanItem
                ref={childRefScanItem}
                onClearDiscount={() => childRefItemList.current.clearDiscount()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ShoppingCartIcon />
                <Typography variant={"h5"} style={{ fontWeight: "bold" }}>
                  {orderType}
                </Typography>
              </div>
            </Grid>
            
            <Grid container justify="flex-end" item xs={12} md={6}>
              <ButtonGroup
                color="primary"
                variant="contained"
              >
                <Button
                  startIcon={<Icon>refresh</Icon>}
                  style={{ background: "#3498db" }}
                  onClick={() => updateStoreItems()}
                >
                  Update Item List
                </Button>
                <Button
                  startIcon={<Icon>send_and_archive</Icon>}
                  style={{ background: "#9b59b6" }}
                  onClick={() => onClickRemit()}
                >
                  Remit
                </Button>

                {/* <Button onClick={()=>onChangeOrderType('Whole Sale')}>Whole Sale</Button> */}
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <ItemList ref={childRefItemList} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
