import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TransactionDetails from "./transactionDetails";
import Dropdown from "../../reusableComponents/Dropdown/dropdown";
// import ThermalPrinter, { PrinterTypes } from "browser-thermal-printer";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;
const width = window.innerWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const dispatch = useDispatch();
  const blmcPage = useSelector((state) => state.pageReducer);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const userLogin = useSelector((state) => state.loginReducer.userLogin);
  const branch_id = useSelector((state) => state.loginReducer.branch_id);

  const openLoading = useSelector((state) => state.orderReducer.openLoading);

  const refreshStoreList = useSelector(
    (state) => state.orderReducer.refreshStoreList
  );
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };


  const [orders, setOrders] = useState([]);
  const [ordersToday, setOrdersToday] = useState([]);
  const [ordersTodayCount, setOrdersTodayCount] = useState(0);
  const [todaySales, setTodaySales] = useState(0);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [openAddOrder, setOpenAddOrder] = React.useState(false);
  const [openStartingCash, setOpenStartingCash] = React.useState(false);
  const [state, setState] = React.useState({
    amount: "",
    search: "",
    from: "",
    to: "",
    profit: 0,
    unpaidTotalTransaction: 0,
    unpaidAmount: 0,
    orderType: "All",
    unpaidOrders: '',
    refresh: false
  });
  useEffect(() => {
    dispatch({
      type: "setPageTitle",
      requestResult: "Transactions",
    });
    onGetOrders(page, rowsPerPage, state.search);
  }, [refreshStoreList, branch_id, state.unpaidOrders, state.refresh]);
  const handleClickOpenOrderDetails = (data) => {
    setSelectedOrderDetails(data);
    setOpen(true);
  };
  const handleCloseOpenOrderDetails = () => {
    setOpen(false);
    setState(prev => ({
      ...prev,
      refresh: !state.refresh
    }))
  };
  const handleGetOrders = () => {
    const data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {},
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {
        customers: ["customers.customer_id = orders.customer_id", "left"],
      },
      selectData: "*",
    };

    sqlData(data, "", "POST", "order/viewdata").then((result) => {
      const orderToday = result.filter(
        (orToday) =>
          moment(orToday.date_added).format("Y-MM-DD") ==
          moment().format("Y-MM-DD")
      );

      const totalSales = orderToday.reduce(
        (a, b) => a + parseFloat(b.total_order_amount),
        0
      );
      setTodaySales(totalSales);
      setOrders(result);
      setOrdersToday(orderToday);
    });
  };

  const [search, setSearch] = useState("");
  const searchItem = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onGetOrders(newPage, rowsPerPage, state.search);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    onGetOrders(0, event.target.value, state.search);
  };
  const onSubmitRegister = (e) => {
    e.preventDefault();
    cashRegister();
  };
  const onGetOrders = (pageNum, limit, search) => {
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        openLoading: true,
      },
    });
    let data = {
      branch_id: [branch_id],
      user_id: userLogin[0].user_id,
      type: "Transactions",
      page: pageNum + 1,
      limit: limit,
      from: state.from,
      to: state.to,
      search: search,
      orderType: state.orderType,
      unpaidOrders: state.unpaidOrders
    };
    sqlData(data, "", "POST", "order/onGetTransactions").then((result) => {
      setOrdersToday(result.res);
      setTodaySales(result.totalOrderDashboard[0].total_amount);
      setOrdersTodayCount(result.totalOrderDashboard[0].total_orders);
      let profit =
        result.totalOrderDashboard[0].total_amount -
        result.totalOrderDashboard[0].total_supplier_price;
      setState((prev) => ({
        ...prev,
        profit: profit,
        unpaidTotalTransaction:
          result.totalOrderDashboard[0].total_order_unpaid,
        unpaidAmount: result.totalOrderDashboard[0].unpaid_amount,
      }));
      dispatch({
        type: "onChangeOrderReducer",
        data: {
          openLoading: false,
        },
      });
    });
  };
  const cashRegister = () => {
    const data = {
      user_id: userLogin[0].user_id,
      branch_id: branch_id,
      amount: state.amount,
    };
    const usersData = {
      user_id: userLogin[0].user_id,
      cash_register: state.amount,
    };
    Promise.all([
      sqlData(usersData, "", "PUT", "users/" + userLogin[0].user_id),
      sqlData(data, "", "POST", "cashRegisterLogs"),
    ]).then((result) => {
      setOpenAddOrder(true);
    });
  };
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const AddOrderModal = () => {
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        user_id: userLogin[0].user_id,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData:
        "full_name,user_id,user_type,branch_id,page_dashboard,page_orders,page_store_item,page_customers,page_reports,page_inventory,page_supply_chain,cash_register",
    };
    sqlData(data, "", "POST", "users/viewdata").then((result) => {
      if (result.length > 0) {
        if (result[0].cash_register != null) {
          setOpenAddOrder(true);
          dispatch({
            type: "onChangeOrderReducer",
            data: {
              branch_id: branch_id,
            },
          });
        } else {
          setOpenStartingCash(true);
        }
      }
    });
  };
  function zeroPad(num) {
    return num.toString().padStart(13, "0");
  }
  let ordersTodayDisplay = ordersToday;
  // .filter((files) => {
  //   return (
  //     files.receipt_number !== null &&
  //     files.receipt_number
  //       .toLowerCase()
  //       .indexOf(state.search.toLocaleLowerCase()) !== -1
  //   );
  // });
  let filterDate = () => {
    setPage(0);
    onGetOrders(0, rowsPerPage, state.search);
  };
  const onSubmitSearch = () => {
    setPage(0);
    onGetOrders(0, rowsPerPage, state.search);
  };
  const onRemoveSearch = () => {
    setPage(0);
    setState((prev) => ({
      ...prev,
      search: "",
    }));

    onGetOrders(0, rowsPerPage, "");
  };

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      orderType: e.target.value,
    }));
  };
  const handleClick = () => {
    onGetOrders(0, rowsPerPage, state.search);
  };
  const onClickUnpaidTransaction = () => {
    // onGetOrders(0, rowsPerPage, state.search);
    setState(prev => ({
      ...prev,
      unpaidOrders: 'Unpaid'
    }))
  };
  const onReset = () => {
    setState(prev => ({
      ...prev,
      unpaidOrders: '',
      from: '',
      to: '',
      refresh: !state.refresh
    }))
  }

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 9999 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{ fontWeight: "bold", fontSize: 18 }}
            variant="overline"
          >
            Transactions
          </Typography>
        </Grid>
        <Grid container justify="flex-end" item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            style={{
              marginRight: width > 600 ? 5 : undefined,
              marginBottom: width < 600 ? 5 : undefined,
            }}
            value={state.from}
            onChange={onChangeText}
            name="from"
            size="small"
            type="date"
            variant="outlined"
            label="From"
          />

          <TextField
            InputLabelProps={{ shrink: true }}
            style={{
              marginRight: width > 600 ? 5 : undefined,
              marginBottom: width < 600 ? 5 : undefined,
            }}
            value={state.to}
            onChange={onChangeText}
            name="to"
            size="small"
            type="date"
            variant="outlined"
            label="To"
          />
          <ButtonGroup
            color="primary"
            variant="contained"
            style={{ marginBottom: 10 }}
          >
            <Button onClick={() => filterDate()}>Filter</Button>
            <Button onClick={() => onReset()}>
              <Icon>refresh</Icon>
            </Button>

          </ButtonGroup>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12} md={12}>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 5 }}>
                <Dropdown
                  data={[
                    { name: "All", value: "All" },
                    { name: "Retail", value: "Retail" },
                    { name: "Whole Sale", value: "Whole Sale" },
                  ]}
                  value={state.orderType}
                  handleChange={handleChange}
                  optionValue={"value"}
                  optionName={"name"}
                  type={true}
                />
              </div>
              <ButtonGroup color="primary" variant="contained">
                <Button onClick={handleClick}>Filter</Button>
              </ButtonGroup>
            </div>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" item xs={12} md={6}>
          <TextField
            onChange={onChangeText}
            name="search"
            value={state.search}
            size="small"
            variant="outlined"
            label="Search Reference No."
            style={{ marginRight: 5 }}
          />
          <ButtonGroup
            color="primary"
            variant="contained"
          // style={{ marginBottom: 10 }}
          >
            <Button onClick={() => onRemoveSearch()}>
              <Icon>close</Icon>
            </Button>
            <Button onClick={() => onSubmitSearch()}>Search</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2" style={{ marginBottom: 25 }}>
                Transaction Dashboard
              </Typography>
              <Grid container spacing={3} style={{}}>
                <Grid item md={2} xs={12}>
                  <Typography variant="h5">
                    {ordersTodayCount}{" "}
                    <Typography variant="overline">Transactions</Typography>
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography variant="h5">
                    {todaySales == null ? 0 : formatNumber(todaySales)}{" "}
                    <Typography variant="overline">Sales</Typography>
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography variant="h5">
                    {state.profit == null ? 0 : formatNumber(state.profit)}{" "}
                    <Typography variant="overline">Profit</Typography>
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography variant="h5">
                    {state.unpaidTotalTransaction == null
                      ? 0
                      : state.unpaidTotalTransaction}{" "}
                    <Typography
                      onClick={onClickUnpaidTransaction}
                      variant="overline"
                      style={{ cursor: "pointer" }}
                    >
                      <u>Unpaid Transactions</u>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography variant="h5">
                    {state.unpaidAmount == null
                      ? 0
                      : formatNumber(state.unpaidAmount)}{" "}
                    <Typography variant="overline">Unpaid Amount</Typography>
                  </Typography>
                </Grid>
                {/* <Grid item md={3} xs={12}>
                  <Typography variant="h5">
                    {todaySales == null|| userLogin[0]?.cash_register ==null ?0: formatNumber(
                      parseFloat(todaySales) +
                        parseFloat(userLogin[0]?.cash_register)
                    )}{" "}
                    <Typography variant="overline">Remit Amount </Typography>
                  </Typography>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <TableContainer style={{ marginTop: 10 }} component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead style={{ background: "#fff" }}>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Reference #</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Date Ordered</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Staff</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Customer</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Total Amount</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Balance</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Profit</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersTodayDisplay.map((order, key) => (
              <TableRow>
                <TableCell>{rowsPerPage * page + key + 1}</TableCell>
                <TableCell>{zeroPad(order.receipt_number)}</TableCell>
                <TableCell>{order.order_type}</TableCell>
                <TableCell>
                  {moment(order.date_added).format("Y-MM-DD")}
                </TableCell>
                <TableCell>{order.full_name}</TableCell>
                <TableCell>{order.customer_name}</TableCell>
                <TableCell>{formatNumber(order.total_amount)}</TableCell>
                <TableCell>{formatNumber(order.total_balance)}</TableCell>

                <TableCell>
                  {formatNumber(
                    parseFloat(order.total_amount) -
                    parseFloat(order.total_supplier_price)
                  )}
                </TableCell>

                <TableCell>{order.order_status}</TableCell>
                <TableCell>
                  <Icon
                    onClick={() => handleClickOpenOrderDetails(order)}
                    style={{ cursor: "pointer", color: "#757575" }}
                  >
                    launched
                  </Icon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={ordersTodayCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Dialog
        open={openStartingCash}
        onClose={() => setOpenAddOrder(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Initial Cash Register</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmitRegister}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  onChange={onChangeText}
                  name="amount"
                  type="number"
                  style={{ width: "100%" }}
                  label="Amount"
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                >
                  <Button type="submit">Submit</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <TransactionDetails
        open={open}
        selectedOrderDetails={selectedOrderDetails}
        handleCloseOpenOrderDetails={handleCloseOpenOrderDetails}
      />
    </div>
  );
}
