import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CashRemittanceAction from "./indexAction";
// import ThermalPrinter, { PrinterTypes } from "browser-thermal-printer";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function IndexTableRemittance(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const {
    sales_amount,
    amount_remitted,
    total_initial_cash_register,
    cashRemittanceList,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    listCount,
    modalCashRegister,
    onCloseOpenModal,
    onSubmitCashRemit,
    onChangeText,
    onSelectedList,
    amountReceived,
    onChangeTextRegister,
    cashRegister
  } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="subtitle2" style={{ marginBottom: 25 }}>
              Cash Remittance Dashboard
            </Typography>
            <Grid container spacing={3} style={{}}>
              <Grid item md={3} xs={12}>
                <Typography variant="h5">
                  {formatNumber(sales_amount)}{" "}
                  <Typography variant="overline">Sales</Typography>
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography variant="h5">
                  {formatNumber(total_initial_cash_register)}{" "}
                  <Typography variant="overline">Initial Cash Register</Typography>
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography variant="h5">
                  {formatNumber(parseFloat(total_initial_cash_register) + parseFloat(sales_amount))}{" "}
                  <Typography variant="overline">Total</Typography>
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography variant="h5">
                  {formatNumber(amount_remitted)}{" "}
                  <Typography variant="overline">Cash Remitted</Typography>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <TableContainer style={{ marginTop: 10 }} component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead style={{ background: "#fff" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Date Ordered
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Initial Cash Register
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Sales Amount
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Amount Recieved
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Date Recieved</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cashRemittanceList.map((row, key) => (
                <TableRow>
                  <TableCell>{page * rowsPerPage + key + 1}</TableCell>
                  <TableCell>
                    {row?.full_name != null
                      ? String(row?.full_name).toLocaleUpperCase()
                      : ""}
                  </TableCell>
                  <TableCell>
                    {moment(row?.date_added).format("Y-MM-DD")}
                  </TableCell>
                  <TableCell>
                    {row?.amount != null ? formatNumber(row?.amount) : ""}
                  </TableCell>
                  <TableCell>
                    {row.sales_amount != null
                      ? formatNumber(row.sales_amount)
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.total_amount != null ? formatNumber(row.total_amount) : ""}
                  </TableCell>
                  <TableCell>
                    {row.amount_remitted != null
                      ? formatNumber(row.amount_remitted)
                      : ""}
                  </TableCell>
                  <TableCell>
                    {String(row.status).toLocaleUpperCase()}
                  </TableCell>
                  <TableCell>
                    {row?.date_remitted}
                  </TableCell>
                  <TableCell>
                    <Icon
                      onClick={() => {
                          if(row.amount_remitted != null && moment(new Date).format('YYYY-MM-DD') > moment(row?.date_added).format('YYYY-MM-DD')){
                             
                          }else{
                            onSelectedList(row);
                            onCloseOpenModal(true);
                          }
                     
                      }}
                      style={{ cursor: "pointer", color:row.amount_remitted != null && moment(new Date).format('YYYY-MM-DD') > moment(row?.date_added).format('YYYY-MM-DD')?'#d9d7d7': "#757575" }}
                    >
                      launched
                    </Icon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={listCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      <Dialog
        open={modalCashRegister}
        onClose={() => onCloseOpenModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Cash Received</DialogTitle>
        <DialogContent>
        <form onSubmit={onSubmitCashRemit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={(e)=>onChangeTextRegister(e,0)}
                  name="1000"
                  type="number"
                  style={{ width: "100%" }}
                  label="1,000"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,1)}
                  name="500"
                  type="number"
                  style={{ width: "100%" }}
                  label="500"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={(e)=>onChangeTextRegister(e,2)}
                  name="200"
                  type="number"
                  style={{ width: "100%" }}
                  label="200"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,3)}
                  name="100"
                  type="number"
                  style={{ width: "100%" }}
                  label="100"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,4)}
                  name="50"
                  type="number"
                  style={{ width: "100%" }}
                  label="50"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={(e)=>onChangeTextRegister(e,5)}
                  name="20"
                  type="number"
                  style={{ width: "100%" }}
                  label="20"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,6)}
                  name="coins"
                  type="number"
                  style={{ width: "100%" }}
                  label="Coins"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{display:'flex',flexDirection:'row'}}>
                <Typography variant="overline" style={{fontSize:20,fontWeight:'bold'}}>Total : </Typography>
                <Typography variant="overline" style={{fontSize:20,fontWeight:'bold'}}>{formatNumber(cashRegister.reduce((count,val)=>{
                  count += parseFloat(val.amount)*parseInt(val.count)
                  return count
                },0))}</Typography>
                </div>
               


              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                >
                  <Button type="submit">Submit</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
          {/* <form onSubmit={onSubmitCashRemit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  onChange={onChangeText}
                  name="amountReceived"
                  type="number"
                  style={{ width: "100%" }}
                  label="Amount"
                  value={amountReceived}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                >
                  <Button type="submit">Submit</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form> */}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
