import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import imgLogo from "../assents/logo/logo.png";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const height = window.innerHeight;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const classes = useStyles();
    const loginDetails = useSelector((state) => state.loginReducer.userLogin);
    // const appNav = useSelector((state) => state.navigation_reducer.appNav);
    const history = useHistory();
    const [open, setOpen] = React.useState(true);
    const [route, setRoute] = React.useState("orders");
    const [readyToBeAnimated, setReadyToBeAnimated] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const getPriviledge = () => {
        loginDetails.forEach((val) => {
            if (val.page_dashboard === "1") {
                setRoute("dashboard");
            }
            setTimeout(() => {
                document.getElementById("logo").style.visibility = "visible";
                document.getElementById("logo").click();
            }, 500);
        });
    };
    // function random(min, max) {
    //     const delta = max - min;
    //     return (direction = 1) => (min + delta * Math.random()) * direction;
    // }

    React.useEffect(() => {
        getPriviledge();
    }, []);
    const routePage = () => {
        // document.getElementById("topNav").style.visibility = "visible";
        history.push("/" + route);
    };
    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            // TransitionComponent={Transition}
            >
                <DialogContent>
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <img src={imgLogo} onClick={() => {
                            var tl = gsap.timeline({
                                onComplete: routePage,
                            });
                            gsap.set("#logo", {
                                scale: 0,
                                //   rotation: 0.01,
                                z: 0.01,
                                transformOrigin: "50% 50%",
                            });
                            tl.to("#logo", 2, { scale: 1 }).play();
                        }}
                            id="logo" style={{ width: 410, height: 300, visibility: 'hidden' }} />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
