import React, { useState } from "react";
import { sqlData } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const OrderReport = () => {
  const dispatch = useDispatch();

  const branch_id = useSelector((state) => state.loginReducer.branch_id);
  const [state, setState] = useState({
    selectedMonth: moment(new Date()).format("YYYY-MM"),
    ordersToday: [],
    dailySales: [],
    allSales: 0,
    allProfit: 0,
    monthFormat: "",
    refresh: false,
    orderType: "All",
    totalAmountSupplier: 0
  });

  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };

  const orderReport = () => {
    let data = { branch_id: branch_id, selectedMonth: selectedMonth, orderType: state.orderType };
    let monthlyInventory = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        'store_item_logs.s_branch_id': branch_id,
        'inventory.delete_id': '0',
        // 'store_item_logs.s_item_id': 12
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {
        inventory: ["inventory.item_id = store_item_logs.s_item_id", "left"],
        store_items: ["store_items.item_id = store_item_logs.s_item_id", "left"],
      },
      selectData: "*,concat(year(store_item_logs.s_date_updated),'-',month(store_item_logs.s_date_updated))as month",
    }
    let stocks = JSON.stringify({
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        'store_items.branch_id': branch_id,
        delete_id: '0',
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {
        inventory: ["inventory.item_id = store_items.item_id", "left"],
        categories: ["inventory.category_id = categories.category_id", "left"],
        suppliers: ["inventory.supplier_id = suppliers.supplier_id", "left"],
      },
      selectData: "*, store_items.branch_id as b_id",
    });
    Promise.all([
      sqlData(data, "", "POST", "order/dashboard"),
      sqlData(stocks, "", "POST", "storeitem/viewdata"),
      sqlData(monthlyInventory, "", "POST", "storeitemlogs/viewdata"),
    ])
      .then((result) => {
        let inventory = result[1]
        inventory.sort(function (a, b) {
          return a["item_name"].localeCompare(b["item_name"]);
        })
        let totalSales = 0;
        let totalProfit = 0;

        let store_item_logs = result[2].reverse()
        let store_item_log = []
        let invRetailValues = []
        let invWholeSaleValues = []
        if (result[0].totalSales.length > 0) {
          totalSales = formatNumber(result[0].totalSales[0].totalSales);
        }
        if (result[0].totalProfit.length > 0) {
          totalProfit = formatNumber(result[0].totalProfit[0].TotalProfit);
        }
        store_item_logs.map((val) => {
          let match = store_item_log.filter((array) => array.s_item_id === val.s_item_id && moment(array.s_date_updated).format('YYYY-MM') === moment(val.s_date_updated).format('YYYY-MM'))
          if (match.length == 0) {
          }
        })
        inventory.map(inv => {
          let qtty = parseInt(inv.store_quantity)
          let unit_price = parseFloat(inv.unit_price)
          let supplier_price = parseFloat(inv.supplier_price)
          if (parseInt(inv.store_quantity) <= 0) {
            qtty = 0
          }
          if (parseInt(inv.store_quantity) <= 0) {
            unit_price = 0
          }
          if (parseInt(inv.supplier_price) <= 0) {
            supplier_price = 0
          }
          invRetailValues.push(qtty * parseFloat(unit_price).toFixed(2))
          invWholeSaleValues.push(qtty * parseFloat(inv.supplier_price).toFixed(2))
        })
        let totalAmountRetailInventory = invRetailValues.reduce((a, b) => a + parseInt(b), 0)
        let totalAmountSupplierInventory = invWholeSaleValues.reduce((a, b) => a + parseInt(b), 0)
        // let inventoryTotal = {
        // totalAmountRetail: formatNumber(totalAmountRetailInventory),
        let totalAmountSupplier = formatNumber(totalAmountSupplierInventory)
        // }
        setState((prev) => ({
          ...prev,
          ordersToday: result[0].recentTransaction,
          monthFormat: result[0].monthFormat,
          dailySales: result[0].dailySales,
          allSales: totalSales,
          allProfit: totalProfit,
          totalAmountSupplier: totalAmountSupplier
        }));
      });
  };

  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClick = () => {
    setState((prev) => ({
      ...prev,
      refresh: !state.refresh,
    }));
  };

  React.useEffect(() => {
    dispatch({
      type: "setPageTitle",
      requestResult: "Dashboard",
    });
    orderReport();
  }, [state.refresh, branch_id]);

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      orderType: e.target.value,
    }));
  };
  const onSubmitFilterOrderType = () => {
    orderReport()
  };
  let selectedMonth = state.selectedMonth;
  let monthFormat = state.monthFormat;
  let allSales = state.allSales;
  let allProfit = state.allProfit;
  let dailySales = state.dailySales;
  let ordersToday = state.ordersToday;
  let orderType = state.orderType;
  let totalAmountSupplier = state.totalAmountSupplier;
  return {
    ordersToday,
    monthFormat,
    allSales,
    allProfit,
    dailySales,
    orderReport,
    onChangeText,
    handleClick,
    selectedMonth,
    orderType,
    handleChange,
    onSubmitFilterOrderType,
    totalAmountSupplier
  };
};
export default OrderReport;
