import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
const formatNumber = (num) => {
  if (num != "") {
    var amount = parseFloat(num).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  } else {
    return 0;
  }
};
const columns = [
  {
    id: "item_name",
    label: "Item Name",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "brand",
    label: "Brand",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "category_name",
    label: "Category",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "item_description",
    label: "Item Description",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  { id: "unit", label: "Unit" },
  { id: "store_quantity", label: "Stocks" },
  { id: "supplier_price", label: "Supplier Price", format: (value) => formatNumber(value) },
  { id: "unit_price", label: "Retail Price", format: (value) => formatNumber(value) },
  { id: "price_wholesale", label: "Whole Sale Price", format: (value) => formatNumber(value) },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 370,
  },
});

export default function InventoryListItemTable(props) {
  const classes = useStyles();
  const { itemList, onSelectItemList, onChangeText, updateQuantity } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const orderType = useSelector((state) => state.orderReducer.orderType);
  const [editModal, setEditModal] = React.useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onEditQuantity = (e) => {
    e.preventDefault();
    updateQuantity();
    setEditModal(false);
  };

  return (
    <Paper className={classes.root} variant="outlined">
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          size="small"
          aria-label="a dense table sticky table"
        >
          <TableHead>
            <TableRow>
              {/* <TableCell></TableCell> */}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, whiteSpace: "nowrap" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      if (
                        (row.barcode == "" ||
                          row.barcode == null)
                      ) {
                        alert('Warning, Please add barcode for this item')
                      } else if (row.unit_price == "" ||
                        row.unit_price == null) {
                        alert('Warning, Please add selling price for this item')
                      }
                      else if (row.supplier_price == "" ||
                        row.supplier_price == null) {
                        alert('Warning, Please add supplier price for this item')
                      } else if ((row.price_wholesale == "" ||
                        row.price_wholesale == null) && orderType == 'Whole Sale') {
                        alert('Warning, Please add wholesale price for this item')
                      } else {
                        onSelectItemList(row);
                        setEditModal(true);
                      }
                    }}
                  >
                    {/* <TableCell>
                      {" "}
                      <Checkbox
                        defaultChecked
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </TableCell> */}
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        >
                          {column.format && value != null
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={itemList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={editModal}
        onClose={() => setEditModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Quantity</DialogTitle>
        <DialogContent>
          <form onSubmit={onEditQuantity}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  id="quantity"
                  autoFocus
                  onChange={onChangeText}
                  name="search"
                  type="number"
                  style={{ width: "100%" }}
                  label="Quantity"
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                >
                  <Button type="submit">Press Enter</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Paper>
  );
}
