const initialState = {
  orderType: "Retail",
  selectedItem: "",
  branch_id: "",
  selectedItems: [],
  refreshStoreList: false,
  cash_register_id: "",
  openLoading: false,
  terms: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "onChangeOrderReducer":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
