import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import BarChartIcon from "@material-ui/icons/BarChart";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExtensionIcon from "@material-ui/icons/Extension";
import Filter9PlusIcon from "@material-ui/icons/Filter9Plus";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PeopleIcon from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link as NewLink, useHistory } from "react-router-dom";
export default function MainListItems({ userLogin }) {
  const history = useHistory()
  const logout = () => {
    localStorage.clear();
    history.push('/')
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  return (
    <div>
      {userLogin.map((val, index) => {
        return (
          <div key={index}>
            {val.page_dashboard == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/dashboard"
              >
                <ListItem button>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </NewLink>
            )}
            {val.page_orders == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/orders"
              >
                <ListItem button>
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Orders" />
                </ListItem>
              </NewLink>
            )}
            {val.page_store_item == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/store/items"
              >
                <ListItem button>
                  <ListItemIcon>
                    <Filter9PlusIcon />
                  </ListItemIcon>
                  <ListItemText primary="Store Items" />
                </ListItem>
              </NewLink>
            )}
            {val.page_customers == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/customers"
              >
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Customers" />
                </ListItem>
              </NewLink>
            )}
            {val.page_reports == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/reports"
              >
                <ListItem button>
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
              </NewLink>

            )}
            {val.page_inventory == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/inventory"
              >
                <ListItem button>
                  <ListItemIcon>
                    <BusinessCenterIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inventory" />
                </ListItem>
              </NewLink>
            )}
            {val.page_transactions == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/transactions"
              >
                <ListItem button>
                  <ListItemIcon>
                    <Icon>point_of_sale</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Transactions" />
                </ListItem>
              </NewLink>
            )}
            {val.page_supply_chain == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/supplychain"
              >
                <ListItem button>
                  <ListItemIcon>
                    <ExtensionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Supply Chain" />
                </ListItem>
              </NewLink>
            )}
            {val.page_supplier == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/supplier"
              >
                <ListItem button>
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Supplier" />
                </ListItem>
              </NewLink>
            )}
            {val.page_cash_remittance == 1 && (
              <NewLink
                style={{ textDecoration: "none", color: "black" }}
                to="/cashRemittance"
              >
                <ListItem button>
                  <ListItemIcon>
                    <Icon>markunread_mailbox</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Cash Remittance" />
                </ListItem>
              </NewLink>
            )}
          </div>
        );
      })}

      <ListItem button onClick={() => { logout() }}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </div>
  );
}

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem> */}
  </div>
);
