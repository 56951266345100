import React from 'react'
import { Link as NewLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const NavigationLogic = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.loginReducer.userLogin[0]);
  const blmcPage = useSelector((state) => state.pageReducer);
  const userLogin = useSelector((state) => state.loginReducer.userLogin);
  const handleBranches = useSelector((state) => state.loginReducer.handleBranches);
  const branch_id = useSelector((state) => state.loginReducer.branch_id);
  const [accountDetailsDialog, setAccountDetailsDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleAccountDetailsDialog = (status) => {
    setAccountDetailsDialog(status);
  };
  const logout = () => {
    localStorage.clear();
    history.push("/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const handleChange = (e) => {
    dispatch({
      type: 'onChangeLoginReducer',
      data: {
        branch_id: e.target.value,
        userLogin: userLogin.map((val, index) => (index == 0) ? { ...val, branch_id: e.target.value } : val)
      }
    })
  }
  const handleAddCustTextChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    console.log({ 'name': name, 'value': value })
    dispatch({
      type: "onChangeLoginReducer",
      data: {
        loginDetails: {
          ...loginDetails,
          [name]: value
        }
      }
    });
  }
  return { open, handleDrawerOpen, handleDrawerClose, logout, userLogin, blmcPage, handleChange, handleBranches, branch_id, accountDetailsDialog, handleAccountDetailsDialog, handleAddCustTextChange, loginDetails }
}
export default NavigationLogic