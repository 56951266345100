import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Badge from '@material-ui/core/Badge';

import { sqlData, sqlDataDelete } from "../api";
import { useDispatch, useSelector } from "react-redux";

import PurchaseOrder from './purchaseOrder';
import Suppliers from './Suppliers';
import Purchase from './purchase'
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function StoreTabs() {
    const dispatch = useDispatch();
    const blmcPage = useSelector((state) => state.pageReducer);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch({
            type: "setPageTitle",
            requestResult: "Supply Chain Management",
        });

        handlePendingTransfers();
    }, []);

    const [counts, setCounts] = useState({
        pendingTransfer: 0
    });

    const handlePendingTransfers = () => {
        const data = JSON.stringify({
            page: 0,
            customWhere: false,
            count: true,
            customWhereStr: "",
            where: {
                transfer_status: "Pending",
            },
            orWhere: {},
            like: {},
            orLike: {},
            groupBy: [],
            orderBy: [],
            join: {},
            selectData: "*",
        });

        sqlData(data, "", "POST", "transfers/viewdata").then((result) => {
            dispatch({
                type: "setPendingtransferCount",
                requestResult: result,
            })
        });
    };

    return (
        <div>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab label="Purchase Order" {...a11yProps(0)} />
                    <Tab label="Suppliers" {...a11yProps(1)} />
                    {/* <Tab label="Purchase" {...a11yProps(2)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Purchase />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Suppliers />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                <PurchaseOrder />
            </TabPanel> */}
        </div>
    );
}
