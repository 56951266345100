import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Divider from "@material-ui/core/Divider";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sqlData } from "../api";
import AddOrder from "./AddOrder/index";
import { IconButton, TextField, Backdrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import OrderDetails from "./orderDetails";
import { useHistory } from "react-router-dom";
import Dropdown from "../../reusableComponents/Dropdown/dropdown";
// import ThermalPrinter, { PrinterTypes } from "browser-thermal-printer";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const dispatch = useDispatch();
  const blmcPage = useSelector((state) => state.pageReducer);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const userLogin = useSelector((state) => state.loginReducer.userLogin);
  const branch_id = useSelector((state) => state.loginReducer.branch_id);

  const openLoading = useSelector((state) => state.orderReducer.openLoading);
  const history = useHistory();
  const cash_register_id = useSelector(
    (state) => state.orderReducer.cash_register_id
  );
  const refreshStoreList = useSelector(
    (state) => state.orderReducer.refreshStoreList
  );
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    dispatch({
      type: "setPageTitle",
      requestResult: "Orders",
    });
    onGetOrders();
  }, [refreshStoreList, branch_id]);

  const [orders, setOrders] = useState([]);
  const [ordersToday, setOrdersToday] = useState([]);
  const [ordersTodayCount, setOrdersTodayCount] = useState(0);
  const [todaySales, setTodaySales] = useState(0);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [openAddOrder, setOpenAddOrder] = React.useState(false);
  const [openStartingCash, setOpenStartingCash] = React.useState(false);
  const [state, setState] = React.useState({
    amount: "",
    search: "",
    searchDisplay: "",
    orderType: "Retail",
    cashRegister:[
      {type:'paper',amount:1000,count:0},
      {type:'paper',amount:500,count:0},
      {type:'paper',amount:200,count:0},
      {type:'paper',amount:100,count:0},
      {type:'paper',amount:50,count:0},
      {type:'paper',amount:20,count:0},
      {type:'coins',amount:0,count:0},
    ]
  });
  const handleClickOpenOrderDetails = (data) => {
    setSelectedOrderDetails(data);
    setOpen(true);
  };
  const handleCloseOpenOrderDetails = () => {
    setOpen(false);
  };
  const handleGetOrders = () => {
    const data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {},
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {
        customers: ["customers.customer_id = orders.customer_id", "left"],
      },
      selectData:
        "*, CONCAT(cust_lname,', ',cust_fname, cust_mname, cust_extname) as customer_name",
    };
    sqlData(data, "", "POST", "order/viewdata").then((result) => {
      const orderToday = result.filter(
        (orToday) =>
          moment(orToday.date_added).format("Y-MM-DD") ==
          moment().format("Y-MM-DD")
      );

      const totalSales = orderToday.reduce(
        (a, b) => a + parseFloat(b.total_order_amount),
        0
      );
      setTodaySales(totalSales);
      setOrders(result);
      setOrdersToday(orderToday);
    });
  };

  const [search, setSearch] = useState("");
  const searchItem = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onSubmitRegister = (e) => {
    e.preventDefault();
    cashRegister();
  };
  const onGetOrders = () => {
    let data = {
      branch_id: branch_id,
      user_id: userLogin[0].user_id,
      orderType: state.orderType
    };
    sqlData(data, "", "POST", "order/onGetOrders").then((result) => {
      if (parseInt(userLogin[0].user_type) == 2) {
        document.getElementById("new_order").click();
      }

      setOrdersToday(result.res);
      setTodaySales(result.totalOrderDashboard[0].total_amount);
      setOrdersTodayCount(result.totalOrderDashboard[0].total_orders);
      dispatch({
        type: "onChangeOrderReducer",
        data: {
          openLoading: false,
        },
      });
    });
  };
  const cashRegister = () => {
    let amount = state.cashRegister.reduce((count,val)=>{
      count += parseFloat(val.amount)*parseInt(val.count)
      return count
    },0)
    const data = {
      user_id: userLogin[0].user_id,
      branch_id: branch_id,
      amount: amount,
      full_name: userLogin[0].full_name,  
      cashSchema:JSON.stringify(state.cashRegister)
    };
    console.log(data)
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        openLoading: true,
      },
    });
    Promise.all([sqlData(data, "", "POST", "cashRegisterLogs")]).then(
      (result) => {
        const usersData = {
          user_id: userLogin[0].user_id,
          cash_register: amount,
          cash_register_id: result[0],
        };
        sqlData(usersData, "", "PUT", "users/" + userLogin[0].user_id).then(
          (res) => {
            dispatch({
              type: "onChangeLoginReducer",
              data: {
                userLogin: userLogin.map((val, index) =>
                  index == 0
                    ? {
                        ...val,
                        cash_register: amount,
                        cash_register_id: result[0],
                      }
                    : val
                ),
              },
            });
            dispatch({
              type: "onChangeOrderReducer",
              data: {
                cash_register_id: result[0],
                openLoading: false,
              },
            });
            setState(prev=>({
              ...prev,
              cashRegister:[  {type:'paper',amount:1000,count:0},
              {type:'paper',amount:500,count:0},
              {type:'paper',amount:200,count:0},
              {type:'paper',amount:100,count:0},
              {type:'paper',amount:50,count:0},
              {type:'paper',amount:20,count:0},
              {type:'coins',amount:0,count:0},]
            }))
          }
        );

        // userLogin[0].cash_register = state.amount
        setOpenAddOrder(true);
        setOpenStartingCash(false);
      }
    );
  };
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const AddOrderModal = () => {
    dispatch({
      type: "onChangeOrderReducer",
      data: {
        openLoading: true,
      },
    });
    let data = {
      page: 0,
      customWhere: false,
      count: false,
      customWhereStr: "",
      where: {
        user_id: userLogin[0].user_id,
      },
      orWhere: {},
      like: {},
      orLike: {},
      groupBy: [],
      orderBy: [],
      join: {},
      selectData:
        "full_name,user_id,user_type,branch_id,page_dashboard,page_orders,page_store_item,page_customers,page_reports,page_inventory,page_supply_chain,cash_register",
    };
    sqlData(data, "", "POST", "users/viewdata").then((result) => {
      if (result.length > 0) {
        if (result[0].cash_register != null) {
          setOpenAddOrder(true);
          dispatch({
            type: "onChangeOrderReducer",
            data: {
              branch_id: branch_id,
              openLoading: false,
            },
          });
        } else {
          setOpenStartingCash(true);
          dispatch({
            type: "onChangeOrderReducer",
            data: {
              openLoading: false,
            },
          });
        }
      }
    });
  };
  function zeroPad(num) {
    return num.toString().padStart(13, "0");
  }
  let ordersTodayDisplay = ordersToday.filter((files) => {
    return (
      files.receipt_number !== null &&
      files.receipt_number
        .toLowerCase()
        .indexOf(state.search.toLocaleLowerCase()) !== -1
    );
  });
  const onRemoveSearch = () => {
    setState((prev) => ({
      ...prev,
      search: "",
      searchDisplay: "",
    }));
  };
  const onSubmitSearch = () => {
    setState((prev) => ({
      ...prev,
      search: state.searchDisplay,
    }));
  };

  const onClickRemit = () => {
    if (userLogin[0]?.cash_register == null) {
      alert("Sorry, You are not allowed to remit!");
    } else {
      if (window.confirm("Are you sure you want to remit?")) {
        dispatch({
          type: "onChangeOrderReducer",
          data: {
            openLoading: true,
          },
        });
        let data = {
          transaction_count: ordersTodayCount,
          sales_amount: todaySales == null ? 0 : todaySales,
          total_amount:
            parseFloat(todaySales == null ? 0 : todaySales) +
            parseFloat(userLogin[0]?.cash_register),
          user_id: userLogin[0]?.user_id,
          branch_id: userLogin[0]?.branch_id,
          full_name: userLogin[0]?.full_name,
          cash_register_id: cash_register_id,
        };

        sqlData(data, "", "POST", "cashRemittance/cashRemit").then((result) => {
          if (parseInt(userLogin[0].user_type) == 2) {
            localStorage.clear();
            history.push("/");
            setTimeout(() => {
              window.location.reload();
            }, 50);
          } else {
            dispatch({
              type: "onChangeLoginReducer",
              data: {
                userLogin: userLogin.map((val, index) =>
                  index == 0
                    ? { ...val, cash_register: null, cash_register_id: null }
                    : val
                ),
              },
            });
            dispatch({
              type: "onChangeOrderReducer",
              data: {
                cash_register_id: "",
                refreshStoreList: !refreshStoreList,
                openLoading: false,
              },
            });
          }
        });
      }
    }
  };

  const handleChange = (e) => {
    setState(prev => ({
      ...prev,
      orderType: e.target.value
    }))
  };
  const onSubmitFilterOrderType = () => {
    onGetOrders()
  }
  const onChangeTextRegister=(e,indexVal)=>{
    let value = e.target.value
    let name = e.target.name
    if(value == ''){
      value = 0;
    }
    let amount = parseFloat(name) * parseFloat(value)
    if(name == 'coins'){
      setState(prev=>({
        ...prev,
        cashRegister:state.cashRegister.map((val,index)=>(index == indexVal)?
        {...val,amount:value,count:1}
        :val)
       }))
    }else{
      setState(prev=>({
        ...prev,
        cashRegister:state.cashRegister.map((val,index)=>(index == indexVal)?
        {...val,count:value}
        :val)
       }))
    }
   
  }
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 9999 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography
            style={{ fontWeight: "bold", fontSize: 18 }}
            variant="overline"
          >
            Order List
          </Typography>
        </Grid>
        <Grid container justify="flex-end" item xs={6}>
          <ButtonGroup
            color="primary"
            variant="contained"
            style={{ marginBottom: 10 }}
          >
            <Button
              startIcon={<Icon>send_and_archive</Icon>}
              onClick={() => onClickRemit()}
            >
              Remit
            </Button>
            <Button
              startIcon={<Icon>add</Icon>}
              onClick={() => AddOrderModal()}
              id="new_order"
            >
              New Order
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} md={6}>
          <div style={{ marginRight: 5 }}>
            <Dropdown
              data={[
                { name: "All", value: "All" },
                { name: "Retail", value: "Retail" },
                { name: "Whole Sale", value: "Whole Sale" },
              ]}
              value={state.orderType}
              handleChange={handleChange}
              optionValue={"value"}
              optionName={"name"}
              type={true}
            />
          </div>
          <ButtonGroup
            color="primary"
            variant="contained"
          // style={{ marginBottom: 10 }}
          >
            <Button onClick={() => onSubmitFilterOrderType()}>Filter</Button>
          </ButtonGroup>
        </Grid>
        <Grid container justify='flex-end' item xs={12} md={6}>
          <TextField
            onChange={onChangeText}
            name="searchDisplay"
            value={state.searchDisplay}
            size="small"
            variant="outlined"
            label="Search Reference No."
            style={{ marginRight: 5 }}
          />
          <ButtonGroup
            color="primary"
            variant="contained"
          // style={{ marginBottom: 10 }}
          >
            <Button onClick={() => onRemoveSearch()}>
              <Icon>close</Icon>
            </Button>
            <Button onClick={() => onSubmitSearch()}>Search</Button>
          </ButtonGroup>
        </Grid>

        {/* <Grid  item xs={6}>
          <TextField
            onChange={onChangeText}
            name="search"
            size="small"
            variant="outlined"
            label="Search Reference No."
          />
            <ButtonGroup
            color="primary"
            variant="contained"
            // style={{ marginBottom: 10 }}
          >
            <Button onClick={() => onRemoveSearch()}>
              <Icon>close</Icon>
            </Button>
            <Button onClick={() => onSubmitSearch()}>Search</Button>
          </ButtonGroup>
        </Grid> */}

        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2" style={{ marginBottom: 25 }}>
                Orders Dashboard
              </Typography>
              <Grid container spacing={3} style={{}}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h5">
                    {ordersTodayCount}{" "}
                    <Typography variant="overline">Orders Today</Typography>
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography variant="h5">
                    {parseInt(userLogin[0].user_type) == 2
                      ? 0
                      : todaySales == null
                        ? 0
                        : formatNumber(todaySales)}{" "}
                    <Typography variant="overline">Today's Sales</Typography>
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography variant="h5">
                    {userLogin[0]?.cash_register != null
                      ? formatNumber(userLogin[0]?.cash_register)
                      : 0}{" "}
                    <Typography variant="overline">
                      Initial Cash Register
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography variant="h5">
                    {parseInt(userLogin[0].user_type) == 2
                      ? 0
                      : todaySales == null ||
                        userLogin[0]?.cash_register == null
                        ? 0
                        : formatNumber(
                          parseFloat(todaySales) +
                          parseFloat(userLogin[0]?.cash_register)
                        )}{" "}
                    <Typography variant="overline">Remit Amount </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <TableContainer style={{ marginTop: 10 }} component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead style={{ background: "#fff" }}>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Reference #</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Date Ordered</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Customer</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Total Amount</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersTodayDisplay
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order, key) => (
                <TableRow>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{zeroPad(order.receipt_number)}</TableCell>
                  <TableCell>{order.order_type}</TableCell>
                  <TableCell>
                    {moment(order.date_added).format("Y-MM-DD")}
                  </TableCell>
                  <TableCell>{order.customer_name}</TableCell>
                  <TableCell>{formatNumber(order.total_amount)}</TableCell>
                  <TableCell>{order.order_status}</TableCell>
                  <TableCell>
                    <Icon
                      onClick={() => handleClickOpenOrderDetails(order)}
                      style={{ cursor: "pointer", color: "#757575" }}
                    >
                      launched
                    </Icon>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={ordersTodayDisplay.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Dialog
        fullScreen
        open={openAddOrder}
        onClose={() =>
          userLogin[0].user_type == 2 ? undefined : setOpenAddOrder(false)
        }
        fullWidth
        maxWidth="lg"
      >
        <div
          style={{ marginTop: userLogin[0].user_type == 2 ? 30 : undefined }}
        >
          <IconButton
            style={{
              display: userLogin[0].user_type == 2 ? "none" : undefined,
            }}
            onClick={() => setOpenAddOrder(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <AddOrder onClickRemit={onClickRemit} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openStartingCash}
        onClose={() => setOpenStartingCash(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Initial Cash Register</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmitRegister}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={(e)=>onChangeTextRegister(e,0)}
                  name="1000"
                  type="number"
                  style={{ width: "100%" }}
                  label="1,000"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,1)}
                  name="500"
                  type="number"
                  style={{ width: "100%" }}
                  label="500"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={(e)=>onChangeTextRegister(e,2)}
                  name="200"
                  type="number"
                  style={{ width: "100%" }}
                  label="200"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,3)}
                  name="100"
                  type="number"
                  style={{ width: "100%" }}
                  label="100"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,4)}
                  name="50"
                  type="number"
                  style={{ width: "100%" }}
                  label="50"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={(e)=>onChangeTextRegister(e,5)}
                  name="20"
                  type="number"
                  style={{ width: "100%" }}
                  label="20"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                   onChange={(e)=>onChangeTextRegister(e,6)}
                  name="coins"
                  type="number"
                  style={{ width: "100%" }}
                  label="Coins"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{display:'flex',flexDirection:'row'}}>
                <Typography variant="overline" style={{fontSize:20,fontWeight:'bold'}}>Total : </Typography>
                <Typography variant="overline" style={{fontSize:20,fontWeight:'bold'}}>{formatNumber(state.cashRegister.reduce((count,val)=>{
                  count += parseFloat(val.amount)*parseInt(val.count)
                  return count
                },0))}</Typography>
                </div>
               


              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <ButtonGroup
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                >
                  <Button type="submit">Submit</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <OrderDetails
        open={open}
        selectedOrderDetails={selectedOrderDetails}
        handleCloseOpenOrderDetails={handleCloseOpenOrderDetails}
      />
    </div>
  );
}
